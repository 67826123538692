/**
 * Localization related values
 */

// FIXME inline to "Currency.ts"
type LocaleCode = Locales.de_DE | Locales.en_GB | Locales.en_US | Locales.pt_BR | '';
type CurrencyCode = Currencies.EUR | Currencies.GBP | Currencies.USD | Currencies.BRL | '';
type CurrencySymbolCode = CurrencySymbols.EUR | CurrencySymbols.GBP | CurrencySymbols.USD | CurrencySymbols.BRL | '';

// FIXME check what is used
export { LocaleCode, CurrencyCode, CurrencySymbolCode };

export enum Locales {
  de_DE = 'de-DE',
  en_US = 'en-US',
  en_GB = 'en-GB',
  pt_BR = 'pt-BR',
}

export enum Currencies {
  EUR = 'eur',
  USD = 'usd',
  BRL = 'brl',
  GBP = 'gbp',
}

export const CURRENCY_LIST: CurrencyCode[] = [Currencies.EUR, Currencies.USD, Currencies.GBP, Currencies.BRL];

export enum CountryCodes {
  DE = 'DE',
  US = 'US',
  GB = 'GB',
  BR = 'BR',
}

export enum CurrencySymbols {
  EUR = '€',
  USD = '$',
  SPECIFIC_USD = 'US $',
  GBP = '£',
  BRL = 'R$',
}

export enum CountriesSpecificUSD {
  ANGUILLA = 'AI',
  ANTIGUA_AND_BARBUDA = 'AG',
  AUSTRALIA = 'AU',
  BAHAMAS = 'BS',
  BARBADOS = 'BB',
  BELIZE = 'BZ',
  BERMUDA = 'BM',
  BRUNEI = 'BN',
  CANADA = 'CA',
  CAYMAN_ISLANDS = 'KY',
  COOK_ISLANDS = 'CK',
  DOMINICA = 'DM',
  FIJI = 'FJ',
  GRENADA = 'GD',
  GUYANA = 'GY',
  HONG_KONG = 'HK',
  JAMAICA = 'JM',
  KIRIBATI = 'KI',
  LIBERIA = 'LR',
  MEXICO = 'MX',
  MONTSERRAT = 'MS',
  NAMIBIA = 'NA',
  NAURU = 'NR',
  NEW_ZEALAND = 'NZ',
  NIUE = 'NU',
  PITCAIRN_ISLANDS = 'PN',
  SAINT_KITTS_AND_NEVIS = 'KN',
  SAINT_VINCENT_AND_THE_GRENADINES = 'VC',
  SINGAPORE = 'SG',
  SOLOMON_ISLANDS = 'SB',
  SURINAME = 'SR',
  TAIWAN = 'TW',
  TRINIDAD_AND_TOBAGO = 'TT',
  TUVALU = 'TV',
  ZIMBABWE = 'ZW',
}
