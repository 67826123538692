import Amplify from '@aws-amplify/core';

// FIXME move to a better place, eg AppConfig or think about config in general
Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.VUE_APP_COGNITO_REGION ?? 'eu-central-1',
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID ?? 'eu-central-1_XXX',
    userPoolWebClientId: process.env.VUE_APP_COGNITO_APP_CLIENT_ID ?? 'XXXXXXXXXXXXXXXXXXXXXXXXXX',
    cookieStorage: {
      domain: process.env.VUE_APP_COGNITO_COOKIE_DOMAIN ?? 'XXXXXXXXXXXXXXXXX.auth.eu-central-1.amazoncognito.com',
      secure: process.env.NODE_ENV === 'production',
      path: '/',
      expires: 365,
    },
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN ?? 'XXXXXXXXXXXXXX.com',
      redirectSignIn: process.env.VUE_APP_COGNITO_REDIRECT_SIGN_IN ?? '',
      redirectSignOut: process.env.VUE_APP_COGNITO_REDIRECT_SIGN_OUT ?? '',
      responseType: 'code',
    },
  },
});
