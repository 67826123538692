import Vue from 'vue';
import Vuex from 'vuex';
import AppStore, { AppState } from '@/store/app';
import AuthStore, { AuthState } from '@/store/auth';
import LayoutStore, { LayoutState } from '@/store/layout';
import AccountStore, { AccountState } from '@/store/account';
import LocationStore, { LocationState } from '@/store/location';
import SubscriptionStore from '@/store/subscription';
import { SubscriptionState } from '@/classes/stripe';

Vue.use(Vuex);

export interface RootState {
  app: AppState;
  auth: AuthState;
  layout: LayoutState;
  account: AccountState;
  location: LocationState;
  subscription: SubscriptionState;
}

const store = new Vuex.Store<RootState>({
  modules: {
    app: AppStore,
    auth: AuthStore,
    layout: LayoutStore,
    account: AccountStore,
    location: LocationStore,
    subscription: SubscriptionStore,
  },
});

export default store;
