<template>
  <v-tooltip bottom z-index="999" transition="none" max-width="320">
    <template #activator="{ on }">
      <v-btn icon :ripple="false" color="primary" small v-on="on">
        <Icon name="help-round-system" outline />
      </v-btn>
    </template>
    <slot name="text" />
  </v-tooltip>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/components/Icons/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class HelpTooltip extends Vue {
  @Prop(String)
  iconSize?: string;
}
</script>

<style scoped></style>
