<template>
  <div>
    <EmptyState illustration="discount_illustration.png" :width="200" class="offer-accepted-confirmation__container">
      <h1 class="text-h1 mt-6">
        {{ $t('fields.account.billing.cancel_subs.offer_accepted_confirmation.title') }}
      </h1>
      <h4
        class="text-h4 mt-6"
        v-html="
          $t('fields.account.billing.cancel_subs.offer_accepted_confirmation.subtitle', {
            discount: `${$route.query.discount}%`,
          })
        "
      ></h4>
      <p
        class="body-1 lapis--text text--lighten-2 mt-6"
        v-html="
          $t('fields.account.billing.cancel_subs.offer_accepted_confirmation.note', {
            subs_end: $store.state.subscription.activeSubscription.current_period_end.format('MMM DD, YYYY'),
          })
        "
      ></p>

      <v-btn depressed rounded :ripple="false" color="primary" x-large to="/my-account/billing" class="mt-10">
        {{ $t('fields.account.billing.cancel_subs.offer_accepted_confirmation.button') }}
      </v-btn>
    </EmptyState>
  </div>
</template>

<script lang="ts">
import EmptyState from '@/components/EmptyState.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Route } from '@sentry/vue/types/router';
import { NavigationGuardNext } from 'vue-router';

@Component({
  components: {
    EmptyState,
  },
})
export default class OfferAcceptedConfirmation extends Vue {
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    if (to.name === 'checkout_complete_with_discount' && from.path !== '/cancel-subscription')
      next({ path: '/my-account/billing' });
    next();
  }
}
</script>
<style lang="scss">
.offer-accepted-confirmation__container {
  margin-top: 80px;
}
</style>
