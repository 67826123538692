<template>
  <v-dialog
    :value="true"
    :persistent="true"
    max-width="460"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    scrollable
    content-class="qr-dialog"
  >
    <v-card rounded class="card__custom">
      <!--      <Dismiss absolute top right @click="onCloseModal" />-->
      <div class="pa-6 pb-0">
        <v-card-title class="pb-1 title__custom">
          <h4 class="text-h4" v-text="$t('fields.modals.support.heading.title')" />
        </v-card-title>

        <Alert dense outlined type="warning" regular-title max-width="1024" class="mt-4 mb-4">
          <template #title>
            <span class="font-weight-semi-bold" v-html="$t('fields.modals.support.heading.alert.description')" />

            <div class="support-modal-alert__button-container">
              <v-btn
                text
                :ripple="false"
                color="primary"
                small
                class="v-btn--text--link"
                :href="helpcenterUrl"
                target="_blank"
              >
                <span class="navy--text" v-html="$t('fields.modals.support.heading.alert.button')"></span>
              </v-btn>
            </div>
          </template>
        </Alert>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                ref="firstName"
                v-model="form.firstName"
                outlined
                dense
                class="text-field"
                maxlength="50"
                :label="$t('fields.modals.support.label.firstName')"
                :rules="fieldIsFirstName"
                required
              />
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                v-model="form.lastName"
                outlined
                dense
                class="text-field"
                maxlength="50"
                :rules="fieldIsLastName"
                :label="$t('fields.modals.support.label.lastName')"
                required
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="form.email"
                outlined
                dense
                type="email"
                class="text-field"
                maxlength="50"
                :rules="fieldIsEmail"
                :label="$t('fields.modals.support.label.email')"
                required
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="form.language"
                class="text-field pb-0"
                :items="form.languages"
                :rules="fieldIsRequired"
                item-value="value"
                item-text="key"
                :menu-props="{ contentClass: 'v-select__lh--fix pa-2 white' }"
                :label="$t('fields.modals.support.label.language')"
                outlined
                dense
                required
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-select
                v-model="form.reason"
                class="text-field pb-0"
                :items="form.reasons"
                :rules="fieldIsRequired"
                item-value="value"
                item-text="key"
                :menu-props="{ contentClass: 'v-select__lh--fix pa-2 white', maxHeight: '100%' }"
                :label="$t('fields.modals.support.label.supportTopic')"
                outlined
                dense
                required
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="form.subject"
                outlined
                dense
                class="text-field"
                maxlength="100"
                :rules="fieldIsSubject"
                :label="$t('fields.modals.support.label.subject')"
                required
              />
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="form.description"
                class="text-field pb-0"
                :rules="fieldIsDescription"
                rows="4"
                maxlength="1000"
                :counter="1000"
                outlined
                :label="$t('fields.modals.support.label.description')"
                required
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-card-actions class="px-6 pb-6 pt-4 actions">
        <v-spacer />
        <v-btn outlined large :ripple="false" rounded color="primary" :disabled="submitting" @click="onCloseModal">
          {{ $t('fields.modals.support.actions.cancel') }}
        </v-btn>
        <v-btn
          depressed
          large
          :ripple="false"
          rounded
          color="primary"
          class="ml-4"
          :disabled="submitting"
          @click.prevent="onSubmit"
        >
          {{ $t('fields.modals.support.actions.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { AuthState } from '@/store/auth';
import * as Sentry from '@sentry/vue';
import { Team, User } from '@/classes/auth';
import Alert from '@/components/Alert.vue';
import Dismiss from '@/components/Dismiss.vue';
import { SupportModalMixin } from '@/mixins/SupportModalMixin';

interface SupportFormInterface {
  firstName: string;
  lastName: string;
  email: string;
  language: string;
  languages: { id: string; key: string; value: string }[];
  reason: string;
  reasons: { id: string; key: string; value: string }[];
  subject: string;
  description: string;
}

@Component({
  components: {
    Alert,
    Dismiss,
  },
  computed: {
    ...mapState<AuthState>('auth', {
      user: (state: AuthState) => state.user,
      team: (state: AuthState) => state.team,
    }),
  },
})
export default class SupportModal extends SupportModalMixin {
  // State from vuex
  user!: User;
  team!: Team;
  // Data
  valid: boolean = true;
  submitting: boolean = false;
  helpcenterUrl: string = 'https://help.qr-code-generator.com/';
  form: SupportFormInterface = {
    firstName: '',
    lastName: '',
    email: '',
    language: '',
    languages: [
      { id: 'en', key: 'English', value: 'English' },
      { id: 'de', key: 'German', value: 'German' },
      { id: 'es', key: 'Spanish', value: 'Spanish' },
      { id: 'it', key: 'Italian', value: 'Italian' },
      { id: 'pt', key: 'Portuguese', value: 'Portuguese' },
      { id: 'fr', key: 'French', value: 'French' },
    ],
    reason: '',
    reasons: [
      { id: 'settingUpYourAccount', key: 'Setting Up Your Account', value: 'Setting Up Your Account' },
      { id: '14DaysFreeTrial', key: '14-Day Free Trial', value: '14-Day Free Trial' },
      { id: 'planInformation', key: 'Plan Information', value: 'Plan Information' },
      { id: 'manageYourSubscription', key: 'Managing Your Subscription', value: 'Managing Your Subscription' },
      { id: 'pricingAndBilling', key: 'Pricing and Billing', value: 'Pricing and Billing' },
      { id: 'managingYourQRCodes', key: 'Managing Your QR Codes', value: 'Managing Your QR Codes' },
      { id: 'designingYourQRCodes', key: 'Designing Your QR Codes', value: 'Designing Your QR Codes' },
      { id: 'trackingYourQRCodes', key: 'Tracking Your QR Codes', value: 'Tracking Your QR Codes' },
      { id: 'paymentProblem', key: 'Payment Problem', value: 'Payment Problem' },
      { id: 'accountDeletion', key: 'Account Deletion', value: 'Account Deletion' },
      { id: 'QRCodeTransfer', key: 'QR Code Transfer', value: 'QR Code Transfer' },
      { id: 'other', key: 'Other', value: 'Other' },
    ],
    subject: '',
    description: '',
  };

  // Hooks
  created(): void {
    // Prefill email with user email since we will disable email input for now
    this.form.email = this.user.email || '';

    // Change the helpcenter URL according to user-language
    if (this.$i18n.locale === 'pt') {
      // pt-BR is an exception here
      this.helpcenterUrl += 'pt-BR';
    } else {
      this.helpcenterUrl += this.$i18n.locale;
    }

    // Autodetect language and preselect it
    const findLanguage = this.form.languages.find((lang) => lang.id === this.$i18n.locale);
    if (findLanguage) {
      this.form.language = findLanguage.value;
    }
    // Translate keys for languages
    this.form.languages = this.form.languages.map((language) => {
      return {
        ...language,
        key: this.$t(`fields.modals.support.languages.${language.id}`) as string,
      };
    });
    // Translate keys for reasons
    this.form.reasons = this.form.reasons.map((reason) => {
      return {
        ...reason,
        key: this.$t(`fields.modals.support.reasons.${reason.id}`) as string,
      };
    });
  }

  mounted(): void {
    this.$nextTick(() => {
      // Focus first name as soon as form is loaded
      setTimeout(() => {
        (this.$refs.firstName as HTMLElement)?.focus();
      }, 500);
      // Check if we did temporary store form inside sessionStorage and fill the form if we find data
      const getSupportModalFromStorage = window.sessionStorage.getItem('SupportModal');
      if (getSupportModalFromStorage) {
        this.form = JSON.parse(getSupportModalFromStorage);
      }
    });
  }

  // Getters
  get fieldIsFirstName(): any[] {
    return [
      (v: string): string | boolean => {
        return !!v || (this.$t('fields.modals.support.validations.required') as string);
      },
      (v: string): string | boolean =>
        v.length <= 50 || (this.$t('fields.modals.support.validations.maxLength', ['50']) as string),
    ];
  }

  get fieldIsLastName(): any[] {
    return [
      (v: string): string | boolean => {
        return !!v || (this.$t('fields.modals.support.validations.required') as string);
      },
      (v: string): string | boolean =>
        v.length <= 50 || (this.$t('fields.modals.support.validations.maxLength', ['50']) as string),
    ];
  }

  get fieldIsEmail(): any[] {
    return [
      (v: string): string | boolean => !!v || (this.$t('fields.modals.support.validations.required') as string),
      (v: string): string | boolean =>
        /.+@.+\..+/.test(v) || (this.$t('fields.modals.support.validations.email') as string),
      (v: string): string | boolean =>
        v.length <= 50 || (this.$t('fields.modals.support.validations.maxLength', ['50']) as string),
    ];
  }

  get fieldIsSubject(): any[] {
    return [
      (v: string): string | boolean => {
        return !!v || (this.$t('fields.modals.support.validations.required') as string);
      },
      (v: string): string | boolean =>
        v.length <= 100 || (this.$t('fields.modals.support.validations.maxLength', ['100']) as string),
    ];
  }

  get fieldIsDescription(): any[] {
    return [
      (v: string): string | boolean => {
        return !!v || (this.$t('fields.modals.support.validations.required') as string);
      },
      (v: string): string | boolean =>
        v.length <= 1000 || (this.$t('fields.modals.support.validations.maxLength', ['1000']) as string),
    ];
  }

  get fieldIsRequired(): any[] {
    return [
      (v: string): string | boolean => {
        return !!v || (this.$t('fields.modals.support.validations.required') as string);
      },
    ];
  }

  // Methods
  async onCloseModal(): Promise<void> {
    // Add form data to sessionStorage on modal close
    window.sessionStorage.setItem('SupportModal', JSON.stringify(this.form));
    this.toggleSupportModal(false);
  }

  onSubmit(): void {
    if ((this as any).$refs.form.validate()) {
      this.submitting = true;
      this.onSubmitForm();
    } else {
      // Scroll to invalid field
      setTimeout(() => {
        const el = document.querySelector('.v-input.text-field.error--text:first-of-type');
        el?.scrollIntoView({
          behavior: 'smooth',
        });
      }, 100);
    }
  }

  onSubmitForm(): void {
    let currency = process.env.VUE_APP_DEFAULT_CURRENCY || 'eur';
    let company = '';
    if (this.team.legal_entity === 'b2b') {
      company = this.team.company || '';
    }
    if (this.team?.currency) {
      currency = this.team.currency;
    }
    const body = {
      orgid: process.env.VUE_APP_SALESFORCE_SUPPORT_FORM_ID || '',
      User_ID__c: this.user.id,
      company: company,
      Country__c: this.team?.country || '',
      currency: currency,
      First_Name__c: this.form.firstName,
      Last_Name__c: this.form.lastName,
      email: this.form.email,
      Language__c: this.form.language,
      reason: this.form.reason,
      subject: this.form.subject,
      description: this.form.description,
    };

    const url = process.env.VUE_APP_SALESFORCE_SUPPORT_FORM_URL || '';
    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: new URLSearchParams(body),
    })
      .then(async () => await this.onSubmitSuccess())
      .catch(async (error: any) => {
        await this.onSubmitError();
        Sentry.captureException(error);
      });
  }

  async onSubmitSuccess(): Promise<void> {
    await this.onCloseModal();
    // Remove form data from sessionStorage on submit
    window.sessionStorage.removeItem('SupportModal');
    this.submitting = false;
    const text = this.$root.$t('fields.modals.support.submit.success') || '';
    await this.$store.dispatch('layout/setSnackbar', {
      type: 'success',
      icon: 'checkmark-system',
      text,
      isSnackbar: true,
      timeToDeactivate: 5000,
      active: true,
    });
  }
  async onSubmitError(): Promise<void> {
    await this.onCloseModal();
    this.submitting = false;
    const text = this.$root.$t('fields.modals.support.submit.error') || '';
    await this.$store.dispatch('layout/setSnackbar', {
      type: 'error',
      icon: 'forbidden-system',
      text,
      isSnackbar: true,
      timeToDeactivate: 5000,
      active: true,
    });
  }
}
</script>

<style lang="scss" scoped>
.actions {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
}
.textarea__custom {
  ::v-deep {
    .v-text-field__details {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.title__custom {
  padding-top: 0 !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.card__custom {
  height: 100%;
}

.support-modal-alert__button-container {
  display: flex;
  justify-content: flex-end;
}
</style>
