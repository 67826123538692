import AppConfig from '@/AppConfig';
import * as Sentry from '@sentry/vue';

const zendeskInit = function (): void {
  if (AppConfig.getZendeskSnippetKey()) {
    init(AppConfig.getZendeskSnippetKey());
  } else {
    console.info('ZENDESK not running');
    Sentry.captureMessage(`[error] ZENDESK zendeskInit() did not pass validation`);
  }
};

function init(snippetKey: string) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'ze-snippet';
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${snippetKey}`;
  script.addEventListener('load', () => {
    window.zE('webWidget', 'hide'); // Hide zendesk button on initial load
    window.zE('webWidget:on', 'close', function () {
      // Listen to event so we can toggle modal
      window.zE('webWidget', 'hide');
    });
    window.zE('webWidget:on', 'open', function () {
      // Listen to event so we can toggle modal
      window.zE('webWidget', 'show');
    });
  });
  script.addEventListener('error', (e) => {
    Sentry.captureException(e, (scope) => {
      scope.setTransactionName(`Zendesk script script load error`);
      return scope;
    });
  });
  document.head.appendChild(script);
}

export default zendeskInit;
