import axios from 'axios';
import store from '@/store';
import AppConfig from '@/AppConfig';

interface TaxResponse {
  taxable: string;
  rate: number;
}

class TaxLookupService {
  get priceIdFromUrlPath() {
    const href = window.location.href;
    const match = href.match(/(price_[\w]*)/gim);
    return match ? match[0] : undefined;
  }

  get priceIdFromStore() {
    return store.state.subscription?.selectedPlan?.id;
  }

  async lookup(
    country: string,
    city: string,
    postal_code: string,
    legalEntity: string,
    vat_id: string,
    state: string
  ): Promise<TaxResponse> {
    const payload = {
      params: {
        country,
        city,
        zip: postal_code,
        legal_entity: legalEntity,
        vat_id,
        state,
        price_id: this.priceIdFromStore || this.priceIdFromUrlPath,
      },
    };
    return (await axios.get(AppConfig.getAPIBaseUrl().concat('/system/tax/'), payload)).data;
  }
}

export default new TaxLookupService();
