import AppConfig from '@/AppConfig';
import { QrCodeAmounts } from '@/classes/account';
import { Plan, Subscription } from '@/classes/stripe';
import store from '@/store';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export const enum PlanState {
  NO_PLAN = 'NO_PLAN',
  PLAN_INCOMPLETE = 'PLAN_INCOMPLETE',
  PLAN_ACTIVE = 'PLAN_ACTIVE',
  PLAN_UPCOMING = 'PLAN_UPCOMING',
  PLAN_INFERIOR = 'PLAN_INFERIOR',
  PLAN_DOWNGRADING = 'PLAN_DOWNGRADING',
  PLAN_CANCELLED = 'PLAN_CANCELLED',
  PLAN_SUPERIOR = 'PLAN_SUPERIOR',
}
/**
 * StripeSubscriptionService is responsible for handling the subscription business logic
 * and provides some swift helpers for components.
 */
export default class StripeSubscriptionService {
  // Active subscription from local storage or an empty one for new customers
  subscription: Subscription = new Subscription();

  // Load subscription from API/vuex and update vuex later on to keep a copy active for the customer session
  async initSubscription(): Promise<void | null> {
    if (!store.state.subscription.activeSubscription.id) {
      await store.dispatch('subscription/loadSubscription');
    }

    this.subscription = store.state.subscription.activeSubscription;

    return Promise.resolve();
  }

  // Store a subscription inside local storage
  async initStorage(subscription: Subscription | null = null): Promise<void | null> {
    return store.dispatch('subscription/setActiveSubscription', subscription ? subscription : this.subscription);
  }

  isPlanIncomplete(planId: string): boolean {
    return this.subscription.plan_id === planId && ['incomplete', 'past_due'].includes(this.subscription.status);
  }

  // Is the given planID registered as an active plan for the customers subscription
  isPlanActive(planId: string): boolean {
    return this.subscription.plan_id === planId && this.subscription.is_active;
  }

  // Is the given planID registered as an cancelled plan for the customers subscription
  isPlanCancelled(planId: string): boolean {
    return this.subscription.plan_id === planId && this.subscription.is_cancelled;
  }

  // Is the given planID planned as a downgrade from an existing plan? Old plan has been cancelled!
  isPlanUpcoming(planId: string): boolean {
    return this.subscription.new_plan_id === planId && this.subscription.is_cancelled;
  }

  // Check if current plan is in downgrade process
  isPlanDowngrading(planId: string): boolean {
    if (!this.subscription.new_plan_id) return false;
    return this.subscription.plan_id === planId && this.subscription.is_active && this.subscription.is_cancelled;
  }

  // Is the given plan inferior in comparision with the active plan
  isPlanInferior(planId: string): boolean | null {
    const [askedPlan, activePlan] = [this.findPlan(planId), store.state.subscription.activeSubscription.plan];
    return askedPlan && activePlan.planLevel >= 0 ? askedPlan.planLevel < activePlan.planLevel : null;
  }

  // Is the given plan superior in comparison with the active plan
  isPlanSuperior(planId: string): boolean | null {
    const result = this.isPlanInferior(planId);
    return result !== null ? !result : null;
  }

  isPlanEquivalent(planId: string): boolean {
    const [askedPlan, activePlan] = [this.findPlan(planId), store.state.subscription.activePlan];
    return askedPlan?.planLevel === activePlan?.planLevel;
  }

  // Find a given plan via plan id inside local storage
  findPlan(planId: string): Plan | null {
    const result: Plan = store.state.subscription.plans.find((v: Plan) => v.id == planId)!;
    return result || null;
  }
  // Checks if the user can downgrade the plan. Returns false if the user has too many dynamic-codes
  canDowngradePlan(planId: string): boolean {
    // New plan
    const askedPlan = this.findPlan(planId);
    const amountUserDynamicCodes = store.state.account.account?.qrcodes?.activeDynamicCodes;
    // Code limit of new plan
    const newPlanCodeLimit = askedPlan ? parseInt(askedPlan.codeLimit) : 0;
    // Returns false if the user has too many dynamic-codes
    return newPlanCodeLimit >= amountUserDynamicCodes!;
  }

  // Returns if user can select the free plan.
  // Only possible if the user has 1 or less codes
  get canSelectFreePlan(): boolean | undefined {
    const { activeDynamicWebsiteCodes, activeStaticCodes, activeDynamicCodes } = store.state.account.account
      ?.qrcodes as QrCodeAmounts;

    if (
      (activeDynamicWebsiteCodes < 1 && activeDynamicCodes < 1) ||
      (activeDynamicWebsiteCodes === 1 && activeDynamicCodes < 2)
    ) {
      return activeStaticCodes <= 5;
    }

    return undefined;
  }

  // Cancel current subscription
  async cancel(resource_id: string): Promise<any> {
    return axios.delete(AppConfig.getAPIBaseUrl() + '/subscription/stripe/' + resource_id).then(async () => {
      await store.dispatch('subscription/loadSubscription');
      await store.commit('subscription/cancelSubscription', true);
    });
  }

  planState(planId: string): PlanState {
    if (this.subscription?.id === 0) return PlanState.NO_PLAN;

    if (this.isPlanIncomplete(planId)) return PlanState.PLAN_INCOMPLETE;
    if (this.isPlanActive(planId) && !this.isPlanCancelled(planId)) return PlanState.PLAN_ACTIVE;
    if (this.isPlanUpcoming(planId)) return PlanState.PLAN_UPCOMING;
    if (this.isPlanInferior(planId)) return PlanState.PLAN_INFERIOR;
    if (this.isPlanDowngrading(planId)) return PlanState.PLAN_DOWNGRADING;
    if (this.isPlanCancelled(planId)) return PlanState.PLAN_CANCELLED;
    if (this.isPlanEquivalent(planId)) return PlanState.PLAN_ACTIVE;
    if (this.isPlanSuperior(planId)) return PlanState.PLAN_SUPERIOR;

    return PlanState.NO_PLAN;
  }

  getStandardPlanByName(planName: string): Plan | undefined {
    return store.state.subscription?.plans?.find(
      (plan: Plan) => plan.planName.toLowerCase() === planName?.toLowerCase()
    );
  }

  async loadPlanById(planId: string): Promise<Plan | undefined> {
    if (planId?.startsWith('price_') === false) {
      Sentry.captureMessage(`Trying to load an invalid plan with id: ${planId}`);
      return;
    }

    const plan = store.state.subscription?.plans?.find((plan: Plan) => plan.id === planId);
    if (plan) return plan;
    try {
      const response = await axios.get<Plan>(AppConfig.getAPIBaseUrl() + '/subscription/stripe/plans/' + planId);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}
