import type { Client, UserAttributes } from '@optimizely/optimizely-sdk';

export function isFeatureEnabled(
  optimizely: Client | null,
  featureName: string,
  userId: string,
  attributes: UserAttributes = {}
): boolean {
  if (!optimizely) {
    return false;
  }
  const user = optimizely.createUserContext(userId, attributes);
  if (!user) {
    return false;
  }
  const decision = user.decide(featureName);
  return decision.enabled;
}
