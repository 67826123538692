<template>
  <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="checkout__summary">
    <!-- ORDER SUMMARY -->
    <OrderSummary
      :selected-plan="selectedPlan"
      :related-plans="relatedPlans"
      :stripe-checkout-service="stripeCheckoutService"
      :legal-entity="stripeCheckoutService.legalEntity"
      :checkout-type="checkoutType"
      @totalPriceChanged="onTotalPriceChanged($event)"
      @pricePlanChanged="loadSelectedPlan($event)"
    />
    <!-- ORDER SUMMARY END -->
    <!-- ORDER INFORMATION -->
    <div>
      <v-list class="mt-7" color="transparent" dense>
        <v-list-item :ripple="false" inactive>
          <v-list-item-icon>
            <Icon name="checkmark-system" outline class="turquoise--text" />
          </v-list-item-icon>

          <v-list-item-content class="pt-1 pb-4 lapis--text text--lighten-2">
            {{ $t('fields.checkout.sidebar.benefit_ready_to_use') }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" inactive>
          <v-list-item-icon>
            <Icon name="checkmark-system" outline class="turquoise--text" />
          </v-list-item-icon>

          <v-list-item-content class="pt-1 pb-4 lapis--text text--lighten-2">
            {{ $t('fields.checkout.sidebar.benefit_invoice_email') }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item :ripple="false" inactive>
          <v-list-item-icon>
            <Icon name="checkmark-system" outline class="turquoise--text" />
          </v-list-item-icon>

          <v-list-item-content class="pt-1 pb-4 lapis--text text--lighten-2">
            {{ $t('fields.checkout.sidebar.benefit_upgrade') }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <!-- ORDER INFORMATION END -->
  </v-col>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrderSummary from '@/components/PaymentForm/OrderSummary.vue';
import Icon from '@/components/Icons/Icon.vue';
import { mapGetters } from 'vuex';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import { Plan } from '@/classes/stripe';
@Component({
  components: {
    OrderSummary,
    Icon,
  },
  computed: {
    ...mapGetters('subscription', ['relatedPlans']),
  },
})
export default class PaymentFormSidebar extends Vue {
  @Prop({ type: Plan })
  selectedPlan!: Plan;
  @Prop({ type: StripeCheckoutService })
  stripeCheckoutService!: StripeCheckoutService;
  @Prop({ type: String })
  checkoutType!: string;
  relatedPlans!: Array<Plan>;
  // Load selected plan (used when changing monthly/yearly)
  async loadSelectedPlan(plan: Plan): Promise<void> {
    Object.assign(this.selectedPlan, new Plan(plan));
  }

  onTotalPriceChanged(price: any): void {
    this.$emit('totalPriceChanged', price);
  }
}
</script>
