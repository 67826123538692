<template>
  <div class="counter-argument-banner" :style="{ backgroundColor: backgroundColor }">
    <slot name="icon-left" />
    <div>
      <h2 class="counter-argument-banner__title" v-text="title" />
      <p v-if="description" class="counter-argument-banner__description" v-text="description" />
      <div v-if="$slots.actions" class="counter-argument-banner__actions">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CounterArgumentBanner extends Vue {
  @Prop(String)
  backgroundColor!: string;

  @Prop(String)
  title!: string;

  @Prop(String)
  description?: string;
}
</script>

<style scoped lang="scss">
.counter-argument-banner {
  padding: 1rem;
  letter-spacing: -0.01em;
  display: flex;
  gap: 1em;
  align-items: center;
  border-radius: 8px;
  margin: 0 0 8px 26px;

  &__title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  &__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
  &__actions {
    margin-top: 12px;
    display: flex;
    gap: 12px;
  }
}
</style>
