<template>
  <ValidationProvider
    ref="checkout.customer.address.line1"
    v-slot="{ errors }"
    name="user.address"
    rules="required|max:191"
  >
    <v-combobox
      v-model="selectedAddress"
      class="text-field places-autocomplete"
      data-test="qr-payment-address-combobox"
      :items="googlePlacesSuggestions"
      :filter="(v) => v"
      outlined
      hide-no-data
      dense
      return-object
      append-icon=""
      item-value="id"
      item-text="description"
      :label="$t('fields.user.address')"
      :error-messages="$t(errors[0])"
      attach=".places-autocomplete"
      autocomplete="chrome-off"
      @keyup="detectAddress($event.target.value)"
    >
      <template #item="{ item }">
        <div data-test="qr-payment-address-combobox-suggestion">
          <Icon name="map-pin-system" outline class="google-places__icon" />
          <span>
            <span class="google-places__suggestion-text">
              {{ item.description }}
            </span>
          </span>
        </div>
      </template>
    </v-combobox>
  </ValidationProvider>
</template>

<script lang="ts">
import AppConfig from '@/AppConfig';
import Icon from '@/components/Icons/Icon.vue';
import store from '@/store';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { ValidationProvider } from 'vee-validate';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { GooglePlacesSuggestions } from './GooglePlacesAutocomplete.vue';
import * as Sentry from '@sentry/vue';

@Component({
  components: {
    Icon,
    ValidationProvider,
  },
})
export default class GooglePlacesCombobox extends Vue {
  googlePlacesSuggestions: GooglePlacesSuggestions[] = [];
  googlePlacesLoading = false;
  googlePlacesTimout: any = '';
  sessionToken: string = uuid();
  selectedAddress: string = store.state.auth.team.line1;

  consumeGooglePlacesApi(type: string, queryOrPlaceId: string): void {
    axios
      .get(
        AppConfig.getAPIBaseUrl().concat(
          '/system/address/' + type + '/' + queryOrPlaceId + '/' + this.$i18n.locale + '/' + this.sessionToken
        )
      )
      .then((response) => {
        if (type === 'autocomplete') {
          this.googlePlacesSuggestions = response.data.predictions;
        } else {
          this.sessionToken = uuid();
          this.$emit('selectedAddressFromDropdown', response.data);

          // Remove City, Country from selection
          if (response.data.street && response.data.house) {
            this.selectedAddress = `${response.data.street} ${response.data.house}`;
          } else if (response.data.street && !response.data.house) {
            this.selectedAddress = response.data.street;
          }
        }
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }
  // Watcher to detect if an address was selected from dropdown
  @Watch('selectedAddress', { deep: true })
  onGooglePlacesSelect(selection: GooglePlacesSuggestions): void {
    if (selection?.place_id) {
      this.consumeGooglePlacesApi('details', selection.place_id);
    } else {
      // if nothing is selected that means it is a manual entry and keep the address user wrote
      this.$emit('manualEntry', selection);
    }
  }
  // Detects address via user input
  detectAddress(userInput: string): void {
    this.googlePlacesLoading = true;

    // Make sure to not send too many requests
    clearTimeout(this.googlePlacesTimout);

    if (userInput?.length > 3) {
      this.googlePlacesTimout = setTimeout(() => {
        this.consumeGooglePlacesApi('autocomplete', userInput);
        this.googlePlacesLoading = false;
      }, 500);
    }
  }
}
</script>

<style lang="scss" scoped>
.google-places {
  &__icon {
    margin: 0 12px;
    color: map-get($neutral, 'darken-1') !important;
    flex-shrink: 0;
  }

  &__suggestion {
    display: flex;
    align-items: center;
    padding-right: 12px;
    margin: 2px 0;

    @media (max-width: 560px) {
      margin: 6px 0;
    }

    &-text {
      color: map-get($neutral, 'darken-4');
      font-size: 16px;

      @media (max-width: 560px) {
        font-size: 14px;
      }
    }
  }
}
</style>
