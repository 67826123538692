<template>
  <v-card elevation="0" color="transparent" class="empty-state">
    <v-img
      contain
      aspect-ratio="1"
      class="empty-state__illustration"
      :src="require('../assets/illustrations/' + illustration)"
      :width="width"
      :height="height"
    />
    <slot />
  </v-card>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class EmptyState extends Vue {
  @Prop()
  readonly illustration!: string;

  @Prop()
  readonly width?: number;

  @Prop()
  readonly height?: number;
}
</script>

<style lang="scss">
.empty-state {
  text-align: center;

  &__illustration {
    margin: 0 auto;
  }
}
</style>
