import AppConfig from '@/AppConfig';
import '@/amplify';
import { Team, User } from '@/classes/auth';
import store from '@/store';
import { ProviderType } from '@/store/auth';
import { Auth } from '@aws-amplify/auth';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import optimizelyAttributesInit from '@/plugins/optimizely';
import dayjs from 'dayjs';

import type { CognitoUserSession } from 'amazon-cognito-identity-js';

export function isExpired(expirationTS: number): boolean {
  return dayjs().isAfter(dayjs.unix(expirationTS));
}

export async function updateAuthStore(cognitoUserSession: CognitoUserSession): Promise<void> {
  const idToken = cognitoUserSession.getIdToken();
  const token = idToken.getJwtToken();

  await Promise.all([
    store.dispatch('auth/setTokenV1', token),
    store.dispatch('auth/setToken', token),
    store.dispatch('auth/setProviderType', getProviderType(idToken.payload.identities || [])),
  ]);
}

function getProviderType(identities: Array<{ [key: string]: any }>): ProviderType {
  if (Array.isArray(identities) && identities.length) {
    // Potentially enhance this later on to work with muliple providers, atm we are only using Google
    const provider = identities.find((item: any) => item.providerType === 'Google');
    return provider?.providerType || 'default';
  }
  return 'default';
}

// FIXME clean up
export default class Authenticator {
  static authUrl: string = AppConfig.getAuthServiceUrl() || '';

  static async init(): Promise<void> {
    try {
      let cognitoUserSession = await Auth.currentSession();
      updateAuthStore(cognitoUserSession);

      axios.interceptors.request.use(async (config) => {
        if (isExpired(cognitoUserSession.getIdToken().getExpiration())) {
          // triggers a refresh of the token from Cognito
          cognitoUserSession = await Auth.currentSession();
          updateAuthStore(cognitoUserSession);
        }
        const authHeader = `Bearer ${cognitoUserSession.getIdToken().getJwtToken()}`;

        if (config.headers) {
          config.headers.Authorization = authHeader;
        } else {
          config.headers = {
            Authorization: authHeader,
          } as any;
        }

        return config;
      });

      await this.fetchUser();
    } catch (e) {
      Sentry.captureException(e);

      return;
    }
  }

  static async fetchUser(): Promise<void> {
    await store.dispatch('layout/setLoadingIndicator', true);
    await axios
      .get(AppConfig.getAPIBaseUrl() + '/user/authenticated')
      .then((response) => {
        this.setUser(response.data);
        this.setTeam(response.data);
        optimizelyAttributesInit(response.data, response.data.team);
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error, {
          tags: {
            method: 'Authenticator.fetchUser',
          },
        });
        this.logout();
      })
      .finally(() => {
        store.dispatch('layout/setLoadingIndicator', false);
      });
  }

  // FIXME do not use as property, but use inline
  public static getUser(): User {
    return store.state.auth.user;
  }

  public static getTeam(): Team {
    return store.state.auth.team;
  }

  public static isAuthenticated(): boolean {
    return this.getUser().id > 0;
  }

  private static async setUser(user: User): Promise<void> {
    await store.dispatch('auth/setUser', user);
  }

  private static async setTeam(user: User): Promise<void> {
    await store.dispatch('auth/setTeam', user.team);
  }

  public static async logout(): Promise<void> {
    await store.dispatch('auth/logout');

    window.location.href = `${this.authUrl}/logout`;
  }
}
