import AppConfig from '@/AppConfig';
import { Team, User } from '@/classes/auth';
import store, { RootState } from '@/store';
import axios from 'axios';
import { Module } from 'vuex';
import * as Sentry from '@sentry/vue';

export type ProviderType = 'default' | 'Google';

export interface AuthState {
  // Private token from API v1
  tokenV1: string;
  // Private token from API v2
  token: string;
  // User from API v2
  user: User;
  // Team from API v2
  team: Team;
  providerType: ProviderType;
}

const AuthStore: Module<AuthState, RootState> = {
  namespaced: true,
  state: {
    tokenV1: '',
    token: '',
    user: new User(),
    team: new Team(),
    providerType: 'default',
  } as AuthState,
  mutations: {
    SET_TOKEN_V1(state, token) {
      state.tokenV1 = token;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_PROVIDER_TYPE(state, providerType: ProviderType) {
      state.providerType = providerType;
    },
    SET_USER(state, user) {
      state.user = new User(user);
    },
    SET_TEAM(state, team) {
      state.team = new Team(team);
    },
    SET_LANG(state, lang) {
      state.user.language = lang;
    },
    LOGOUT(state) {
      state.tokenV1 = '';
      state.token = '';
      state.user = new User();
      state.team = new Team();
    },
  },
  actions: {
    async setTokenV1({ commit }, token) {
      await commit('SET_TOKEN_V1', token);
    },
    async setToken({ commit }, token) {
      await commit('SET_TOKEN', token);
    },
    async setProviderType({ commit }, providerType: ProviderType) {
      await commit('SET_PROVIDER_TYPE', providerType);
    },
    async setUser({ commit }, user) {
      await commit('SET_USER', user);
    },
    async setTeam({ commit }, data: any) {
      await commit('SET_TEAM', data);
    },
    async setLang({ commit }, lang) {
      await commit('SET_LANG', lang);
    },
    async logout({ commit }) {
      await commit('LOGOUT');
    },
    async updateTeam({ commit, state }, data: any) {
      await commit('SET_TEAM', data);
      try {
        await axios
          .patch(AppConfig.getAPIBaseUrl() + '/teams/' + state.team.id, data)
          .then(() => {
            store.dispatch('layout/setSnackbar', {
              type: 'info',
              icon: 'info-system',
              text: 'Your company info has been updated successfully',
              isSnackbar: false,
              active: true,
            });
          })
          .catch(() => {
            store
              .dispatch('layout/setSnackbar', {
                type: 'error',
                icon: 'forbidden-system',
                text: 'We failed to update your company info. Please try again or contact support',
                isSnackbar: true,
                active: true,
              })
              .finally(() => store.dispatch('layout/setLoadingIndicator', false));
          });
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            type: 'API-Error',
            method: 'updateTeam',
          },
        });
      }
    },
  },
  getters: {
    user(state: AuthState): User {
      return state.user;
    },
    team(state: AuthState): Team {
      return state.team;
    },
  },
};

export default AuthStore;
