import { MiddlewareInterface } from '@/middleware/middlewareInterface';

export default async function seoMiddleware({ next, to, store }: MiddlewareInterface): Promise<MiddlewareInterface> {
  document.title = to.meta?.title || process.env.VUE_APP_NAME || '';
  const currency = store.state?.auth?.team?.currency || process.env.VUE_APP_DEFAULT_CURRENCY || 'eur';

  window.dataLayer?.push({
    event: 'pageview',
    page: {
      path: to.fullPath,
      title: document.title,
    },
    currency: currency,
  });

  // On every page navigation store route in our own location.history so we can make validations for back button
  await store.commit('app/LOCATION_HISTORY_ADD', to.fullPath);

  return next();
}

declare global {
  interface Window {
    dataLayer?: Array<unknown>;
  }
}
