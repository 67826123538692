<!--
 * @desc Icon component that uses v-icon by default and implements additional logic and props
 *
 * @prop name:string — the icon name to displayed
 * @prop large:boolean — use large icon size (24px x 24px)
 * @prop outline:boolean — use the outline icon style
 *
-->

<template>
  <v-icon v-bind="$attrs" :size="large && !$attrs.size ? 24 : $attrs.size || 16" v-on="$listeners">
    ${{ name }}-{{ getSize }}-{{ getType }}
  </v-icon>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import appIcons from '@/plugins/vuetify/icons';

@Component
export default class Icon extends Vue {
  @Prop(Boolean)
  large?: boolean;

  @Prop(Boolean)
  outline?: boolean;

  @Prop({
    required: true,
    validator(value: string): boolean {
      let isIcon = false;

      for (let icon in appIcons) {
        // Check if prop icon name exists in the list of all icons
        // We only check the name before words -fill and -outline
        if (icon.match(/.+?(?=-small|-large)/g)![0] === value) {
          isIcon = !isIcon;
          break;
        }
      }

      return isIcon;
    },
  })
  name!: string;

  get getSize(): string {
    return this.large ? 'large' : 'small';
  }

  get getType(): string {
    return this.outline ? 'outline' : 'fill';
  }
}
</script>

<style lang="scss">
.icon {
  fill: currentColor;
  width: unset;
  display: block;

  path {
    fill: currentColor;
  }

  &--mirror {
    transform: scale(-1);
  }

  &--transform {
    transform: rotate(270deg);
  }
}
</style>
