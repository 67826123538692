import AppConfig from '@/AppConfig';
import * as Sentry from '@sentry/vue';
import { Component, Vue } from 'vue-property-decorator';

@Component
export class SupportModalMixin extends Vue {
  toggleSupportModal(toggle: boolean): void {
    if (AppConfig.getZendeskSnippetKey()) {
      this.openZendeskModal(toggle);
    } else {
      // Open default support modal
      this.openSalesForceModal(toggle);
      Sentry.captureMessage(`[warning] SALESFORCE form loaded by calling toggleSupportModal()`);
    }
  }

  openZendeskModal(toggle: boolean): void {
    if (this.zendeskInit()) {
      if (!window.zE) {
        console.warn('ZENDESK FUNCTION IS NOT AVAILABLE INSIDE WINDOWS CONTEXT');
        Sentry.captureMessage(`[error] ZENDESK is not available inside windows context`);
      } else {
        window.zE('webWidget', 'toggle');
      }
    } else {
      // If Zendesk is not loaded/missing config it will fallback to salesforce modal
      this.openSalesForceModal(toggle);
      Sentry.captureMessage(`[warning] ZENDESK could not be loaded, SALESFORCE form was loaded as a fallback form`);
    }
  }

  openSalesForceModal(toggle: boolean): void {
    this.$store.commit('layout/toggleSupportModal', toggle);
  }

  zendeskInit(): boolean {
    const defaultTicketFormId = AppConfig.getZendeskSupportFormId();
    const egoditorIDZendeskFieldID = AppConfig.getZendeskSupportFormEgoditorId();
    const languageZendeskFieldID = AppConfig.getZendeskSupportFormLanguageId();
    const abTestsFieldId = AppConfig.getZendeskSupportFormAbTests();
    const priceTagFieldId = AppConfig.getZendeskSupportFormPriceTag();
    const { id, first_name, last_name, email } = this.$store.state.auth.user;
    const { price_tag, ab_tests } = this.$store.state.auth.team;

    if (!defaultTicketFormId || !egoditorIDZendeskFieldID || !languageZendeskFieldID) {
      console.warn('MISSING ZENDESK ENV VARIABLES CONFIG');
      Sentry.captureMessage(`[error] ZENDESK missing ENV variables config`);
      return false;
    }

    // Set locale if exists or fallback to default locale
    const lang = this.$i18n.locale || this.$i18n.fallbackLocale;

    window.zE(() => {
      window.zE.setLocale(lang);
    });

    const contactFrom = {
      fields: [
        { id: 'name', prefill: { '*': `${first_name} ${last_name}` } },
        { id: 'email', prefill: { '*': email } },
        // Language
        { id: +languageZendeskFieldID, prefill: { '*': lang } },
        // Egoditor ID
        { id: +egoditorIDZendeskFieldID, prefill: { '*': id } },
        // Ab Tests
        { id: +abTestsFieldId, prefill: { '*': ab_tests.length ? ab_tests.join(',') : '' } },
        // Price Tag
        { id: +priceTagFieldId, prefill: { '*': price_tag ? price_tag : '' } },
      ],
      // Select default form
      ticketForms: [{ id: +defaultTicketFormId }],
      title: { '*': 'Contact us' },
    };

    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: contactFrom,
      },
    });

    window.zE('webWidget:on', 'userEvent', function (event: { properties?: { id?: string } }) {
      // Listen for user interaction in form and hide field when user actually see the form
      if (`${event?.properties?.id}` === `${defaultTicketFormId}`) {
        setTimeout(() => {
          // Current way to hide Egoditor ID input inside the form
          const iFrame = document.querySelector('#webWidget') as HTMLIFrameElement;
          iFrame?.contentWindow?.document
            ?.querySelectorAll(
              [egoditorIDZendeskFieldID, abTestsFieldId, priceTagFieldId].map((key) => `[name="key:${key}"]`).join(', ')
            )
            .forEach((element) => element?.parentElement?.style?.setProperty('display', 'none'));
        }, 0);
      }
    });
    return true;
  }
}
