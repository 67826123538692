import AppConfig from '@/AppConfig';
import Authenticator from '@/Services/Auth/Authenticator';
import StripeService from '@/Services/Subscription/StripeService';
import store from '@/store';
import * as Sentry from '@sentry/vue';
import type {
  PaymentMethodResult,
  SourceResult,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js';
import { PaymentRequest, StripeElementBase, StripeElements } from '@stripe/stripe-js';
import type { AxiosError } from 'axios';
import axios from 'axios';

// Small helper for payment resource payload type
export interface PaymentResourcePayload {
  legal_entity: string;
  customer: {
    email: string;
    name: string;
    metadata: {
      first_name: string;
      last_name: string;
    };
    tax_id_data: {
      type: string;
      value: string;
    };
    address: {
      line1: string;
      line2: string;
      city: string;
      country: string;
      state: string;
      postal_code: string;
    };
  };
}

// TODO: replace any-type with checkout-api response type
// Type union for Stripe save payment-method and checkout-api response
export type PaymentMethodSaveResponse = PaymentMethodResult | SourceResult | any | null;

/**
 * StripePaymentMethodService is responsible for handling the payment method business logic
 * and provides some swift helpers for components.
 */
export class StripePaymentMethodService extends StripeService {
  // Mounted card form to collect payment method details
  card!: any;
  cardNumber!: StripeCardNumberElement;
  cardExpiry!: StripeCardExpiryElement;
  cardCvc!: StripeCardCvcElement;
  postalCode!: StripeElementBase;

  iban!: any;

  ibanData: {
    first_name: string | null;
    last_name: string | null;
  } = {
    first_name: '',
    last_name: '',
  };

  // Error message from Stripe API
  stripeErrorMessage = '';
  stripeError = '';

  // Prevents premature stepper click/form show, indicates if the payment method form is ready
  isPaymentFormLoaded = false;

  paymentMethodsLoading = false;
  paymentMethodsToShow: Array<any> = [];

  // Which payment type is used?
  paymentType: string = 'card';
  setupIntent: boolean = false;

  walletsAvailable: object = {};
  paymentRequest: PaymentRequest | undefined;

  inputStyles = {
    base: {
      color: '#1B294B',
      fontWeight: 400,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
    invalid: {
      color: '#E25950',
    },
  };

  // Init a payment method form, pass callback to declare and configure type of payment method
  initPaymentForm(): StripeElements {
    this.card?.unmount();
    this.isPaymentFormLoaded = false;

    // amount cannot be longer than 8 Digits, according to Stripe docs
    const amount = Math.round(store.state.subscription.selectedPlan.price.price * 100);

    // Create the stripe elements payment form, unmount old and mount new one
    const stripeElements: StripeElements = this.stripe?.elements({
      mode: 'subscription',
      amount,
      currency: store.state.subscription.selectedPlan.price.currency,
    });

    return stripeElements;
  }

  /**
   * Gets payment-methods for user
   */
  async getAllowedPaymentMethodsForUser(country: string | null = null): Promise<void> {
    this.paymentMethodsLoading = true;

    try {
      const response = await axios.get(
        AppConfig.getAPIBaseUrl() + '/system/allowed-payment-methods' + (country ? '?filter[country]=' + country : '')
      );

      this.paymentMethodsToShow = response.data.data;
    } catch (error) {
      Sentry.captureException(error);
    }

    this.paymentMethodsLoading = false;
  }

  // Create the payment method form for credit card
  initCardForm(): void {
    this.cardFormLoading(true);

    const stripeElements = this.initPaymentForm();

    if (!stripeElements) {
      this.cardFormLoading(false);
      return;
    }

    const stripeElementsToCheck = [this.cardNumber, this.cardExpiry, this.cardCvc, this.postalCode];
    stripeElementsToCheck.forEach((stripeElement) => {
      // Check if the element is already created and if so unmount it
      if (stripeElement) {
        stripeElement.unmount();
      }
    });

    this.cardNumber = stripeElements.create('cardNumber', { style: this.inputStyles, showIcon: true });
    this.cardExpiry = stripeElements.create('cardExpiry', { style: this.inputStyles });
    this.cardCvc = stripeElements.create('cardCvc', { style: this.inputStyles });
    //TODO: re-evaluate the need of the postalCode field since it is no longer officially part of the stripe js sdk
    this.postalCode = stripeElements.create('postalCode' as any, {
      style: this.inputStyles,
    });

    const elements = {
      cardNumber: this.cardNumber,
      cardExpiry: this.cardExpiry,
      cardCvc: this.cardCvc,
      postalCode: this.postalCode,
    };
    Object.entries(elements).forEach(([domId, element]) => {
      try {
        element.mount(`#${domId}`);
      } catch (e) {
        Sentry.captureException(e, {
          tags: {
            method: 'initCardForm',
          },
        });
      }
    });

    this.cardFormLoading(false);
  }

  // Create the payment method form for sepa_debit
  initSEPAForm(): void {
    this.cardFormLoading(true);
    const stripeElements = this.initPaymentForm();
    this.iban = stripeElements.create('iban', { style: this.inputStyles, supportedCountries: ['SEPA'] });
    try {
      this.iban.mount('#iban');
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          method: 'initSEPAForm',
        },
      });
    }
    this.cardFormLoading(false);
  }

  initWallets(): void {
    this.cardFormLoading(true);

    // The whole purpose of this is to instantiate the payment request and check if we can make wallet payments
    this.initPaymentForm();
    try {
      // amount cannot be longer than 8 Digits, according to Stripe docs
      const amount = Math.round(store.state.subscription.selectedPlan.price.price * 100);

      this.paymentRequest = this.stripe?.paymentRequest({
        country: store.state.auth.user.stripe_instance_country,
        currency: store.state.subscription.selectedPlan.price.currency,
        total: {
          label: store.state.subscription.selectedPlan.planName,
          amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // This helps show the option to pay with a wallet like Google or Apple Pay
      (async () => {
        // Check the availability of the Payment Request API first.
        const result = await this.paymentRequest?.canMakePayment();
        if (result) {
          this.walletsAvailable = result;
          // traditionally this is when you'd show the button
        }
      })();
    } catch (e) {
      Sentry.captureException(e, {
        tags: {
          method: 'initWallets',
        },
        extra: {
          country: store.state.auth.user.stripe_instance_country,
          message: '[StripePaymentMethodService] Failed to initialize wallet payment methods',
        },
      });
    }
    this.cardFormLoading(false);
  }

  // Loading state when initCartForm/initSEPAForm is applied
  cardFormLoading(loading: boolean): void {
    if (loading) {
      document.body.classList.add('body--disable');
    } else {
      document.body.classList.remove('body--disable');
      this.isPaymentFormLoaded = true;
    }
    store.commit('layout/toggleGlobalOverlay', loading);
    store.commit('layout/toggleGlobalLoader', loading);
  }

  // Call this router method to save the actual payment method type.
  async savePaymentMethod(payload: PaymentResourcePayload): Promise<any> {
    const observe = async (saveRequest: Promise<any>) =>
      saveRequest
        .then((result: PaymentMethodSaveResponse) => this.handleStripeConfirmation(result, payload))
        .catch(() => Promise.resolve(null));

    switch (this.paymentType) {
      case 'card':
        return observe(this.saveCard());
      case 'sepa_debit':
        return observe(this.saveIBAN(payload));
      case 'send_invoice':
        return this.saveInvoice(payload);
      case 'paypal':
        if (!this.setupIntent) {
          return observe(this.savePaypal());
        } else {
          return observe(await this.savePaypalFuturePayment());
        }
      default:
        return Promise.resolve(null);
    }
  }

  savePaypal(): Promise<PaymentMethodResult | null> {
    try {
      // For checkout, we use this one (payment intent)
      return this.stripe.createPaymentMethod({
        type: 'paypal',
      });
    } catch (e) {
      Sentry.captureException(e, {
        user: {
          id: Authenticator.getUser().id,
          email: Authenticator.getUser().email,
          team_id: Authenticator.getTeam().id,
        },
        tags: {
          method: 'savePaypal',
        },
      });
      return Promise.resolve(null);
    }
  }

  async savePaypalFuturePayment(): Promise<any> {
    const { data: setupIntent } = await axios({
      method: 'post',
      url: `${AppConfig.getAPIBaseUrl()}/subscription/stripe/payment-methods/create`,
      data: {
        payment_method_type: 'paypal',
      },
    });

    // persist a temporary piece of data that will get removed once the switch is complete
    await axios({
      method: 'post',
      url: AppConfig.getAPIBaseUrl() + '/team/payment-method-transition/create',
    });

    // Redirect to the processing page
    const redirectUrl = `${AppConfig.getAppUrl()}/processing?returningFromPaypal=true&step=3`;

    const { error } = await this.stripe.confirmPayPalSetup(setupIntent.client_secret, {
      return_url: redirectUrl,
      mandate_data: {
        customer_acceptance: {
          type: 'online',
          online: {
            infer_from_client: true,
          },
        },
      },
    });

    if (error) {
      Sentry.captureException(error, {
        user: {
          id: Authenticator.getUser().id,
          email: Authenticator.getUser().email,
          team_id: Authenticator.getTeam().id,
        },
        tags: {
          method: 'savePaypalFuturePayment',
        },
      });
    }
  }

  /**
   * Create a new credit card as payment method in Stripe.
   */
  saveCard(): Promise<PaymentMethodResult | null> {
    try {
      // For checkout, we use this one (payment intent)
      return this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumber,
      });
    } catch (e) {
      Sentry.captureException(e, {
        user: {
          id: Authenticator.getUser().id,
          email: Authenticator.getUser().email,
          team_id: Authenticator.getTeam().id,
        },
        tags: {
          method: 'saveCard',
        },
      });
      return Promise.resolve(null);
    }
  }

  /**
   * Create SEPA direct debit as Stripe source.
   *
   * @param payload
   */
  async saveIBAN(payload: PaymentResourcePayload): Promise<SourceResult | null> {
    try {
      const user = await Authenticator.getUser();
      const {
        metadata: { first_name, last_name },
      } = payload.customer;
      const firstName = first_name || user.team.first_name;
      const lastName = last_name || user.team.last_name;

      return await this.stripe.createSource(this.iban, {
        type: 'sepa_debit',
        currency: 'eur',
        owner: {
          name: `${firstName} ${lastName}`,
        },
      });
    } catch (e) {
      Sentry.captureException(e, {
        user: {
          id: Authenticator.getUser().id,
          email: Authenticator.getUser().email,
          team_id: Authenticator.getTeam().id,
        },
        tags: {
          method: 'saveIBAN',
        },
      });
      return Promise.resolve(null);
    }
  }

  /**
   * Invoice payment method needs no intent token because it is no real payment method (not saved in stripe),
   * instead we just say that a subscription should just trigger an invoice without automatic payment via
   * default payment method. Therefore we pass a different collection_method value to tell stripe about it.
   */
  async saveInvoice(payload: PaymentResourcePayload): Promise<any> {
    return await axios
      .post(
        AppConfig.getAPIBaseUrl() + '/subscription/stripe/payment-methods',
        Object.assign(
          {
            payment_method_id: null,
          },
          payload
        )
      )
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
      });
  }

  // After confirmation via stripe is done this one will be called to inform our API about the new payment method.
  async handleStripeConfirmation(result: any, payload: PaymentResourcePayload): Promise<any> {
    if (result && !result.error) {
      return axios
        .post(
          AppConfig.getAPIBaseUrl() + '/subscription/stripe/payment-methods',
          Object.assign(
            {
              new_payment_method_id:
                result.source?.id || result.paymentMethod?.id || result.setupIntent?.payment_method || null,
            },
            payload
          )
        )
        .catch((error) => {
          if (['lost_card', 'stolen_card'].includes(error.response.data.decline_code)) {
            Authenticator.logout();
          }
          this.stripeErrorMessage = error?.response?.data?.error_message || '';
          this.stripeError = error?.response?.data?.decline_code || error?.response?.data?.error_code || '';

          Sentry.withScope((scope: Sentry.Scope) => {
            scope.setExtra('decline_code', error?.response?.data?.decline_code);
            scope.setExtra('error_code', error?.response?.data?.error_code);
            scope.setExtra('error_message', error?.response?.data?.error_message);
            scope.setTag('method', 'handleStripeConfirmation');
            Sentry.captureMessage('[StripePaymentMethodService] Failed payment method confirmation with Checkout-API');
          });
        })
        .finally(() => {
          // Refresh user instance because we maybe updated customer data, too.
          Authenticator.fetchUser();
        });
    } else {
      this.stripeErrorMessage = result?.error?.message || '';
      this.stripeError = result?.error?.code || '';
    }
    return Promise.resolve(null);
  }
}
