<template>
  <v-app id="inspire">
    <!--Add condition and preloading in App as well so we have smooth transition if we have redirection between new and old app-->
    <Mainframe v-if="$store.state.layout.layoutInitialized" />
    <div v-else id="preloading">
      <div class="qr-loader" />
    </div>
  </v-app>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import Mainframe from '@/components/Layout/Mainframe.vue';

@Component({
  components: { Mainframe },
})
export default class Main extends Vue {}
</script>

<style lang="scss">
// Vuetify overrides
.row {
  margin-top: 0;
  margin-bottom: 0;
}

.row + .row {
  margin-top: 0;
}
</style>
