<template>
  <div>
    <v-row justify="center">
      <v-col md="11">
        <v-row>
          <v-col cols="12">
            <ValidationProvider
              ref="checkout.customer.email"
              v-slot="{ errors }"
              name="user.email"
              rules="required|email"
            >
              <v-text-field
                v-model="checkoutService.customer.email"
                :error-messages="errors[0]"
                :label="$t('fields.user.email')"
                outlined
                hide-details
                dense
                class="text-field"
                type="email"
                persistent-hint
              />
            </ValidationProvider>

            <v-col class="pa-0 caption">
              {{ $t('fields.checkout.form.invoice_hint') }}
            </v-col>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="px-3 py-1">
            <ValidationProvider v-slot="{ errors }" rules="max:30">
              <v-textarea
                v-if="showNotes"
                v-model="checkoutService.customer.invoice_settings.custom_fields[0].value"
                :label="$t('fields.checkout.form.notes')"
                :placeholder="$t('fields.checkout.form.note_placeholder')"
                :counter="30"
                :error-messages="errors[0]"
                outlined
                dense
                class="text-field pb-0"
                maxlength="30"
                height="80"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="checkout__notes-wrapper" :class="showNotes ? 'pt-0' : null">
            <v-btn v-if="!showNotes" text :ripple="false" color="primary" small @click.stop="showNotes = true">
              <Icon name="edit-system" left outline small />
              <span class="navy--text">{{ $t('fields.checkout.form.add_note') }}</span>
            </v-btn>

            <span v-if="!showNotes" class="checkout__notes-hint">
              {{ $t('fields.checkout.form.note_hint') }}
            </span>

            <v-btn v-if="showNotes" text :ripple="false" color="primary" small @click.stop="removeNotes">
              <Icon name="delete-system" left outline small />
              <span class="navy--text">{{ $t('fields.checkout.form.remove_note') }}</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-divider />
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import Icon from '@/components/Icons/Icon.vue';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    Icon,
    ValidationProvider,
  },
})
export default class EmailAndNotes extends Vue {
  @Prop({ type: StripeCheckoutService })
  checkoutService!: StripeCheckoutService;
  showNotes = false;
  /**
   * Hides the note input and clears it
   */
  removeNotes(): void {
    this.showNotes = false;
    if (this.checkoutService.customer.invoice_settings.custom_fields) {
      this.checkoutService.customer.invoice_settings.custom_fields[0].value = '';
    }
  }
}
</script>
