// @ts-ignore
import { Intercom } from '@mathieustan/vue-intercom';
import store from '@/store';
import AppConfig from '@/AppConfig';
const intercom = new Intercom({ appId: AppConfig.getIntercomAppId() });
const CryptoJS = require('crypto-js');

interface IntercomConfig {
  user_id: string;
  name?: string;
  email: string;
  user_hash?: string;
}

function intercomInit(): void {
  if (intercom.isBooted) return;
  intercom.boot(getIntercomConfig());
}

function getIntercomConfig(): IntercomConfig {
  const config = {
    user_id: store.state.auth.user.id,
    name: `${store.state.auth.user.first_name || ''} ${store.state.auth.user.last_name || ''}`,
    email: store.state.auth.user.email,
  };

  // user_hash is needed only in production, local testing does not need it
  if (process.env.NODE_ENV === 'production') {
    Object.assign(config, {
      user_hash: CryptoJS.HmacSHA256(store.state.auth.user.id.toString(), AppConfig.getIntercomSecret()).toString(
        CryptoJS.enc.Hex
      ),
    });
  }

  return config;
}

export const intercomShutdown = function (): void {
  intercom.shutdown();
};

export const intercomBoot = function (): void {
  if (!intercom.ready) {
    intercom.once('ready', () => intercomInit());
  } else {
    intercomInit();
  }
};
