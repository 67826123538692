import AppConfig from '@/AppConfig';
import store from '@/store';
import axios from 'axios';

export default class EventService {
  static readonly VISITED_PRICING = 'visited_page_pricing';
  static readonly VISITED_CHECKOUT = 'visited_page_checkout';
  static readonly VISITED_PAGE_BILLING = 'visited_page_billing';

  public static createEvent(eventName: string, payload: Record<string, unknown> = {}): void {
    // Add AB test data to the payload if the team is assigned to some tests
    if (store.state.auth.team.ab_tests.length) {
      if (payload.ab_tests) {
        payload.ab_tests += `, ${store.state.auth.team.ab_tests.join(',')}`;
      } else {
        payload.ab_tests = store.state.auth.team.ab_tests.join(',');
      }
    }

    axios.post(AppConfig.getAPIBaseUrl() + '/events', {
      event_name: eventName,
      payload: JSON.stringify(payload),
    });
  }

  private attachAbTestsToPayload() {}
}
