<template>
  <div>
    <!-- PAYMENT DATA & INFORMATION -->
    <v-row align="stretch">
      <!-- PAYMENT DETAILS -->
      <v-col cols="12" md="6" class="py-0 mb-4">
        <v-card elevation="0" class="fill-height qr-card--account-background">
          <!-- Title -->
          <v-card-title class="pa-0">
            <h4 class="text-h4 lapis--text text--lighten-1">
              {{ $t('fields.account.billing.payment.payment_details') }}
            </h4>
          </v-card-title>

          <v-card-text class="pa-0 mt-4">
            <v-row dense>
              <!-- CUSTOMER ID -->
              <v-col cols="6" class="pt-0">
                <v-card outlined rounded class="px-3 py-4 text-center">
                  <div class="text-overline--small neutral--text text--darken-1">
                    {{ $t('fields.account.billing.payment.customer_id') }}
                  </div>
                  <h5 class="text-h5 mt-3 lapis--text text--lighten-2">App-u-{{ user.id }}</h5>
                </v-card>
              </v-col>
              <!-- CUSTOMER ID END -->

              <!-- NEXT RENEWAL -->
              <v-col cols="6" class="pt-0">
                <!-- SKELETON LOADING -->
                <v-card v-if="activePlan.id === 0" outlined rounded class="px-3 py-4" height="90">
                  <div class="d-flex justify-center">
                    <v-skeleton-loader type="text" width="100" />
                  </div>

                  <div class="d-flex justify-center align-center mt-3">
                    <v-skeleton-loader type="image" width="150" height="24" class="rounded-pill" />
                  </div>
                </v-card>
                <!-- SKELETON LOADING END -->

                <!-- DATA -->
                <v-card
                  v-if="activePlan.id"
                  outlined
                  rounded
                  class="px-3 py-4 text-center"
                  data-test="activeSubscription-period"
                >
                  <div class="text-overline--small neutral--text text--darken-1">
                    {{ $t('fields.account.billing.payment.next_renewal') }}
                  </div>
                  <h5 class="text-h5 mt-3 lapis--text text--lighten-2">
                    {{ activeSubscription.current_period_end.format('MMM DD, YYYY') }}
                  </h5>
                </v-card>
                <!-- DATA END -->
              </v-col>
              <!-- NEXT RENEWAL END -->

              <!-- SUBSCRIPTION PLAN -->
              <v-col cols="12">
                <!-- SKELETON LOADING -->
                <v-card
                  v-if="!activePlan.id && !errorSubscription && !tryAgainSubscription && !hasInvoices"
                  outlined
                  rounded
                  class="px-3 py-4"
                  height="90"
                >
                  <div class="d-flex justify-space-between">
                    <v-skeleton-loader type="text" width="100" />
                    <v-skeleton-loader type="image" width="55" height="20" class="rounded" />
                  </div>

                  <div class="d-flex justify-space-between align-center mt-3">
                    <v-skeleton-loader type="heading" width="225" height="24" class="rounded-pill" />
                    <v-skeleton-loader type="image" width="100" height="20" class="rounded" />
                  </div>
                </v-card>
                <!-- SKELETON LOADING END -->

                <!-- ERROR -->
                <v-card v-if="errorSubscription || tryAgainSubscription" outlined rounded class="px-3 py-4" height="90">
                  <div class="d-flex justify-space-between">
                    <!-- Title -->
                    <div class="text-overline--small neutral--text text--darken-1">
                      {{ $t('fields.account.billing.payment.subscription_plan') }}
                    </div>
                  </div>

                  <Alert
                    v-if="errorSubscription"
                    type="error"
                    icon="forbidden-system"
                    inline
                    regular-title
                    small
                    class="mt-3"
                  >
                    <template #title>
                      <span class="font-weight-semi-bold">{{
                        $t('fields.account.billing.payment.subscription_error')
                      }}</span>
                      <v-btn
                        name="btn-try-again"
                        text
                        :ripple="false"
                        color="primary"
                        x-small
                        class="ml-1"
                        @click="loadSubscriptionPlan(true)"
                      >
                        <span class="navy--text">
                          {{ $t('fields.account.billing.payment.try_again') }}
                        </span>
                      </v-btn>
                    </template>
                  </Alert>

                  <v-progress-circular
                    v-if="tryAgainSubscription"
                    indeterminate
                    :width="4"
                    :size="24"
                    color="candy"
                    class="mt-2"
                  />
                </v-card>
                <!-- ERROR END -->

                <!-- DATA -->
                <v-card v-if="activePlan.id && !errorSubscription" outlined rounded class="px-3 py-4">
                  <div class="d-flex justify-space-between align-center">
                    <div class="text-overline--small neutral--text text--darken-1">
                      {{ $t('fields.account.billing.payment.subscription_plan') }}
                    </div>

                    <!-- Action -->
                    <v-btn
                      v-if="upgradeButton"
                      name="btn-upgrade"
                      text
                      :ripple="false"
                      color="primary"
                      x-small
                      to="/features-and-pricing"
                      data-test="billing-page-upgrade-button"
                    >
                      <span class="navy--text">{{ $t('fields.account.billing.payment.upgrade') }}</span>
                    </v-btn>
                  </div>

                  <div class="d-flex justify-space-between align-center mt-3">
                    <!-- Title -->
                    <h5 v-if="activePlan.id" class="text-h5 lapis--text text--lighten-2">
                      {{ activePlanTitle }}
                      <span class="text-caption">({{ $t(getBillingInfoTranslationKey(activePlan)) }})</span>
                    </h5>
                    <!-- Cancel subscription: button -->
                    <v-btn
                      v-if="!activeSubscription.is_cancelled && !showLoadingSubscription.reactivateSubscription"
                      name="btn-cancel-subscription"
                      text
                      :ripple="false"
                      color="primary"
                      x-small
                      @click="openDialog('subscriptionCancelPause')"
                    >
                      <span v-if="!showLoadingSubscription.reactivateSubscription" class="navy--text">
                        {{ $t('fields.account.billing.payment.subscription_cancel') }}
                      </span>
                    </v-btn>

                    <!-- Resume: button -->
                    <v-btn
                      v-if="false"
                      name="btn-resume-subscription"
                      text
                      :ripple="false"
                      color="primary"
                      x-small
                      @click="openDialog('subscriptionResume')"
                    >
                      <span class="navy--text">
                        {{ $t('fields.account.billing.payment.subscription_resume') }}
                      </span>
                    </v-btn>

                    <!-- Renew: button -->
                    <v-btn
                      v-if="expiredSubscription"
                      name="btn-renew-subscription"
                      text
                      :ripple="false"
                      color="primary"
                      x-small
                      @click="onRenewSubscription(activePlan)"
                    >
                      <span class="navy--text">
                        {{ $t('fields.account.billing.payment.subscription_renew') }}
                      </span>
                    </v-btn>

                    <v-btn
                      v-if="
                        isUserOnAbtestVariantENG1738() &&
                        activeSubscription.is_cancelled &&
                        activeSubscription.is_active &&
                        !expiredSubscription
                      "
                      name="btn-reactivate"
                      text
                      :ripple="false"
                      color="primary"
                      x-small
                      class="d-inline-block float-right"
                      @click="openDialog('subscriptionReactivate')"
                    >
                      <span class="navy--text mr-n2 mr-lg-0 mr-xl-0"> Enable Auto-Renewal </span>
                    </v-btn>
                  </div>

                  <!-- Canceled banner -->
                  <v-banner
                    v-if="activeSubscription.is_cancelled && activeSubscription.is_active && !expiredSubscription"
                    class="qr-banner--card primary--text text--lighten-1"
                    data-test="scheduled-downgrade-banner"
                  >
                    <Icon name="info-system" outline size="12" color="navy lighten-1" />
                    <span class="body-2 lapis--text text--lighten-2">
                      <!--Check if plan is downgrading and if we have plan downgrade plan-->
                      <template v-if="isDowngrading">
                        {{
                          $t('fields.account.billing.payment.subscription_is_downgraded', {
                            plan_name: newPlanName,
                            active_until: $t(activeSubscription.ends_at.format('MMM DD, YYYY')),
                          })
                        }}
                      </template>
                      <template v-else>
                        <span v-if="!isUserOnAbtestVariantENG1738()">
                          {{
                            $t('fields.account.billing.payment.subscription_is_cancelled', {
                              cancelled_on: $t(activeSubscription.updated_at.format('MMM DD, YYYY')),
                              active_until: $t(activeSubscription.ends_at.format('MMM DD, YYYY')),
                            })
                          }}
                        </span>
                        <span v-if="isUserOnAbtestVariantENG1738()">
                          Auto-renewal disabled on {{ $t(activeSubscription.updated_at.format('MMM DD, YYYY')) }}.
                          Subscription active until {{ $t(activeSubscription.ends_at.format('MMM DD, YYYY')) }}
                        </span>
                      </template>
                    </span>
                    <!-- Reactivate: button -->
                    <v-btn
                      v-if="
                        !isUserOnAbtestVariantENG1738() &&
                        activeSubscription.is_cancelled &&
                        activeSubscription.is_active &&
                        !expiredSubscription
                      "
                      name="btn-reactivate"
                      text
                      :ripple="false"
                      color="primary"
                      x-small
                      class="d-inline-block float-right"
                      data-test="reactivate-subscription-button"
                      @click="openDialog('subscriptionReactivate')"
                    >
                      <span class="navy--text mr-n2 mr-lg-0 mr-xl-0">
                        {{ $t('fields.account.billing.payment.subscription_reactivate') }}
                      </span>
                    </v-btn>
                  </v-banner>

                  <!-- Expired banner -->
                  <v-banner v-if="expiredSubscription" class="qr-banner--card banana--text text--lighten-1">
                    <Icon name="warning-system" outline size="12" color="banana darken-1" />
                    <span class="body-2 lapis--text text--lighten-2">
                      {{
                        $t('fields.account.billing.payment.subscription_is_expired', {
                          date: $t(activeSubscription.ends_at.format('MMM DD, YYYY')),
                        })
                      }}
                    </span>
                  </v-banner>

                  <!-- "Discount Active" banner -->
                  <v-banner v-if="isDiscountBannerVisible" class="qr-banner--card turquoise--text text--lighten-1">
                    <Icon name="checkmark-system" outline size="12" color="turquoise darken-1" />
                    <span class="body-2 lapis--text text--lighten-2">
                      {{ $t('fields.account.billing.payment.discount_active_confirmation') }}
                    </span>
                  </v-banner>
                </v-card>

                <v-card v-if="hasInvoices && !activePlan.id" outlined rounded class="px-3 py-4">
                  <div class="d-flex justify-space-between align-center">
                    <div class="text-overline--small neutral--text text--darken-1">
                      {{ $t('fields.account.billing.payment.no_active_subscription_plan') }}
                    </div>
                    <v-btn
                      v-if="!activePlan.id"
                      name="btn-buy"
                      text
                      :ripple="false"
                      color="primary"
                      x-small
                      to="/features-and-pricing"
                    >
                      <span v-if="!showLoadingSubscription.reactivateSubscription" class="navy--text">
                        {{ $t('fields.account.billing.payment.buy') }}
                      </span>
                    </v-btn>
                  </div>
                </v-card>
                <!-- DATA END -->
              </v-col>
              <!-- SUBSCRIPTION PLAN END -->

              <!-- PAYMENT METHOD -->
              <PaymentMethodSummary
                :is-team-payment-method-invoice="team.is_payment_method_invoice"
                @update-payment-address="updatePaymentAddress"
              />
              <!-- PAYMENT METHOD END -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- PAYMENT DETAILS END -->

      <!-- INFORMATION -->
      <v-col cols="12" md="6" class="py-0 mb-4">
        <v-card elevation="0" class="fill-height qr-card--account-background">
          <!-- Title -->
          <v-card-title class="pa-0">
            <h4 class="text-h4">
              {{ $t('fields.account.billing.info.info') }}
            </h4>
          </v-card-title>

          <v-card-text class="pa-0 mt-4">
            <v-row dense>
              <!-- COMPANY -->
              <v-col cols="12">
                <v-card outlined rounded class="px-4 py-5">
                  <!-- Title -->
                  <div class="d-flex justify-space-between align-center">
                    <div class="text-overline--small neutral--text text--darken-1">
                      {{ $t('fields.account.billing.info.billing_address') }}
                    </div>
                  </div>

                  <!-- Company name -->
                  <div
                    v-if="team.legal_entity === legalEntityB2B"
                    class="d-flex justify-space-between align-center my-3"
                  >
                    <!-- Title -->
                    <h5 class="text-h5 lapis--text text--lighten-2" data-test="companyField">
                      {{ team.company }}
                    </h5>

                    <!-- Action -->
                    <v-btn
                      text
                      name="btn-edit-address"
                      :ripple="false"
                      color="primary"
                      x-small
                      @click="
                        openDialog('addressEdit');
                        isPaymentMode = false;
                      "
                    >
                      <span class="navy--text"> {{ $t('fields.account.billing.payment.edit') }} </span>
                    </v-btn>
                  </div>

                  <!-- Address -->
                  <v-list class="transparent">
                    <v-list-item>
                      <v-list-item-title
                        class="text-h5 lapis--text text--lighten-2 d-flex justify-space-between align-center"
                        :class="
                          $store.state.auth.team.legal_entity === legalEntityB2C
                            ? 'font-weight-bold'
                            : 'font-weight-regular'
                        "
                        data-test="firstAndLastNameField"
                      >
                        {{ $store.state.auth.team.first_name }} {{ $store.state.auth.team.last_name }}

                        <!-- Action -->
                        <v-btn
                          v-if="$store.state.auth.team.legal_entity === legalEntityB2C"
                          name="btn-edit-address"
                          text
                          :ripple="false"
                          color="primary"
                          x-small
                          @click="
                            openDialog('addressEdit');
                            isPaymentMode = false;
                          "
                        >
                          <span class="navy--text"> {{ $t('fields.account.billing.payment.edit') }} </span>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="text-h5 font-weight-regular lapis--text text--lighten-2"
                        data-test="addressField"
                      >
                        {{ $store.state.auth.team.line1 }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="text-h5 font-weight-regular lapis--text text--lighten-2"
                        data-test="zipCodeAndCityField"
                      >
                        {{ $store.state.auth.team.postal_code }}, {{ $store.state.auth.team.city }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="text-h5 font-weight-regular lapis--text text--lighten-2"
                        data-test="countryField"
                      >
                        {{ $t('fields.countries.' + $store.state.auth.team.country.toLowerCase()) }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <!-- COMPANY END -->

              <!-- VAT ID -->
              <v-col v-if="isCustomerB2BAndNeedsVatId" cols="12">
                <v-card outlined rounded class="px-3 py-4">
                  <div class="d-flex justify-space-between align-center">
                    <!-- Title -->
                    <div class="text-overline--small neutral--text text--darken-1">
                      {{ $t('fields.checkout.form.vat_id') }}
                    </div>
                  </div>

                  <div class="d-flex justify-space-between align-center mt-3">
                    <!-- Title -->
                    <h5 class="text-h5 lapis--text text--lighten-2">
                      {{ team.vat_id }}
                    </h5>

                    <!-- Action -->
                    <v-btn text :ripple="false" color="primary" x-small @click="openDialog('vatIdEdit')">
                      <span class="navy--text">
                        {{ $t('fields.account.billing.payment.edit') }}
                      </span>
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
              <!-- VAT ID END -->

              <!-- INVOICE EMAIL -->
              <v-col cols="12" class="pb-0">
                <v-card outlined rounded class="px-3 py-4">
                  <div class="d-flex justify-space-between align-center">
                    <!-- Title -->
                    <div class="text-overline--small neutral--text text--darken-1">
                      {{ $t('fields.account.billing.info.invoice_email') }}
                    </div>
                  </div>

                  <div class="d-flex justify-space-between align-center mt-3">
                    <!-- Title -->
                    <h5 class="text-h5 lapis--text text--lighten-2 text-truncate" data-test="invoiceEmailField">
                      {{ $store.state.auth.team.email }}
                    </h5>

                    <!-- Action -->
                    <v-btn text :ripple="false" color="primary" x-small @click="openDialog('invoiceEmailEdit')">
                      <span class="navy--text">
                        {{ $t('fields.account.billing.payment.edit') }}
                      </span>
                    </v-btn>
                  </div>
                </v-card>
              </v-col>
              <!-- INVOICE EMAIL END -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- INFORMATION END -->
    </v-row>
    <Invoices />

    <!-- Billing address edit -->
    <billing-address-edit
      :open="isDialogShown['addressEdit']"
      :is-payment-mode="isPaymentMode"
      @cancel="isDialogShown['addressEdit'] = false"
    />
    <vat-id-edit
      :open="isDialogShown['vatIdEdit']"
      :is-team-updating="isTeamUpdating"
      @cancel="isDialogShown['vatIdEdit'] = false"
    />
    <cancel-pause-subscription
      :open="isDialogShown['subscriptionCancelPause']"
      @cancel="isDialogShown['subscriptionCancelPause'] = false"
    />
    <reactivate-subscription
      :open="isDialogShown['subscriptionReactivate']"
      :renew="activeSubscription.current_period_end.format('MMM DD, YYYY')"
      @cancel="isDialogShown['subscriptionReactivate'] = false"
    />
    <resume-subscription
      :subscription-end="activeSubscription.ends_at"
      :open="isDialogShown['subscriptionResume']"
      @cancel="isDialogShown['subscriptionResume'] = false"
    />
    <invoice-email-edit :open="isDialogShown['invoiceEmailEdit']" @done="isDialogShown['invoiceEmailEdit'] = false" />
  </div>
</template>
<script lang="ts">
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import { Team, User } from '@/classes/auth';
import { Invoice, LoadingState, Plan, Subscription } from '@/classes/stripe';
import store from '@/store';
import dayjs from 'dayjs';

import OptimizelyService from '@/Services/OptimizelyService';
import Alert from '@/components/Alert.vue';
import Icon from '@/components/Icons/Icon.vue';
import BillingAddressEdit from '@/components/Modals/Account/BillingAddressEdit.vue';
import CancelPauseSubscription from '@/components/Modals/Account/CancelPauseSubscription.vue';
import InvoiceEmailEdit from '@/components/Modals/Account/InvoiceEmailEdit.vue';
import ReactivateSubscription from '@/components/Modals/Account/ReactivateSubscription.vue';
import ResumeSubscription from '@/components/Modals/Account/ResumeSubscription.vue';
import VatIdEdit from '@/components/Modals/Account/VatIdEdit.vue';

import Invoices from '@/components/Stripe/Invoices.vue';
import PaymentMethodSummary from '@/components/Subscription/PaymentMethodSummary.vue';
import { Component, Vue } from 'vue-property-decorator';
import { isFeatureEnabled } from '@/Services/FeatureFlagService';
import { optimizelyFeatureFlags } from '@/plugins/optimizely-feature-flags';
import PromoCodeService from '@/Services/PromoCodeService';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    Invoices,
    BillingAddressEdit,
    VatIdEdit,
    CancelPauseSubscription,
    ReactivateSubscription,
    ResumeSubscription,
    InvoiceEmailEdit,
    Icon,
    Alert,
    PaymentMethodSummary,
  },
})
export default class activeSubscription extends Vue {
  team: Team = store.state.auth.team;
  // Modal states
  isDialogShown: any = {
    subscriptionCancelPause: false,
    addressEdit: false,
    vatIdEdit: false,
    invoiceEmailEdit: false,
    subscriptionResume: false,
    subscriptionReactivate: false,
  };

  isPaymentMode = false;

  legalEntityB2B: string = 'b2b';
  legalEntityB2C: string = 'b2c';

  tryAgainSubscription = false;
  errorSubscription = false;

  // Update state of update team request
  isTeamUpdating = false;

  isDiscountModalCouponActive = PromoCodeService.isCouponCodeEnabled();

  isFeatureEng2202Enabled = isFeatureEnabled(
    optimizelyFeatureFlags,
    'cancellation-discount-modal',
    store.state?.auth?.user?.id.toString()
  );

  isFeatureDiscountsForCancelledSubscriptionsEnabled = isFeatureEnabled(
    optimizelyFeatureFlags,
    'cnv-3263_apply_discounts',
    store.state?.auth?.user?.id.toString()
  );
  // Handles payment method logic and some of the form state
  stripeSubscriptionService: StripeSubscriptionService = new StripeSubscriptionService();
  stripeCheckoutService: StripeCheckoutService = new StripeCheckoutService();

  get activeSubscription(): Subscription {
    return store.state.subscription.activeSubscription;
  }

  get activePlan(): Plan {
    return store.state.subscription.activePlan;
  }

  get expiredSubscription(): boolean {
    if (!this.activeSubscription.ends_at) {
      return false;
    }
    return this.activeSubscription.ends_at.isBefore(dayjs());
  }

  get isDiscountBannerVisible(): boolean {
    return (
      !this.activeSubscription.is_cancelled &&
      // FeatureFlags for showing discount banner
      (this.isFeatureEng2202Enabled || this.isFeatureDiscountsForCancelledSubscriptionsEnabled) &&
      this.isDiscountModalCouponActive
    );
  }

  /**
   * We check here if the current active plan is still upgradeable or not
   * by comparing against other plan levels.
   */
  get upgradeButton(): boolean {
    // !== 0 is there because if a user does not have a plan the ID will still be 0 due to the class setup
    return this.activePlan && this.activePlan.id !== 0;
  }

  get isCustomerB2BAndNeedsVatId(): boolean {
    return store.getters['location/isVatIdNeeded'] && this.team.legal_entity === 'b2b';
  }

  get showLoadingSubscription(): LoadingState {
    return store.state.subscription.showLoading;
  }

  get isDowngrading(): boolean {
    return this.stripeSubscriptionService.isPlanDowngrading(this.activeSubscription.plan_id);
  }

  get invoices(): Invoice[] {
    return store.state.account.invoices || [];
  }

  get hasInvoices(): boolean {
    return this.invoices?.length > 0 || false;
  }

  get newPlanName(): string {
    if (!this.activeSubscription.new_plan_id) return '';

    const newPlan = this.stripeSubscriptionService.findPlan(this.activeSubscription.new_plan_id);
    if (newPlan?.isMonthlyFlexPlan) {
      return this.$t('fields.pricing_table.flex_plan_title_monthly').toString();
    }

    return this.stripeSubscriptionService.findPlan(this.activeSubscription.new_plan_id)?.title || '';
  }

  get user(): User {
    return store.state.auth.user;
  }

  get activePlanTitle(): TranslateResult {
    return this.activePlan.isMonthlyFlexPlan
      ? this.$t('fields.pricing_table.flex_plan_title_monthly')
      : this.$t(`fields.${this.activePlan.translationKey}`);
  }

  openDialog(dialog: string): void {
    if (dialog === 'subscriptionReactivate') {
      OptimizelyService.trackEvent('checkout_renewal_cta_clicked');
    }
    this.isDialogShown[dialog] = true;
  }

  hideDialog(name: string): void {
    this.isDialogShown[name] = false;
  }

  async loadSubscriptionPlan(tryAgain?: boolean): Promise<void> {
    if (tryAgain) {
      this.errorSubscription = false;
      this.tryAgainSubscription = true;
    }

    // Reload Subscription
    if (this.activeSubscription.id == 0 || tryAgain) {
      await this.$store.dispatch('subscription/loadSubscription');
      this.errorSubscription = false;
      this.tryAgainSubscription = false;
    }

    // Validate reloaded subscription
    if (this.activeSubscription.id == 0) {
      this.errorSubscription = true;
      this.tryAgainSubscription = false;
    }

    if (this.invoices) {
      this.errorSubscription = false;
    }
  }

  getBillingInfoTranslationKey(plan: Plan): string {
    if (plan.interval === 'year') {
      return 'fields.account.billing.payment.yearly';
    }
    if (plan.interval === 'month' && plan.interval_count == 3) {
      return 'fields.account.billing.payment.quarter';
    }
    if (plan.interval === 'month' && plan.interval_count == 1) {
      return 'fields.account.billing.payment.month';
    }

    return 'fields.account.billing.payment.yearly';
  }

  onRenewSubscription(plan: Plan): void {
    this.$store.dispatch('subscription/setSubscriptionPlan', plan).then(() => {
      this.$router.push({ name: 'checkout_payment_form', params: { planId: plan.id } });
    });
  }

  async created(): Promise<void> {
    await this.stripeSubscriptionService.initSubscription();
    await this.loadSubscriptionPlan();
    this.stripeCheckoutService.initCustomerData();
  }

  mounted(): void {
    if (this.$route.query?.modal) {
      this.openDialog(this.$route.query?.modal as string);
    }
  }

  updatePaymentAddress(): void {
    this.openDialog('addressEdit');
    this.isPaymentMode = true;
  }

  isUserOnAbtestVariantENG1738(): boolean {
    return OptimizelyService.isAbTestVariantActive('24291960077', '24272590038');
  }
}
</script>
