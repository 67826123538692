<template>
  <div>
    <v-container>
      <router-view />
    </v-container>
  </div>
</template>

<script lang="ts">
// FIXME Inline this component
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Checkout extends Vue {}
</script>

<style lang="scss"></style>
