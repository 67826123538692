import store from '@/store';

function middlewarePipeline(context: any, middleware: any[], index: number) {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    setTimeout(() => {
      store.commit('layout/setRouteLoading', false);
      store.commit('layout/setLayoutInitialized', true);
    });
    return context.next;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);

    nextMiddleware({ ...context, next: nextPipeline });
  };
}

export default middlewarePipeline;
