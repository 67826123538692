class AppConfig {
  getOldAppBaseUrl(): string {
    return AppConfig.cleanUrl(process.env.VUE_APP_OLD_BASE_URL);
  }

  getAuthServiceUrl(): string {
    return AppConfig.cleanUrl(process.env.VUE_APP_AUTH_BASE_URL);
  }

  getAPIBaseUrl(): string {
    return AppConfig.cleanUrl(process.env.VUE_APP_API_BASE_URL);
  }

  getGeoIpServiceBaseUrl(): string {
    return AppConfig.cleanUrl(process.env.VUE_APP_GEOIP_SERVICE_BASE_URL);
  }

  getZendeskSnippetKey(): string {
    return process.env.VUE_APP_ZENDESK_SNIPPET_KEY || '';
  }

  getZendeskSupportFormId(): string {
    return process.env.VUE_APP_ZENDESK_SUPPORT_FORM_ID || '';
  }

  getZendeskSupportFormEgoditorId(): string {
    return process.env.VUE_APP_ZENDESK_SUPPORT_FORM_EGODITOR_ID || '';
  }

  getZendeskSupportFormLanguageId(): string {
    return process.env.VUE_APP_ZENDESK_SUPPORT_FORM_LANGUAGE_ID || '';
  }

  getZendeskSupportFormAbTests(): string {
    return process.env.VUE_APP_ZENDESK_SUPPORT_FORM_AB_TESTS || '';
  }

  getZendeskSupportFormPriceTag(): string {
    return process.env.VUE_APP_ZENDESK_SUPPORT_FORM_PRICE_TAG || '';
  }

  getIntercomAppId(): string {
    return process.env.VUE_APP_INTERCOM_APP_ID || '';
  }

  getIntercomSecret(): string {
    return process.env.VUE_APP_INTERCOM_SECRET_KEY || '';
  }

  getSnowplowCollectorEndpoint(): string {
    return process.env.VUE_APP_SNOWPLOW_ENDPOINT ?? '';
  }

  getAppUrl(): string {
    return AppConfig.cleanUrl(process.env.VUE_APP_URL);
  }

  getOptimizelySdkKey(): string {
    return process.env.VUE_APP_OPTIMIZELY_SDK_KEY ?? '';
  }

  getPromoCodeId(): string {
    return process.env.VUE_APP_PROMO_CODE_ID ?? '';
  }

  getStripeKeyUS(): string {
    return process.env.VUE_APP_STRIPE_KEY_US ?? '';
  }

  getDefaultStripeKey(): string {
    // StripeKeyEU
    return process.env.VUE_APP_STRIPE_KEY_DEFAULT ?? '';
  }

  // Cancelled subscriptions promo code
  getCNV3263PromoCodeId(): string {
    return process.env.VUE_APP_CNV_3263_PROMO_CODE_ID ?? '';
  }

  private static cleanUrl(url: string | undefined): string {
    if (url) {
      return url.replace(/\/$/, '');
    }
    return '';
  }
}

export default new AppConfig();
