/**
 * Custom Application Theme Variant
 */

export default {
  // Neutrals
  neutral: {
    base: '#C0CED5', // $neutral
    lighten4: '#FDFEFF', // $surface
    lighten3: '#F5F8FA', // $neutral_tint90
    lighten2: '#E6EDF0', // $neutral_tint80
    lighten1: '#D3DCE0', // $neutral_tint60
    darken1: '#AFBFC7', // $neutral_shade20
    darken2: '#8C9CA3', // $neutral_shade40
    darken3: '#6F7E85', // $neutral_shade60
    darken4: '#4A5961', // $neutral_shade80
  },

  // Primary Color
  primary: {
    base: '#00BFFF', // $primary
    lighten1: '#80DBFF', // $light_primary
    darken1: '#00A5F1', // $dark_primary
  },

  // Navy Blue
  navy: {
    base: '#1974D2', // $navy_blue
    lighten1: '#1E94F3', // $light_navy_blue
    darken1: '#0D45A1', // $dark_navy_blue
  },

  // Lapis Lazuli
  lapis: {
    base: '#1B294B', // $lapis
    lighten2: '#53678C', // $pale_lapis
    lighten1: '#294270', // $light_lapis
  },

  // Bright Turquoise
  turquoise: {
    base: '#08E8DE', // $turquoise
    lighten1: '#D6FAF9', // $light_turquoise
    darken1: '#00CDBD', // $dark_turquoise
  },

  // Banana Yellow
  banana: {
    base: '#FFE135', // $yellow
    lighten1: '#FBF372', // $light_yellow
    darken1: '#FEC525', // $dark_yellow
  },

  // Cotton Candy
  candy: {
    base: '#FD64A3', // $pink
    lighten2: '#FEE5F1', // $light_cotton_candy
    lighten1: '#FFBCD9', // $cotton_candy
  },

  // Begonia
  begonia: {
    base: '#E81526', // $red
    lighten2: '#FFCDD7', // $light_begonia
    lighten1: '#FA6E79', // $begonia
  },
};
