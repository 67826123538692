import {
  CountriesSpecificUSD,
  Currencies,
  CurrencyCode,
  CurrencySymbolCode,
  CurrencySymbols,
  LocaleCode,
  Locales,
} from '@/Models/Localization';
import { Plan } from '@/classes/stripe';
import store from '@/store';

export default class Currency {
  private readonly currencyToLocale = {
    [Currencies.EUR]: Locales.de_DE,
    [Currencies.USD]: Locales.en_US,
    [Currencies.GBP]: Locales.en_GB,
    [Currencies.BRL]: Locales.pt_BR,
  };

  // countries that use comma as decimal separator in prices
  private readonly alpha2CommaCountries = [
    'DE', // GERMANY
    'ES', // SPAIN
    'FR', // FRANCE
    'IT', // ITALY
    'PT', // PORTUGAL
    'AT', // AUSTRIA
    'BE', // BELGIUM
    'BG', // BULGARIA
    'CY', // CYPRUS
    'CZ', // CZECHIA
    'DK', // DENMARK
    'EE', // ESTONIA
    'FI', // FINLAND
    'GR', // GREECE
    'HR', // CROATIA
    'HU', // HUNGARY
    'LT', // LITHUANIA
    'LU', // LUXEMBOURG
    'LV', // LATVIA
    'NL', // NETHERLANDS
    'PL', // POLAND
    'RO', // ROMANIA
    'SE', // SWEDEN
    'SI', // SLOVENIA
    'SK', // SLOVAKIA
    'BR', // BRAZIL
    'TR', // TURKEY
    'CL', // CHILE
    'RU', // RUSSIAN_FEDERATION
    'MA', // MOROCCO
    'IQ', // IRAQ
    'NO', // NORWAY
    'KZ', // KAZAKHSTAN
    'ID', // INDONESIA
    'AR', // ARGENTINA
    'CO', // COLOMBIA
    'ZA', // SOUTH_AFRICA
    'UA', // UKRAINE
    'VE', // VENEZUELA_BOLIVIAN_REPUBLIC_OF
    'BO', // BOLIVIA
    'VN', // VIETNAM
    'EC', // ECUADOR
    'PE', // PERU
    'DZ', // ALGERIA
    'CR', // COSTA_RICA
    'TN', // TUNISIA
    'AZ', // AZERBAIJAN
    'MN', // MONGOLIA
    'UZ', // UZBEKISTAN
    'RS', // SERBIA
    'UY', // URUGUAY
    'GE', // GEORGIA
    'PY', // PARAGUAY
    'AM', // ARMENIA
    'BY', // BELARUS
    'MD', // REPUBLIC_OF_MOLDOVA
  ];

  constructor(public price: number = 0) {}

  currency: CurrencyCode = Currencies.EUR;
  symbol: CurrencySymbolCode = CurrencySymbols.EUR;
  locale: LocaleCode = Locales.de_DE;
  minimumFractionDigits: number = 2;
  useGrouping: boolean = false;

  priceToInt(price: string | number = this.price): number {
    if (!price) return 0;
    if (typeof price === 'string') {
      return parseFloat(price);
    }
    // Always show two decimals
    return Math.round(price * 100) / 100;
  }

  // Core function to format price with Intl.NumberFormat
  format({
    options = {},
    overrideCurrency = '',
    useGrouping = false,
  }: {
    options?: Record<string, unknown>;
    overrideCurrency?: string;
    useGrouping?: boolean;
  }): Intl.NumberFormat {
    const { currency, country_from_registration } = store.state.auth?.team || {};

    const locale: string = this.alpha2CommaCountries.includes(country_from_registration) ? 'de' : 'en';
    const uppercaseCurrency: string = overrideCurrency !== '' ? overrideCurrency.toUpperCase() : currency.toUpperCase();

    return new Intl.NumberFormat(locale, {
      ...options,
      minimumFractionDigits: this.minimumFractionDigits,
      currency: uppercaseCurrency,
      useGrouping,
    });
  }

  // Display price without the symbol (Example: 460.00)
  priceAsValue({
    price = this.price,
    overrideCurrency = '',
    useGrouping = false,
  }: {
    price: number | string;
    overrideCurrency?: string;
    useGrouping?: boolean;
  }): string {
    return this.format({ overrideCurrency, useGrouping }).format(this.priceToInt(price));
  }

  // Display full price with symbol together (Example: $460.00)
  displayPrice({
    price = this.price,
    overrideCurrency = '',
    useGrouping = false,
  }: {
    price: number | string;
    overrideCurrency?: string;
    useGrouping?: boolean;
  }): string {
    //checking if the user is on Brazilian AB test so we display the right currency in the transaction table on the billing page
    if (store.state.auth.team.ab_tests?.includes('CNV-2733')) {
      overrideCurrency = Currencies.USD;
    }
    let formattedPrice = this.format({ options: { style: 'currency' }, overrideCurrency, useGrouping }).format(
      this.priceToInt(price)
    );

    const { country_from_registration } = store.state.auth?.team || {};

    if (
      formattedPrice.startsWith('$') &&
      (<any>Object).values(CountriesSpecificUSD).includes(country_from_registration)
    ) {
      formattedPrice = formattedPrice.replace('$', CurrencySymbols.SPECIFIC_USD);
    }

    return formattedPrice;
  }

  // Returns only symbol
  displaySymbol({ overrideCurrency = '' }: { overrideCurrency?: string }): string {
    let symbol =
      this.format({ options: { style: 'currency' }, overrideCurrency })
        .formatToParts(this.priceToInt(this.price))
        .find((obj) => obj.type === 'currency')?.value || CurrencySymbols.EUR;

    const { country_from_registration } = store.state.auth?.team || {};

    if (symbol === '$' && (<any>Object).values(CountriesSpecificUSD).includes(country_from_registration)) {
      symbol = CurrencySymbols.SPECIFIC_USD;
    }

    return symbol;
  }

  // Returns formatted monthly price (Example: 38.33)
  displayPriceMonthly({
    plan,
    selectedInterval = '',
    overrideCurrency = '',
  }: {
    plan: Plan;
    selectedInterval: string;
    overrideCurrency?: string;
  }): string {
    if (!plan || !selectedInterval) return '';
    let formattedPrice = this.priceToInt(this.price);

    if (plan.interval === 'month' && plan.interval_count == 3) {
      formattedPrice /= 3;
    }

    // Yearly Plan and selected monthly display
    if (plan.interval === 'year' && selectedInterval === 'month') {
      formattedPrice /= 12;
    }

    // Monthly Plan and selected yearly display
    if (!(plan.interval === 'year') && selectedInterval === 'year') {
      formattedPrice *= 12;
    }

    return this.priceAsValue({ price: formattedPrice, overrideCurrency });
  }

  toJSON(): number {
    return this.price;
  }

  toString(): string {
    return this.displayPrice({ price: this.price });
  }
}
