<template>
  <v-row>
    <v-col xs="12">
      <h1 class="pricing__header text-h1 mt-6">Find a plan that suits your needs</h1>
      <h5 class="pricing__subheader text-h5 mt-6">All plans include</h5>
      <v-row class="mt-3">
        <v-col lg="10" offset-lg="1" class="py-0">
          <v-row align="stretch" justify="center" :style="{ gap: '12px' }">
            <v-col :style="{ maxWidth: '200px' }">
              <v-tooltip bottom max-width="220">
                <template #activator="{ on }">
                  <v-btn :style="{ width: 'auto' }" icon class="no-background-hover" v-on="on">
                    <UnlimitedAccess />
                  </v-btn>
                </template>
                <span>No limit on how many visits your QR Codes can generate.</span>
              </v-tooltip>
            </v-col>
            <v-col :style="{ maxWidth: '200px' }">
              <div>
                <v-tooltip bottom max-width="220">
                  <template #activator="{ on }">
                    <v-btn :style="{ width: 'auto' }" icon class="no-background-hover" v-on="on">
                      <FullCustomization />
                    </v-btn>
                  </template>
                  <span>Make your QR Codes your own with custom logos, colors, and frames.</span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col :style="{ maxWidth: '200px' }">
              <v-tooltip bottom max-width="220">
                <template #activator="{ on }">
                  <v-btn :style="{ width: 'auto' }" icon class="no-background-hover" v-on="on">
                    <ScanStatistics />
                  </v-btn>
                </template>
                <span>Collect and analyze data on where your QR Codes are being scanned.</span>
              </v-tooltip>
            </v-col>
            <v-col :style="{ maxWidth: '200px' }">
              <v-tooltip bottom max-width="220">
                <template #activator="{ on }">
                  <v-btn :style="{ width: 'auto' }" icon class="no-background-hover" v-on="on">
                    <NoAds />
                  </v-btn>
                </template>
                <span>Users who scan your QR Codes won't be shown ads or branding.</span>
              </v-tooltip>
            </v-col>
            <v-col :style="{ maxWidth: '200px' }">
              <v-tooltip bottom max-width="220">
                <template #activator="{ on }">
                  <v-btn :style="{ width: 'auto' }" icon class="no-background-hover" v-on="on">
                    <AllFileFormats />
                  </v-btn>
                </template>
                <span>Download your QR Codes in all print-ready file formats.</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script lang="ts">
import AllFileFormats from '@/components/SVG/AllFileFormats.vue';
import FullCustomization from '@/components/SVG/FullCustomization.vue';
import NoAds from '@/components/SVG/NoAds.vue';
import ScanStatistics from '@/components/SVG/ScanStatistics.vue';
import UnlimitedAccess from '@/components/SVG/UnlimitedScans.vue';
import { Component, Vue } from 'vue-property-decorator';
@Component({
  components: {
    UnlimitedAccess,
    AllFileFormats,
    NoAds,
    FullCustomization,
    ScanStatistics,
  },
})
export default class CommonFeatures extends Vue {}
</script>
<style scoped>
.no-background-hover::before {
  background-color: transparent !important;
}
</style>
