export class Account {
  id: any;
  trial: number;
  trial_date: string;
  type: string;
  payment_open?: any;
  payment_overdue: number;
  status: string;
  payment_creditcard_expired?: any;
  payment_txid?: any;
  trial_left: number;
  qrcodes: QrCodeAmounts;
  industry_id?: number;

  constructor(object: any = {}) {
    this.id = object?.id || 0;
    this.trial = object?.trial || 0;
    this.trial_date = object?.trial_date || '';
    this.type = object?.type || '';
    this.payment_open = object?.payment_open || false;
    this.payment_overdue = object?.payment_overdue || 0;
    this.status = object?.status || '';
    this.payment_creditcard_expired = object?.payment_creditcard_expired || false;
    this.payment_txid = object?.payment_txid || false;
    this.trial_left = object?.trial_left || 14;
    this.qrcodes = object?.qrcodes || {
      current: 0,
      allowed: 0,
      activeDynamicCodes: 0,
      activeDynamicWebsiteCodes: 0,
      activeStaticCodes: 0,
      activeTotalCodes: 0,
    };
    this.industry_id = object.industry_id ?? null;
  }
}

export interface QrCodeAmounts {
  current: number;
  allowed: number;
  activeDynamicCodes: number;
  activeDynamicWebsiteCodes: number;
  activeStaticCodes: number;
  activeTotalCodes: number;
}
