// Simple list of vat id validation regex commands
import KeyValueItem from '@/Models/PrimitiveModels/KeyValueItem';

export const VatRules: Array<KeyValueItem> = [
  {
    key: 'AT',
    value: new RegExp(/U[A-Z\d]{8}/, 'i'),
  },
  {
    key: 'BE',
    value: new RegExp(/(0\d{9}|\d{10})/, 'i'),
  },
  {
    key: 'BG',
    value: new RegExp(/\d{9,10}/, 'i'),
  },
  {
    key: 'CY',
    value: new RegExp(/\d{8}[A-Z]/, 'i'),
  },
  {
    key: 'CZ',
    value: new RegExp(/\d{8,10}/, 'i'),
  },
  {
    key: 'DE',
    value: new RegExp(/\d{9}/, 'i'),
  },
  {
    key: 'DK',
    value: new RegExp(/(\d{2} ?){3}\d{2}/, 'i'),
  },
  {
    key: 'EE',
    value: new RegExp(/\d{9}/, 'i'),
  },
  {
    key: 'EL',
    value: new RegExp(/\d{9}/, 'i'),
  },
  {
    key: 'ES',
    value: new RegExp(/[A-Z]\d{7}[A-Z]|\d{8}[A-Z]|[A-Z]\d{8}/, 'i'),
  },
  {
    key: 'FI',
    value: new RegExp(/\d{8}/, 'i'),
  },
  {
    key: 'FR',
    value: new RegExp(/([A-Z]{2}|\d{2})\d{9}/, 'i'),
  },
  {
    key: 'GB',
    value: new RegExp(/\d{12}|\d{9}|(GD|HA)\d{3}/, 'i'),
  },
  {
    key: 'HR',
    value: new RegExp(/\d{11}/, 'i'),
  },
  {
    key: 'HU',
    value: new RegExp(/\d{8}/, 'i'),
  },
  {
    key: 'IE',
    value: new RegExp(/[A-Z\d]{8,9}/, 'i'),
  },
  {
    key: 'IT',
    value: new RegExp(/\d{11}/, 'i'),
  },
  {
    key: 'LT',
    value: new RegExp(/(\d{12}|\d{9})/, 'i'),
  },
  {
    key: 'LU',
    value: new RegExp(/\d{8}/, 'i'),
  },
  {
    key: 'LV',
    value: new RegExp(/\d{11}/, 'i'),
  },
  {
    key: 'MT',
    value: new RegExp(/\d{8}/, 'i'),
  },
  {
    key: 'NL',
    value: new RegExp(/\d{9}B\d{2}/, 'i'),
  },
  {
    key: 'PL',
    value: new RegExp(/\d{10}/, 'i'),
  },
  {
    key: 'PT',
    value: new RegExp(/\d{9}/, 'i'),
  },
  {
    key: 'RO',
    value: new RegExp(/\d{2,10}/, 'i'),
  },
  {
    key: 'SE',
    value: new RegExp(/\d{12}/, 'i'),
  },
  {
    key: 'SI',
    value: new RegExp(/\d{8}/, 'i'),
  },
  {
    key: 'SK',
    value: new RegExp(/\d{10}/, 'i'),
  },
];
