<template>
  <ValidationProvider
    ref="checkout.customer.address.state"
    v-slot="{ errors }"
    name="user.state"
    rules="required|max:191"
  >
    <v-autocomplete
      class="text-field state-autocomplete"
      :value="userState"
      :items="stateList"
      outlined
      hide-details
      hide-no-data
      dense
      item-text="name"
      item-value="abbreviation"
      :label="$t('fields.user.state')"
      :error-messages="errors[0]"
      :disabled="isPreselected"
      attach=".state-autocomplete"
      :menu-props="{
        contentClass: 'menu--select',
        bottom: true,
        offsetY: true,
        nudgeBottom: 0,
      }"
      @change="selectState($event)"
    />
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class StateDropdown extends Vue {
  @Prop()
  stateList?: Array<any>;

  @Prop()
  userState?: string;

  @Prop()
  isPreselected?: boolean;

  // Emits event with selected state as value
  selectState(state: string): void {
    this.$emit('stateSelected', state);
  }
}
</script>

<style lang="scss" scoped>
.state-autocomplete {
  .v-list-item__mask {
    font-weight: 700;
    color: inherit;
    background: inherit;
  }
}
</style>
