// SYSTEM
// region Fill@16px
import AccountSystemSmallFill from '@/components/Icons/System/16/Fill/Account.vue';
import ActiveSystemSmallFill from '@/components/Icons/System/16/Fill/Active.vue';
import BulkCreateSystemSmallFill from '@/components/Icons/System/16/Fill/BulkCreate.vue';
import BulkUpdateSystemSmallFill from '@/components/Icons/System/16/Fill/BulkUpdate.vue';
import CaretWideSystemSmallFill from '@/components/Icons/System/16/Fill/CaretWide.vue';
import CheckCheckedSystemSmallFill from '@/components/Icons/System/16/Fill/CheckChecked.vue';
import CheckIndeterminateSystemSmallFill from '@/components/Icons/System/16/Fill/CheckIndeterminate.vue';
import CheckRoundSystemSmallFill from '@/components/Icons/System/16/Fill/CheckRound.vue';
import ClockSystemSmallFill from '@/components/Icons/System/16/Fill/Clock.vue';
import CopySystemSmallFill from '@/components/Icons/System/16/Fill/Copy.vue';
import DeleteSystemSmallFill from '@/components/Icons/System/16/Fill/Delete.vue';
import EditSystemSmallFill from '@/components/Icons/System/16/Fill/Edit.vue';
import EnvelopeSystemSmallFill from '@/components/Icons/System/16/Fill/Envelope.vue';
import FilterSystemSmallFill from '@/components/Icons/System/16/Fill/Filter.vue';
import FolderSystemSmallFill from '@/components/Icons/System/16/Fill/Folder.vue';
import GearSystemSmallFill from '@/components/Icons/System/16/Fill/Gear.vue';
import HeartSystemSmallFill from '@/components/Icons/System/16/Fill/Heart.vue';
import HelpRoundSystemSmallFill from '@/components/Icons/System/16/Fill/HelpRound.vue';
import HomeSystemSmallFill from '@/components/Icons/System/16/Fill/Home.vue';
import InfoSystemSmallFill from '@/components/Icons/System/16/Fill/Info.vue';
import LockSystemSmallFill from '@/components/Icons/System/16/Fill/Lock.vue';
import LogoutSystemSmallFill from '@/components/Icons/System/16/Fill/Logout.vue';
import MultipleFolderSystemSmallFill from '@/components/Icons/System/16/Fill/MultipleFolder.vue';
import NotificationSystemSmallFill from '@/components/Icons/System/16/Fill/Notification.vue';
import PaletteSystemSmallFill from '@/components/Icons/System/16/Fill/Palette.vue';
import PauseSystemSmallFill from '@/components/Icons/System/16/Fill/Pause.vue';
import PayAmexSystemSmallFill from '@/components/Icons/System/16/Fill/PayAmex.vue';
import PayDirectDebitSystemSmallFill from '@/components/Icons/System/16/Fill/PayDirectDebit.vue';
import PayMastercardSystemSmallFill from '@/components/Icons/System/16/Fill/PayMastercard.vue';
import PaySepaSystemSmallFill from '@/components/Icons/System/16/Fill/PaySepa.vue';
import PayVisaSystemSmallFill from '@/components/Icons/System/16/Fill/PayVisa.vue';
import PayPaypalSystemSmallFill from '@/components/Icons/System/16/Fill/PayPaypal.vue';
import PriceRoundSystemSmallFill from '@/components/Icons/System/16/Fill/PriceRound.vue';
import PrintSystemSmallFill from '@/components/Icons/System/16/Fill/Print.vue';
import SaveDesignSystemSmallFill from '@/components/Icons/System/16/Fill/SaveDesign.vue';
import ShareSystemSmallFill from '@/components/Icons/System/16/Fill/Share.vue';
import TrackSystemSmallFill from '@/components/Icons/System/16/Fill/Track.vue';
import UploadSystemSmallFill from '@/components/Icons/System/16/Fill/Upload.vue';
import UsersSystemSmallFill from '@/components/Icons/System/16/Fill/Users.vue';
import WarningSystemSmallFill from '@/components/Icons/System/16/Fill/Warning.vue';
import WebSystemSmallFill from '@/components/Icons/System/16/Fill/Web.vue';
// endregion

// region Fill@24px
import AccountSystemLargeFill from '@/components/Icons/System/24/Fill/Account.vue';
import ActiveSystemLargeFill from '@/components/Icons/System/24/Fill/Active.vue';
import BulkCreateSystemLargeFill from '@/components/Icons/System/24/Fill/BulkCreate.vue';
import BulkUpdateSystemLargeFill from '@/components/Icons/System/24/Fill/BulkUpdate.vue';
import CaretWideSystemLargeFill from '@/components/Icons/System/24/Fill/CaretWide.vue';
import CheckCheckedSystemLargeFill from '@/components/Icons/System/24/Fill/CheckChecked.vue';
import CheckIndeterminateSystemLargeFill from '@/components/Icons/System/24/Fill/CheckIndeterminate.vue';
import CheckRoundSystemLargeFill from '@/components/Icons/System/24/Fill/CheckRound.vue';
import ClockSystemLargeFill from '@/components/Icons/System/24/Fill/Clock.vue';
import CopySystemLargeFill from '@/components/Icons/System/24/Fill/Copy.vue';
import DeleteSystemLargeFill from '@/components/Icons/System/24/Fill/Delete.vue';
import EditSystemLargeFill from '@/components/Icons/System/24/Fill/Edit.vue';
import EnvelopeSystemLargeFill from '@/components/Icons/System/24/Fill/Envelope.vue';
import FilterSystemLargeFill from '@/components/Icons/System/24/Fill/Filter.vue';
import FolderSystemLargeFill from '@/components/Icons/System/24/Fill/Folder.vue';
import GearSystemLargeFill from '@/components/Icons/System/24/Fill/Gear.vue';
import HelpRoundSystemLargeFill from '@/components/Icons/System/24/Fill/HelpRound.vue';
import HomeSystemLargeFill from '@/components/Icons/System/24/Fill/Home.vue';
import InfoSystemLargeFill from '@/components/Icons/System/24/Fill/Info.vue';
import LockSystemLargeFill from '@/components/Icons/System/24/Fill/Lock.vue';
import LogoutSystemLargeFill from '@/components/Icons/System/24/Fill/Logout.vue';
import MultipleFolderSystemLargeFill from '@/components/Icons/System/24/Fill/MultipleFolder.vue';
import NotificationSystemLargeFill from '@/components/Icons/System/24/Fill/Notification.vue';
import PaletteSystemLargeFill from '@/components/Icons/System/24/Fill/Palette.vue';
import PauseSystemLargeFill from '@/components/Icons/System/24/Fill/Pause.vue';
import PayAmexSystemLargeFill from '@/components/Icons/System/24/Fill/PayAmex.vue';
import PayDirectDebitSystemLargeFill from '@/components/Icons/System/24/Fill/PayDirectDebit.vue';
import PayMastercardSystemLargeFill from '@/components/Icons/System/24/Fill/PayMastercard.vue';
import PaySepaSystemLargeFill from '@/components/Icons/System/24/Fill/PaySepa.vue';
import PayVisaSystemLargeFill from '@/components/Icons/System/24/Fill/PayVisa.vue';
import PayPaypalSystemLargeFill from '@/components/Icons/System/24/Fill/PayPaypal.vue';
import PriceRoundSystemLargeFill from '@/components/Icons/System/24/Fill/PriceRound.vue';
import PrintSystemLargeFill from '@/components/Icons/System/24/Fill/Print.vue';
import SaveDesignSystemLargeFill from '@/components/Icons/System/24/Fill/SaveDesign.vue';
import ShareSystemLargeFill from '@/components/Icons/System/24/Fill/Share.vue';
import TrackSystemLargeFill from '@/components/Icons/System/24/Fill/Track.vue';
import UploadSystemLargeFill from '@/components/Icons/System/24/Fill/Upload.vue';
import UsersSystemLargeFill from '@/components/Icons/System/24/Fill/Users.vue';
import WarningSystemLargeFill from '@/components/Icons/System/24/Fill/Warning.vue';
import WebSystemLargeFill from '@/components/Icons/System/24/Fill/Web.vue';
// endregion

// region Outline@16px
import AccountSystemSmallOutline from '@/components/Icons/System/16/Outline/Account.vue';
import ActiveSystemSmallOutline from '@/components/Icons/System/16/Outline/Active.vue';
import AddSystemSmallOutline from '@/components/Icons/System/16/Outline/Add.vue';
import AlignCenterSystemSmallOutline from '@/components/Icons/System/16/Outline/AlignCenter.vue';
import AlignLeftSystemSmallOutline from '@/components/Icons/System/16/Outline/AlignLeft.vue';
import AlignRightSystemSmallOutline from '@/components/Icons/System/16/Outline/AlignRight.vue';
import AllCodesSystemSmallOutline from '@/components/Icons/System/16/Outline/AllCodes.vue';
import ArrowSystemSmallOutline from '@/components/Icons/System/16/Outline/Arrow.vue';
import BulkCreateSystemSmallOutline from '@/components/Icons/System/16/Outline/BulkCreate.vue';
import BulkUpdateSystemSmallOutline from '@/components/Icons/System/16/Outline/BulkUpdate.vue';
import CaretWideSystemSmallOutline from '@/components/Icons/System/16/Outline/CaretWide.vue';
import CheckCheckedSystemSmallOutline from '@/components/Icons/System/16/Outline/CheckChecked.vue';
import CheckIndeterminateSystemSmallOutline from '@/components/Icons/System/16/Outline/CheckIndeterminate.vue';
import CheckmarkSystemSmallOutline from '@/components/Icons/System/16/Outline/Checkmark.vue';
import CheckRoundSystemSmallOutline from '@/components/Icons/System/16/Outline/CheckRound.vue';
import CheckUncheckedSystemSmallOutline from '@/components/Icons/System/16/Outline/CheckUnchecked.vue';
import ChevronSystemSmallOutline from '@/components/Icons/System/16/Outline/Chevron.vue';
import ClockSystemSmallOutline from '@/components/Icons/System/16/Outline/Clock.vue';
import CopySystemSmallOutline from '@/components/Icons/System/16/Outline/Copy.vue';
import DeleteSystemSmallOutline from '@/components/Icons/System/16/Outline/Delete.vue';
import DoubleArrowsSystemSmallOutline from '@/components/Icons/System/16/Outline/DoubleArrows.vue';
import DownloadSystemSmallOutline from '@/components/Icons/System/16/Outline/Download.vue';
import EditSystemSmallOutline from '@/components/Icons/System/16/Outline/Edit.vue';
import EnvelopeSystemSmallOutline from '@/components/Icons/System/16/Outline/Envelope.vue';
import ExitSystemSmallOutline from '@/components/Icons/System/16/Outline/Exit.vue';
import FilterSystemSmallOutline from '@/components/Icons/System/16/Outline/Filter.vue';
import FolderSystemSmallOutline from '@/components/Icons/System/16/Outline/Folder.vue';
import ForbiddenSystemSmallOutline from '@/components/Icons/System/16/Outline/Forbidden.vue';
import GearSystemSmallOutline from '@/components/Icons/System/16/Outline/Gear.vue';
import HelpRoundSystemSmallOutline from '@/components/Icons/System/16/Outline/HelpRound.vue';
import HistorySystemSmallOutline from '@/components/Icons/System/16/Outline/History.vue';
import HomeSystemSmallOutline from '@/components/Icons/System/16/Outline/Home.vue';
import InfoSystemSmallOutline from '@/components/Icons/System/16/Outline/Info.vue';
import LockSystemSmallOutline from '@/components/Icons/System/16/Outline/Lock.vue';
import LinkExternalSystemSmallOutline from '@/components/Icons/System/16/Outline/LinkExternal.vue';
import LinkHorizontalSystemSmallOutline from '@/components/Icons/System/16/Outline/LinkHorizontal.vue';
import LinkTiltSystemSmallOutline from '@/components/Icons/System/16/Outline/LinkTilt.vue';
import LogoutSystemSmallOutline from '@/components/Icons/System/16/Outline/Logout.vue';
import MapPinSystemSmallOutline from '@/components/Icons/System/16/Outline/MapPin.vue';
import MenuSystemSmallOutline from '@/components/Icons/System/16/Outline/Menu.vue';
import MenuHideSystemSmallOutline from '@/components/Icons/System/16/Outline/MenuHide.vue';
import MenuShowSystemSmallOutline from '@/components/Icons/System/16/Outline/MenuShow.vue';
import MoreOptionsSystemSmallOutline from '@/components/Icons/System/16/Outline/MoreOptions.vue';
import MultipleFolderSystemSmallOutline from '@/components/Icons/System/16/Outline/MultipleFolder.vue';
import NotificationSystemSmallOutline from '@/components/Icons/System/16/Outline/Notification.vue';
import PaletteSystemSmallOutline from '@/components/Icons/System/16/Outline/Palette.vue';
import PauseSystemSmallOutline from '@/components/Icons/System/16/Outline/Pause.vue';
import PriceRoundSystemSmallOutline from '@/components/Icons/System/16/Outline/PriceRound.vue';
import PrintSystemSmallOutline from '@/components/Icons/System/16/Outline/Print.vue';
import RadioSelectedSystemSmallOutline from '@/components/Icons/System/16/Outline/RadioSelected.vue';
import RadioUnselectedSystemSmallOutline from '@/components/Icons/System/16/Outline/RadioUnselected.vue';
import ReloadSystemSmallOutline from '@/components/Icons/System/16/Outline/Reload.vue';
import SaveDesignSystemSmallOutline from '@/components/Icons/System/16/Outline/SaveDesign.vue';
import SearchSystemSmallOutline from '@/components/Icons/System/16/Outline/Search.vue';
import ShareSystemSmallOutline from '@/components/Icons/System/16/Outline/Share.vue';
import TrackSystemSmallOutline from '@/components/Icons/System/16/Outline/Track.vue';
import TypeSystemSmallOutline from '@/components/Icons/System/16/Outline/Type.vue';
import UploadSystemSmallOutline from '@/components/Icons/System/16/Outline/Upload.vue';
import UsersSystemSmallOutline from '@/components/Icons/System/16/Outline/Users.vue';
import WarningSystemSmallOutline from '@/components/Icons/System/16/Outline/Warning.vue';
import WebSystemSmallOutline from '@/components/Icons/System/16/Outline/Web.vue';
// endregion

// region Outline@24px
import AccountSystemLargeOutline from '@/components/Icons/System/24/Outline/Account.vue';
import ActiveSystemLargeOutline from '@/components/Icons/System/24/Outline/Active.vue';
import AddSystemLargeOutline from '@/components/Icons/System/24/Outline/Add.vue';
import AlignCenterSystemLargeOutline from '@/components/Icons/System/24/Outline/AlignCenter.vue';
import AlignLeftSystemLargeOutline from '@/components/Icons/System/24/Outline/AlignLeft.vue';
import AlignRightSystemLargeOutline from '@/components/Icons/System/24/Outline/AlignRight.vue';
import AllCodesSystemLargeOutline from '@/components/Icons/System/24/Outline/AllCodes.vue';
import ArrowSystemLargeOutline from '@/components/Icons/System/24/Outline/Arrow.vue';
import BulkCreateSystemLargeOutline from '@/components/Icons/System/24/Outline/BulkCreate.vue';
import BulkUpdateSystemLargeOutline from '@/components/Icons/System/24/Outline/BulkUpdate.vue';
import CaretWideSystemLargeOutline from '@/components/Icons/System/24/Outline/CaretWide.vue';
import CheckCheckedSystemLargeOutline from '@/components/Icons/System/24/Outline/CheckChecked.vue';
import CheckIndeterminateSystemLargeOutline from '@/components/Icons/System/24/Outline/CheckIndeterminate.vue';
import CheckmarkSystemLargeOutline from '@/components/Icons/System/24/Outline/Checkmark.vue';
import CheckRoundSystemLargeOutline from '@/components/Icons/System/24/Outline/CheckRound.vue';
import CheckUncheckedSystemLargeOutline from '@/components/Icons/System/24/Outline/CheckUnchecked.vue';
import ChevronSystemLargeOutline from '@/components/Icons/System/24/Outline/Chevron.vue';
import ClockSystemLargeOutline from '@/components/Icons/System/24/Outline/Clock.vue';
import CopySystemLargeOutline from '@/components/Icons/System/24/Outline/Copy.vue';
import DeleteSystemLargeOutline from '@/components/Icons/System/24/Outline/Delete.vue';
import DoubleArrowsSystemLargeOutline from '@/components/Icons/System/24/Outline/DoubleArrows.vue';
import DownloadSystemLargeOutline from '@/components/Icons/System/24/Outline/Download.vue';
import EditSystemLargeOutline from '@/components/Icons/System/24/Outline/Edit.vue';
import EnvelopeSystemLargeOutline from '@/components/Icons/System/24/Outline/Envelope.vue';
import ExitSystemLargeOutline from '@/components/Icons/System/24/Outline/Exit.vue';
import FilterSystemLargeOutline from '@/components/Icons/System/24/Outline/Filter.vue';
import FolderSystemLargeOutline from '@/components/Icons/System/24/Outline/Folder.vue';
import ForbiddenSystemLargeOutline from '@/components/Icons/System/24/Outline/Forbidden.vue';
import GearSystemLargeOutline from '@/components/Icons/System/24/Outline/Gear.vue';
import HelpRoundSystemLargeOutline from '@/components/Icons/System/24/Outline/HelpRound.vue';
import HistorySystemLargeOutline from '@/components/Icons/System/24/Outline/History.vue';
import HomeSystemLargeOutline from '@/components/Icons/System/24/Outline/Home.vue';
import InfoSystemLargeOutline from '@/components/Icons/System/24/Outline/Info.vue';
import LockSystemLargeOutline from '@/components/Icons/System/24/Outline/Lock.vue';
import LinkExternalSystemLargeOutline from '@/components/Icons/System/24/Outline/LinkExternal.vue';
import LinkHorizontalSystemLargeOutline from '@/components/Icons/System/24/Outline/LinkHorizontal.vue';
import LinkTiltSystemLargeOutline from '@/components/Icons/System/24/Outline/LinkTilt.vue';
import LogoutSystemLargeOutline from '@/components/Icons/System/24/Outline/Logout.vue';
import MenuSystemLargeOutline from '@/components/Icons/System/24/Outline/Menu.vue';
import MenuHideSystemLargeOutline from '@/components/Icons/System/24/Outline/MenuHide.vue';
import MenuShowSystemLargeOutline from '@/components/Icons/System/24/Outline/MenuShow.vue';
import MoreOptionsSystemLargeOutline from '@/components/Icons/System/24/Outline/MoreOptions.vue';
import MultipleFolderSystemLargeOutline from '@/components/Icons/System/24/Outline/MultipleFolder.vue';
import NotificationSystemLargeOutline from '@/components/Icons/System/24/Outline/Notification.vue';
import PaletteSystemLargeOutline from '@/components/Icons/System/24/Outline/Palette.vue';
import PauseSystemLargeOutline from '@/components/Icons/System/24/Outline/Pause.vue';
import PriceRoundSystemLargeOutline from '@/components/Icons/System/24/Outline/PriceRound.vue';
import PrintSystemLargeOutline from '@/components/Icons/System/24/Outline/Print.vue';
import RadioSelectedSystemLargeOutline from '@/components/Icons/System/24/Outline/RadioSelected.vue';
import RadioUnselectedSystemLargeOutline from '@/components/Icons/System/24/Outline/RadioUnselected.vue';
import ReloadSystemLargeOutline from '@/components/Icons/System/24/Outline/Reload.vue';
import SaveDesignSystemLargeOutline from '@/components/Icons/System/24/Outline/SaveDesign.vue';
import SearchSystemLargeOutline from '@/components/Icons/System/24/Outline/Search.vue';
import ShareSystemLargeOutline from '@/components/Icons/System/24/Outline/Share.vue';
import TrackSystemLargeOutline from '@/components/Icons/System/24/Outline/Track.vue';
import TypeSystemLargeOutline from '@/components/Icons/System/24/Outline/Type.vue';
import UploadSystemLargeOutline from '@/components/Icons/System/24/Outline/Upload.vue';
import UsersSystemLargeOutline from '@/components/Icons/System/24/Outline/Users.vue';
import WarningSystemLargeOutline from '@/components/Icons/System/24/Outline/Warning.vue';
import WebSystemLargeOutline from '@/components/Icons/System/24/Outline/Web.vue';
// endregion
// SYSTEM END

// SOCIAL
// region Fill@16px
import FacebookSocialSmallFill from '@/components/Icons/Social/16/Fill/Facebook.vue';
import GoogleSocialSmallFill from '@/components/Icons/Social/16/Fill/Google.vue';
import InstagramSocialSmallFill from '@/components/Icons/Social/16/Fill/Instagram.vue';
import LinkedinSocialSmallFill from '@/components/Icons/Social/16/Fill/Linkedin.vue';
import TwitterSocialSmallFill from '@/components/Icons/Social/16/Fill/Twitter.vue';
import XingSocialSmallFill from '@/components/Icons/Social/16/Fill/Xing.vue';
import YoutubeSocialSmallFill from '@/components/Icons/Social/16/Fill/Youtube.vue';
// endregion

// region Fill@24px
import FacebookSocialLargeFill from '@/components/Icons/Social/24/Fill/Facebook.vue';
import GoogleSocialLargeFill from '@/components/Icons/Social/24/Fill/Google.vue';
import InstagramSocialLargeFill from '@/components/Icons/Social/24/Fill/Instagram.vue';
import LinkedinSocialLargeFill from '@/components/Icons/Social/24/Fill/Linkedin.vue';
import TwitterSocialLargeFill from '@/components/Icons/Social/24/Fill/Twitter.vue';
import XingSocialLargeFill from '@/components/Icons/Social/24/Fill/Xing.vue';
import YoutubeSocialLargeFill from '@/components/Icons/Social/24/Fill/Youtube.vue';
// endregion

// region Outline@16px
import FacebookSocialSmallOutline from '@/components/Icons/Social/16/Outline/Facebook.vue';
import GoogleSocialSmallOutline from '@/components/Icons/Social/16/Outline/Google.vue';
import InstagramSocialSmallOutline from '@/components/Icons/Social/16/Outline/Instagram.vue';
import LinkedinSocialSmallOutline from '@/components/Icons/Social/16/Outline/Linkedin.vue';
import TwitterSocialSmallOutline from '@/components/Icons/Social/16/Outline/Twitter.vue';
import XingSocialSmallOutline from '@/components/Icons/Social/16/Outline/Xing.vue';
import YoutubeSocialSmallOutline from '@/components/Icons/Social/16/Outline/Youtube.vue';
// endregion

// region Outline@24px
import FacebookSocialLargeOutline from '@/components/Icons/Social/24/Outline/Facebook.vue';
import GoogleSocialLargeOutline from '@/components/Icons/Social/24/Outline/Google.vue';
import InstagramSocialLargeOutline from '@/components/Icons/Social/24/Outline/Instagram.vue';
import LinkedinSocialLargeOutline from '@/components/Icons/Social/24/Outline/Linkedin.vue';
import TwitterSocialLargeOutline from '@/components/Icons/Social/24/Outline/Twitter.vue';
import XingSocialLargeOutline from '@/components/Icons/Social/24/Outline/Xing.vue';
import YoutubeSocialLargeOutline from '@/components/Icons/Social/24/Outline/Youtube.vue';
// endregion
// SOCIAL END

// TYPE
// region Fill@16px
import AppTypeSmallFill from '@/components/Icons/Type/16/Fill/App.vue';
import BitcoinTypeSmallFill from '@/components/Icons/Type/16/Fill/Bitcoin.vue';
import BusinessTypeSmallFill from '@/components/Icons/Type/16/Fill/Business.vue';
import CouponTypeSmallFill from '@/components/Icons/Type/16/Fill/Coupon.vue';
import EmailTypeSmallFill from '@/components/Icons/Type/16/Fill/Email.vue';
import EpcTypeSmallFill from '@/components/Icons/Type/16/Fill/Epc.vue';
import EventTypeSmallFill from '@/components/Icons/Type/16/Fill/Event.vue';
import FacebookLikeTypeSmallFill from '@/components/Icons/Type/16/Fill/FacebookLike.vue';
import FacebookMessengerTypeSmallFill from '@/components/Icons/Type/16/Fill/FacebookMessenger.vue';
import FeedbackTypeSmallFill from '@/components/Icons/Type/16/Fill/Feedback.vue';
import FormTypeSmallFill from '@/components/Icons/Type/16/Fill/Form.vue';
import ImgGalleryTypeSmallFill from '@/components/Icons/Type/16/Fill/ImgGallery.vue';
import Mp3TypeSmallFill from '@/components/Icons/Type/16/Fill/Mp3.vue';
import PdfTypeSmallFill from '@/components/Icons/Type/16/Fill/Pdf.vue';
import RatingTypeSmallFill from '@/components/Icons/Type/16/Fill/Rating.vue';
import SmsTypeSmallFill from '@/components/Icons/Type/16/Fill/Sms.vue';
import SocialMediaTypeSmallFill from '@/components/Icons/Type/16/Fill/SocialMedia.vue';
import TextTypeSmallFill from '@/components/Icons/Type/16/Fill/Text.vue';
import TwitterTypeSmallFill from '@/components/Icons/Type/16/Fill/Twitter.vue';
import UrlDynamicTypeSmallFill from '@/components/Icons/Type/16/Fill/UrlDynamic.vue';
import UrlStaticTypeSmallFill from '@/components/Icons/Type/16/Fill/UrlStatic.vue';
import VcardTypeSmallFill from '@/components/Icons/Type/16/Fill/Vcard.vue';
import VcardPlusTypeSmallFill from '@/components/Icons/Type/16/Fill/VcardPlus.vue';
import VideoTypeSmallFill from '@/components/Icons/Type/16/Fill/Video.vue';
import WifiTypeSmallFill from '@/components/Icons/Type/16/Fill/Wifi.vue';
// endregion

// region Fill@24px
import AppTypeLargeFill from '@/components/Icons/Type/24/Fill/App.vue';
import BitcoinTypeLargeFill from '@/components/Icons/Type/24/Fill/Bitcoin.vue';
import BusinessTypeLargeFill from '@/components/Icons/Type/24/Fill/Business.vue';
import CouponTypeLargeFill from '@/components/Icons/Type/24/Fill/Coupon.vue';
import EmailTypeLargeFill from '@/components/Icons/Type/24/Fill/Email.vue';
import EpcTypeLargeFill from '@/components/Icons/Type/24/Fill/Epc.vue';
import EventTypeLargeFill from '@/components/Icons/Type/24/Fill/Event.vue';
import FacebookLikeTypeLargeFill from '@/components/Icons/Type/24/Fill/FacebookLike.vue';
import FacebookMessengerTypeLargeFill from '@/components/Icons/Type/24/Fill/FacebookMessenger.vue';
import FeedbackTypeLargeFill from '@/components/Icons/Type/24/Fill/Feedback.vue';
import FormTypeLargeFill from '@/components/Icons/Type/24/Fill/Form.vue';
import ImgGalleryTypeLargeFill from '@/components/Icons/Type/24/Fill/ImgGallery.vue';
import Mp3TypeLargeFill from '@/components/Icons/Type/24/Fill/Mp3.vue';
import PdfTypeLargeFill from '@/components/Icons/Type/24/Fill/Pdf.vue';
import RatingTypeLargeFill from '@/components/Icons/Type/24/Fill/Rating.vue';
import SmsTypeLargeFill from '@/components/Icons/Type/24/Fill/Sms.vue';
import SocialMediaTypeLargeFill from '@/components/Icons/Type/24/Fill/SocialMedia.vue';
import TextTypeLargeFill from '@/components/Icons/Type/24/Fill/Text.vue';
import TwitterTypeLargeFill from '@/components/Icons/Type/24/Fill/Twitter.vue';
import UrlDynamicTypeLargeFill from '@/components/Icons/Type/24/Fill/UrlDynamic.vue';
import UrlStaticTypeLargeFill from '@/components/Icons/Type/24/Fill/UrlStatic.vue';
import VcardTypeLargeFill from '@/components/Icons/Type/24/Fill/Vcard.vue';
import VcardPlusTypeLargeFill from '@/components/Icons/Type/24/Fill/VcardPlus.vue';
import VideoTypeLargeFill from '@/components/Icons/Type/24/Fill/Video.vue';
import WifiTypeLargeFill from '@/components/Icons/Type/24/Fill/Wifi.vue';
// endregion

// region Outline@16px
import AppTypeSmallOutline from '@/components/Icons/Type/16/Outline/App.vue';
import BitcoinTypeSmallOutline from '@/components/Icons/Type/16/Outline/Bitcoin.vue';
import BusinessTypeSmallOutline from '@/components/Icons/Type/16/Outline/Business.vue';
import CouponTypeSmallOutline from '@/components/Icons/Type/16/Outline/Coupon.vue';
import EmailTypeSmallOutline from '@/components/Icons/Type/16/Outline/Email.vue';
import EpcTypeSmallOutline from '@/components/Icons/Type/16/Outline/Epc.vue';
import EventTypeSmallOutline from '@/components/Icons/Type/16/Outline/Event.vue';
import FacebookLikeTypeSmallOutline from '@/components/Icons/Type/16/Outline/FacebookLike.vue';
import FacebookMessengerTypeSmallOutline from '@/components/Icons/Type/16/Outline/FacebookMessenger.vue';
import FeedbackTypeSmallOutline from '@/components/Icons/Type/16/Outline/Feedback.vue';
import FormTypeSmallOutline from '@/components/Icons/Type/16/Outline/Form.vue';
import ImgGalleryTypeSmallOutline from '@/components/Icons/Type/16/Outline/ImgGallery.vue';
import Mp3TypeSmallOutline from '@/components/Icons/Type/16/Outline/Mp3.vue';
import PdfTypeSmallOutline from '@/components/Icons/Type/16/Outline/Pdf.vue';
import RatingTypeSmallOutline from '@/components/Icons/Type/16/Outline/Rating.vue';
import SmsTypeSmallOutline from '@/components/Icons/Type/16/Outline/Sms.vue';
import SocialMediaTypeSmallOutline from '@/components/Icons/Type/16/Outline/SocialMedia.vue';
import TextTypeSmallOutline from '@/components/Icons/Type/16/Outline/Text.vue';
import TwitterTypeSmallOutline from '@/components/Icons/Type/16/Outline/Twitter.vue';
import UrlDynamicTypeSmallOutline from '@/components/Icons/Type/16/Outline/UrlDynamic.vue';
import UrlStaticTypeSmallOutline from '@/components/Icons/Type/16/Outline/UrlStatic.vue';
import VcardTypeSmallOutline from '@/components/Icons/Type/16/Outline/Vcard.vue';
import VcardPlusTypeSmallOutline from '@/components/Icons/Type/16/Outline/VcardPlus.vue';
import VideoTypeSmallOutline from '@/components/Icons/Type/16/Outline/Video.vue';
import WifiTypeSmallOutline from '@/components/Icons/Type/16/Outline/Wifi.vue';
// endregion

// region Outline@24px
import AppTypeLargeOutline from '@/components/Icons/Type/24/Outline/App.vue';
import BitcoinTypeLargeOutline from '@/components/Icons/Type/24/Outline/Bitcoin.vue';
import BusinessTypeLargeOutline from '@/components/Icons/Type/24/Outline/Business.vue';
import CouponTypeLargeOutline from '@/components/Icons/Type/24/Outline/Coupon.vue';
import EmailTypeLargeOutline from '@/components/Icons/Type/24/Outline/Email.vue';
import EpcTypeLargeOutline from '@/components/Icons/Type/24/Outline/Epc.vue';
import EventTypeLargeOutline from '@/components/Icons/Type/24/Outline/Event.vue';
import FacebookLikeTypeLargeOutline from '@/components/Icons/Type/24/Outline/FacebookLike.vue';
import FacebookMessengerTypeLargeOutline from '@/components/Icons/Type/24/Outline/FacebookMessenger.vue';
import FeedbackTypeLargeOutline from '@/components/Icons/Type/24/Outline/Feedback.vue';
import FormTypeLargeOutline from '@/components/Icons/Type/24/Outline/Form.vue';
import ImgGalleryTypeLargeOutline from '@/components/Icons/Type/24/Outline/ImgGallery.vue';
import Mp3TypeLargeOutline from '@/components/Icons/Type/24/Outline/Mp3.vue';
import PdfTypeLargeOutline from '@/components/Icons/Type/24/Outline/Pdf.vue';
import RatingTypeLargeOutline from '@/components/Icons/Type/24/Outline/Rating.vue';
import SmsTypeLargeOutline from '@/components/Icons/Type/24/Outline/Sms.vue';
import SocialMediaTypeLargeOutline from '@/components/Icons/Type/24/Outline/SocialMedia.vue';
import TextTypeLargeOutline from '@/components/Icons/Type/24/Outline/Text.vue';
import TwitterTypeLargeOutline from '@/components/Icons/Type/24/Outline/Twitter.vue';
import UrlDynamicTypeLargeOutline from '@/components/Icons/Type/24/Outline/UrlDynamic.vue';
import UrlStaticTypeLargeOutline from '@/components/Icons/Type/24/Outline/UrlStatic.vue';
import VcardTypeLargeOutline from '@/components/Icons/Type/24/Outline/Vcard.vue';
import VcardPlusTypeLargeOutline from '@/components/Icons/Type/24/Outline/VcardPlus.vue';
import VideoTypeLargeOutline from '@/components/Icons/Type/24/Outline/Video.vue';
import WifiTypeLargeOutline from '@/components/Icons/Type/24/Outline/Wifi.vue';
// endregion
// TYPE END

// MATERIAL
// region Fill@24px
import ArtMaterialLargeFill from '@/components/Icons/Material/24/Fill/Art.vue';
import BadgeMaterialLargeFill from '@/components/Icons/Material/24/Fill/Badge.vue';
import BannerMaterialLargeFill from '@/components/Icons/Material/24/Fill/Banner.vue';
import BillboardMaterialLargeFill from '@/components/Icons/Material/24/Fill/Billboard.vue';
import BookMaterialLargeFill from '@/components/Icons/Material/24/Fill/Book.vue';
import BottleMaterialLargeFill from '@/components/Icons/Material/24/Fill/Bottle.vue';
import BrochureMaterialLargeFill from '@/components/Icons/Material/24/Fill/Brochure.vue';
import BusinessCardMaterialLargeFill from '@/components/Icons/Material/24/Fill/BusinessCard.vue';
import CatalogueMaterialLargeFill from '@/components/Icons/Material/24/Fill/Catalogue.vue';
import CinemaAdMaterialLargeFill from '@/components/Icons/Material/24/Fill/CinemaAd.vue';
import ClothingMaterialLargeFill from '@/components/Icons/Material/24/Fill/Clothing.vue';
import DigitalDisplayMaterialLargeFill from '@/components/Icons/Material/24/Fill/DigitalDisplay.vue';
import DisplayMaterialLargeFill from '@/components/Icons/Material/24/Fill/Display.vue';
import EbookMaterialLargeFill from '@/components/Icons/Material/24/Fill/Ebook.vue';
import EmailMaterialLargeFill from '@/components/Icons/Material/24/Fill/Email.vue';
import FlyerMaterialLargeFill from '@/components/Icons/Material/24/Fill/Flyer.vue';
import FoodPackagingMaterialLargeFill from '@/components/Icons/Material/24/Fill/FoodPackaging.vue';
import GiveawayMaterialLargeFill from '@/components/Icons/Material/24/Fill/Giveaway.vue';
import InfographicMaterialLargeFill from '@/components/Icons/Material/24/Fill/Infographic.vue';
import MenuMaterialLargeFill from '@/components/Icons/Material/24/Fill/Menu.vue';
import NewspaperMaterialLargeFill from '@/components/Icons/Material/24/Fill/Newspaper.vue';
import PosterMaterialLargeFill from '@/components/Icons/Material/24/Fill/Poster.vue';
import ProductPackagingMaterialLargeFill from '@/components/Icons/Material/24/Fill/ProductPackaging.vue';
import SocialMediaMaterialLargeFill from '@/components/Icons/Material/24/Fill/SocialMedia.vue';
import StationaryMaterialLargeFill from '@/components/Icons/Material/24/Fill/Stationary.vue';
import StickerMaterialLargeFill from '@/components/Icons/Material/24/Fill/Sticker.vue';
import StreetSignMaterialLargeFill from '@/components/Icons/Material/24/Fill/StreetSign.vue';
import TableTentMaterialLargeFill from '@/components/Icons/Material/24/Fill/TableTent.vue';
import TicketMaterialLargeFill from '@/components/Icons/Material/24/Fill/Ticket.vue';
import TvAdMaterialLargeFill from '@/components/Icons/Material/24/Fill/TvAd.vue';
import VehicleMaterialLargeFill from '@/components/Icons/Material/24/Fill/Vehicle.vue';
import VideoGameMaterialLargeFill from '@/components/Icons/Material/24/Fill/VideoGame.vue';
import WebBannerMaterialLargeFill from '@/components/Icons/Material/24/Fill/WebBanner.vue';
import WebsiteMaterialLargeFill from '@/components/Icons/Material/24/Fill/Website.vue';
import WindowMaterialLargeFill from '@/components/Icons/Material/24/Fill/Window.vue';
// endregion

// region Outline@24px
import ArtMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Art.vue';
import BadgeMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Badge.vue';
import BannerMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Banner.vue';
import BillboardMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Billboard.vue';
import BookMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Book.vue';
import BottleMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Bottle.vue';
import BrochureMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Brochure.vue';
import BusinessCardMaterialLargeOutline from '@/components/Icons/Material/24/Outline/BusinessCard.vue';
import CatalogueMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Catalogue.vue';
import CinemaAdMaterialLargeOutline from '@/components/Icons/Material/24/Outline/CinemaAd.vue';
import ClothingMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Clothing.vue';
import DigitalDisplayMaterialLargeOutline from '@/components/Icons/Material/24/Outline/DigitalDisplay.vue';
import DisplayMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Display.vue';
import EbookMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Ebook.vue';
import EmailMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Email.vue';
import FlyerMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Flyer.vue';
import FoodPackagingMaterialLargeOutline from '@/components/Icons/Material/24/Outline/FoodPackaging.vue';
import GiveawayMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Giveaway.vue';
import InfographicMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Infographic.vue';
import MenuMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Menu.vue';
import NewspaperMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Newspaper.vue';
import PosterMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Poster.vue';
import ProductPackagingMaterialLargeOutline from '@/components/Icons/Material/24/Outline/ProductPackaging.vue';
import SocialMediaMaterialLargeOutline from '@/components/Icons/Material/24/Outline/SocialMedia.vue';
import StationaryMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Stationary.vue';
import StickerMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Sticker.vue';
import StreetSignMaterialLargeOutline from '@/components/Icons/Material/24/Outline/StreetSign.vue';
import TableTentMaterialLargeOutline from '@/components/Icons/Material/24/Outline/TableTent.vue';
import TicketMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Ticket.vue';
import TvAdMaterialLargeOutline from '@/components/Icons/Material/24/Outline/TvAd.vue';
import VehicleMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Vehicle.vue';
import VideoGameMaterialLargeOutline from '@/components/Icons/Material/24/Outline/VideoGame.vue';
import WebBannerMaterialLargeOutline from '@/components/Icons/Material/24/Outline/WebBanner.vue';
import WebsiteMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Website.vue';
import WindowMaterialLargeOutline from '@/components/Icons/Material/24/Outline/Window.vue';
// endregion
// MATERIAL END

// INDUSTRY
// region Fill@24px
import ArtistIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Artist.vue';
import BusinessIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Business.vue';
import BusinessServiceIndustryLargeFill from '@/components/Icons/Industry/24/Fill/BusinessService.vue';
import CityCultureIndustryLargeFill from '@/components/Icons/Industry/24/Fill/CityCulture.vue';
import EducationIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Education.vue';
import ElectronicIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Electronic.vue';
import EventManagerIndustryLargeFill from '@/components/Icons/Industry/24/Fill/EventManager.vue';
import FinanceIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Finance.vue';
import GovernmentIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Government.vue';
import GymIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Gym.vue';
import HealthcareIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Healthcare.vue';
import HomeRepairIndustryLargeFill from '@/components/Icons/Industry/24/Fill/HomeRepair.vue';
import HotelsIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Hotels.vue';
import MuseumIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Museum.vue';
import MusicianIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Musician.vue';
import NonprofitIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Nonprofit.vue';
import PackagedGoodIndustryLargeFill from '@/components/Icons/Industry/24/Fill/PackagedGood.vue';
import PhotographerIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Photographer.vue';
import PrivateIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Private.vue';
import PublisherIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Publisher.vue';
import RealEstateIndustryLargeFill from '@/components/Icons/Industry/24/Fill/RealEstate.vue';
import RestaurantIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Restaurant.vue';
import RetailIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Retail.vue';
import SoftwareDevIndustryLargeFill from '@/components/Icons/Industry/24/Fill/SoftwareDev.vue';
import TourismIndustryLargeFill from '@/components/Icons/Industry/24/Fill/Tourism.vue';
// endregion

// region Outline@24px
import ArtistIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Artist.vue';
import BusinessIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Business.vue';
import BusinessServiceIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/BusinessService.vue';
import CityCultureIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/CityCulture.vue';
import EducationIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Education.vue';
import ElectronicIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Electronic.vue';
import EventManagerIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/EventManager.vue';
import FinanceIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Finance.vue';
import GovernmentIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Government.vue';
import GymIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Gym.vue';
import HealthcareIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Healthcare.vue';
import HomeRepairIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/HomeRepair.vue';
import HotelsIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Hotels.vue';
import MuseumIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Museum.vue';
import MusicianIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Musician.vue';
import NonprofitIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Nonprofit.vue';
import PackagedGoodIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/PackagedGood.vue';
import PhotographerIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Photographer.vue';
import PrivateIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Private.vue';
import PublisherIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Publisher.vue';
import RealEstateIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/RealEstate.vue';
import RestaurantIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Restaurant.vue';
import RetailIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Retail.vue';
import SoftwareDevIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/SoftwareDev.vue';
import TourismIndustryLargeOutline from '@/components/Icons/Industry/24/Outline/Tourism.vue';
// endregion
// INDUSTRY END

/**
 * Custom Application Icons
 */

export default {
  // SYSTEM
  // region Fill@16px
  'account-system-small-fill': {
    component: AccountSystemSmallFill,
  },
  'active-system-small-fill': {
    component: ActiveSystemSmallFill,
  },
  'bulk-create-system-small-fill': {
    component: BulkCreateSystemSmallFill,
  },
  'bulk-update-system-small-fill': {
    component: BulkUpdateSystemSmallFill,
  },
  'caret-wide-system-small-fill': {
    component: CaretWideSystemSmallFill,
  },
  'check-checked-system-small-fill': {
    component: CheckCheckedSystemSmallFill,
  },
  'check-indeterminate-system-small-fill': {
    component: CheckIndeterminateSystemSmallFill,
  },
  'check-round-system-small-fill': {
    component: CheckRoundSystemSmallFill,
  },
  'clock-system-small-fill': {
    component: ClockSystemSmallFill,
  },
  'copy-system-small-fill': {
    component: CopySystemSmallFill,
  },
  'delete-system-small-fill': {
    component: DeleteSystemSmallFill,
  },
  'edit-system-small-fill': {
    component: EditSystemSmallFill,
  },
  'envelope-system-small-fill': {
    component: EnvelopeSystemSmallFill,
  },
  'filter-system-small-fill': {
    component: FilterSystemSmallFill,
  },
  'folder-system-small-fill': {
    component: FolderSystemSmallFill,
  },
  'gear-system-small-fill': {
    component: GearSystemSmallFill,
  },
  'heart-system-small-fill': {
    component: HeartSystemSmallFill,
  },
  'help-round-system-small-fill': {
    component: HelpRoundSystemSmallFill,
  },
  'home-system-small-fill': {
    component: HomeSystemSmallFill,
  },
  'info-system-small-fill': {
    component: InfoSystemSmallFill,
  },
  'lock-system-small-fill': {
    component: LockSystemSmallFill,
  },
  'logout-system-small-fill': {
    component: LogoutSystemSmallFill,
  },
  'multiple-folder-system-small-fill': {
    component: MultipleFolderSystemSmallFill,
  },
  'notification-system-small-fill': {
    component: NotificationSystemSmallFill,
  },
  'palette-system-small-fill': {
    component: PaletteSystemSmallFill,
  },
  'pause-system-small-fill': {
    component: PauseSystemSmallFill,
  },
  'pay-amex-system-small-fill': {
    component: PayAmexSystemSmallFill,
  },
  'pay-direct-debit-system-small-fill': {
    component: PayDirectDebitSystemSmallFill,
  },
  'pay-mastercard-system-small-fill': {
    component: PayMastercardSystemSmallFill,
  },
  'pay-sepa-system-small-fill': {
    component: PaySepaSystemSmallFill,
  },
  'pay-visa-system-small-fill': {
    component: PayVisaSystemSmallFill,
  },
  'pay-paypal-system-small-fill': {
    component: PayPaypalSystemSmallFill,
  },
  'price-round-system-small-fill': {
    component: PriceRoundSystemSmallFill,
  },
  'print-system-small-fill': {
    component: PrintSystemSmallFill,
  },
  'save-design-system-small-fill': {
    component: SaveDesignSystemSmallFill,
  },
  'share-system-small-fill': {
    component: ShareSystemSmallFill,
  },
  'track-system-small-fill': {
    component: TrackSystemSmallFill,
  },
  'upload-system-small-fill': {
    component: UploadSystemSmallFill,
  },
  'users-system-small-fill': {
    component: UsersSystemSmallFill,
  },
  'warning-system-small-fill': {
    component: WarningSystemSmallFill,
  },
  'web-system-small-fill': {
    component: WebSystemSmallFill,
  },
  // endregion

  // region Fill@24px
  'account-system-large-fill': {
    component: AccountSystemLargeFill,
  },
  'active-system-large-fill': {
    component: ActiveSystemLargeFill,
  },
  'bulk-create-system-large-fill': {
    component: BulkCreateSystemLargeFill,
  },
  'bulk-update-system-large-fill': {
    component: BulkUpdateSystemLargeFill,
  },
  'caret-wide-system-large-fill': {
    component: CaretWideSystemLargeFill,
  },
  'check-checked-system-large-fill': {
    component: CheckCheckedSystemLargeFill,
  },
  'check-indeterminate-system-large-fill': {
    component: CheckIndeterminateSystemLargeFill,
  },
  'check-round-system-large-fill': {
    component: CheckRoundSystemLargeFill,
  },
  'clock-system-large-fill': {
    component: ClockSystemLargeFill,
  },
  'copy-system-large-fill': {
    component: CopySystemLargeFill,
  },
  'delete-system-large-fill': {
    component: DeleteSystemLargeFill,
  },
  'edit-system-large-fill': {
    component: EditSystemLargeFill,
  },
  'envelope-system-large-fill': {
    component: EnvelopeSystemLargeFill,
  },
  'filter-system-large-fill': {
    component: FilterSystemLargeFill,
  },
  'folder-system-large-fill': {
    component: FolderSystemLargeFill,
  },
  'gear-system-large-fill': {
    component: GearSystemLargeFill,
  },
  'help-round-system-large-fill': {
    component: HelpRoundSystemLargeFill,
  },
  'home-system-large-fill': {
    component: HomeSystemLargeFill,
  },
  'info-system-large-fill': {
    component: InfoSystemLargeFill,
  },
  'lock-system-large-fill': {
    component: LockSystemLargeFill,
  },
  'logout-system-large-fill': {
    component: LogoutSystemLargeFill,
  },
  'multiple-folder-system-large-fill': {
    component: MultipleFolderSystemLargeFill,
  },
  'notification-system-large-fill': {
    component: NotificationSystemLargeFill,
  },
  'palette-system-large-fill': {
    component: PaletteSystemLargeFill,
  },
  'pause-system-large-fill': {
    component: PauseSystemLargeFill,
  },
  'pay-amex-system-large-fill': {
    component: PayAmexSystemLargeFill,
  },
  'pay-direct-debit-system-large-fill': {
    component: PayDirectDebitSystemLargeFill,
  },
  'pay-mastercard-system-large-fill': {
    component: PayMastercardSystemLargeFill,
  },
  'pay-sepa-system-large-fill': {
    component: PaySepaSystemLargeFill,
  },
  'pay-visa-system-large-fill': {
    component: PayVisaSystemLargeFill,
  },
  'pay-paypal-system-large-fill': {
    component: PayPaypalSystemLargeFill,
  },
  'price-round-system-large-fill': {
    component: PriceRoundSystemLargeFill,
  },
  'print-system-large-fill': {
    component: PrintSystemLargeFill,
  },
  'save-design-system-large-fill': {
    component: SaveDesignSystemLargeFill,
  },
  'share-system-large-fill': {
    component: ShareSystemLargeFill,
  },
  'track-system-large-fill': {
    component: TrackSystemLargeFill,
  },
  'upload-system-large-fill': {
    component: UploadSystemLargeFill,
  },
  'users-system-large-fill': {
    component: UsersSystemLargeFill,
  },
  'warning-system-large-fill': {
    component: WarningSystemLargeFill,
  },
  'web-system-large-fill': {
    component: WebSystemLargeFill,
  },
  // endregion

  // region Outline@16px
  'account-system-small-outline': {
    component: AccountSystemSmallOutline,
  },
  'active-system-small-outline': {
    component: ActiveSystemSmallOutline,
  },
  'add-system-small-outline': {
    component: AddSystemSmallOutline,
  },
  'align-center-system-small-outline': {
    component: AlignCenterSystemSmallOutline,
  },
  'align-left-system-small-outline': {
    component: AlignLeftSystemSmallOutline,
  },
  'align-right-system-small-outline': {
    component: AlignRightSystemSmallOutline,
  },
  'all-codes-system-small-outline': {
    component: AllCodesSystemSmallOutline,
  },
  'arrow-system-small-outline': {
    component: ArrowSystemSmallOutline,
  },
  'bulk-create-system-small-outline': {
    component: BulkCreateSystemSmallOutline,
  },
  'bulk-update-system-small-outline': {
    component: BulkUpdateSystemSmallOutline,
  },
  'caret-wide-system-small-outline': {
    component: CaretWideSystemSmallOutline,
  },
  'check-checked-system-small-outline': {
    component: CheckCheckedSystemSmallOutline,
  },
  'check-indeterminate-system-small-outline': {
    component: CheckIndeterminateSystemSmallOutline,
  },
  'checkmark-system-small-outline': {
    component: CheckmarkSystemSmallOutline,
  },
  'check-round-system-small-outline': {
    component: CheckRoundSystemSmallOutline,
  },
  'check-unchecked-system-small-outline': {
    component: CheckUncheckedSystemSmallOutline,
  },
  'chevron-system-small-outline': {
    component: ChevronSystemSmallOutline,
  },
  'clock-system-small-outline': {
    component: ClockSystemSmallOutline,
  },
  'copy-system-small-outline': {
    component: CopySystemSmallOutline,
  },
  'delete-system-small-outline': {
    component: DeleteSystemSmallOutline,
  },
  'double-arrows-system-small-outline': {
    component: DoubleArrowsSystemSmallOutline,
  },
  'download-system-small-outline': {
    component: DownloadSystemSmallOutline,
  },
  'edit-system-small-outline': {
    component: EditSystemSmallOutline,
  },
  'envelope-system-small-outline': {
    component: EnvelopeSystemSmallOutline,
  },
  'exit-system-small-outline': {
    component: ExitSystemSmallOutline,
  },
  'filter-system-small-outline': {
    component: FilterSystemSmallOutline,
  },
  'folder-system-small-outline': {
    component: FolderSystemSmallOutline,
  },
  'forbidden-system-small-outline': {
    component: ForbiddenSystemSmallOutline,
  },
  'gear-system-small-outline': {
    component: GearSystemSmallOutline,
  },
  'help-round-system-small-outline': {
    component: HelpRoundSystemSmallOutline,
  },
  'history-system-small-outline': {
    component: HistorySystemSmallOutline,
  },
  'home-system-small-outline': {
    component: HomeSystemSmallOutline,
  },
  'info-system-small-outline': {
    component: InfoSystemSmallOutline,
  },
  'link-external-system-small-outline': {
    component: LinkExternalSystemSmallOutline,
  },
  'link-horizontal-system-small-outline': {
    component: LinkHorizontalSystemSmallOutline,
  },
  'link-tilt-system-small-outline': {
    component: LinkTiltSystemSmallOutline,
  },
  'lock-system-small-outline': {
    component: LockSystemSmallOutline,
  },
  'logout-system-small-outline': {
    component: LogoutSystemSmallOutline,
  },
  'map-pin-system-small-outline': {
    component: MapPinSystemSmallOutline,
  },
  'menu-system-small-outline': {
    component: MenuSystemSmallOutline,
  },
  'menu-hide-system-small-outline': {
    component: MenuHideSystemSmallOutline,
  },
  'menu-show-system-small-outline': {
    component: MenuShowSystemSmallOutline,
  },
  'more-options-system-small-outline': {
    component: MoreOptionsSystemSmallOutline,
  },
  'multiple-folders-system-small-outline': {
    component: MultipleFolderSystemSmallOutline,
  },
  'notification-system-small-outline': {
    component: NotificationSystemSmallOutline,
  },
  'palette-system-small-outline': {
    component: PaletteSystemSmallOutline,
  },
  'pause-system-small-outline': {
    component: PauseSystemSmallOutline,
  },
  'price-round-system-small-outline': {
    component: PriceRoundSystemSmallOutline,
  },
  'print-system-small-outline': {
    component: PrintSystemSmallOutline,
  },
  'radio-selected-system-small-outline': {
    component: RadioSelectedSystemSmallOutline,
  },
  'radio-unselected-system-small-outline': {
    component: RadioUnselectedSystemSmallOutline,
  },
  'reload-system-small-outline': {
    component: ReloadSystemSmallOutline,
  },
  'save-design-system-small-outline': {
    component: SaveDesignSystemSmallOutline,
  },
  'search-system-small-outline': {
    component: SearchSystemSmallOutline,
  },
  'share-system-small-outline': {
    component: ShareSystemSmallOutline,
  },
  'track-system-small-outline': {
    component: TrackSystemSmallOutline,
  },
  'type-system-small-outline': {
    component: TypeSystemSmallOutline,
  },
  'upload-system-small-outline': {
    component: UploadSystemSmallOutline,
  },
  'users-system-small-outline': {
    component: UsersSystemSmallOutline,
  },
  'warning-system-small-outline': {
    component: WarningSystemSmallOutline,
  },
  'web-system-small-outline': {
    component: WebSystemSmallOutline,
  },
  // endregion

  // region Outline@24px
  'account-system-large-outline': {
    component: AccountSystemLargeOutline,
  },
  'active-system-large-outline': {
    component: ActiveSystemLargeOutline,
  },
  'add-system-large-outline': {
    component: AddSystemLargeOutline,
  },
  'align-center-system-large-outline': {
    component: AlignCenterSystemLargeOutline,
  },
  'align-left-system-large-outline': {
    component: AlignLeftSystemLargeOutline,
  },
  'align-right-system-large-outline': {
    component: AlignRightSystemLargeOutline,
  },
  'all-codes-system-large-outline': {
    component: AllCodesSystemLargeOutline,
  },
  'arrow-system-large-outline': {
    component: ArrowSystemLargeOutline,
  },
  'bulk-create-system-large-outline': {
    component: BulkCreateSystemLargeOutline,
  },
  'bulk-update-system-large-outline': {
    component: BulkUpdateSystemLargeOutline,
  },
  'caret-wide-system-large-outline': {
    component: CaretWideSystemLargeOutline,
  },
  'check-checked-system-large-outline': {
    component: CheckCheckedSystemLargeOutline,
  },
  'check-indeterminate-system-large-outline': {
    component: CheckIndeterminateSystemLargeOutline,
  },
  'checkmark-system-large-outline': {
    component: CheckmarkSystemLargeOutline,
  },
  'check-round-system-large-outline': {
    component: CheckRoundSystemLargeOutline,
  },
  'check-unchecked-system-large-outline': {
    component: CheckUncheckedSystemLargeOutline,
  },
  'chevron-system-large-outline': {
    component: ChevronSystemLargeOutline,
  },
  'clock-system-large-outline': {
    component: ClockSystemLargeOutline,
  },
  'copy-system-large-outline': {
    component: CopySystemLargeOutline,
  },
  'delete-system-large-outline': {
    component: DeleteSystemLargeOutline,
  },
  'double-arrows-system-large-outline': {
    component: DoubleArrowsSystemLargeOutline,
  },
  'download-system-large-outline': {
    component: DownloadSystemLargeOutline,
  },
  'edit-system-large-outline': {
    component: EditSystemLargeOutline,
  },
  'envelope-system-large-outline': {
    component: EnvelopeSystemLargeOutline,
  },
  'exit-system-large-outline': {
    component: ExitSystemLargeOutline,
  },
  'filter-system-large-outline': {
    component: FilterSystemLargeOutline,
  },
  'folder-system-large-outline': {
    component: FolderSystemLargeOutline,
  },
  'forbidden-system-large-outline': {
    component: ForbiddenSystemLargeOutline,
  },
  'gear-system-large-outline': {
    component: GearSystemLargeOutline,
  },
  'help-round-system-large-outline': {
    component: HelpRoundSystemLargeOutline,
  },
  'history-system-large-outline': {
    component: HistorySystemLargeOutline,
  },
  'home-system-large-outline': {
    component: HomeSystemLargeOutline,
  },
  'info-system-large-outline': {
    component: InfoSystemLargeOutline,
  },
  'link-external-system-large-outline': {
    component: LinkExternalSystemLargeOutline,
  },
  'link-horizontal-system-large-outline': {
    component: LinkHorizontalSystemLargeOutline,
  },
  'link-tilt-system-large-outline': {
    component: LinkTiltSystemLargeOutline,
  },
  'lock-system-large-outline': {
    component: LockSystemLargeOutline,
  },
  'logout-system-large-outline': {
    component: LogoutSystemLargeOutline,
  },
  'menu-system-large-outline': {
    component: MenuSystemLargeOutline,
  },
  'menu-hide-system-large-outline': {
    component: MenuHideSystemLargeOutline,
  },
  'menu-show-system-large-outline': {
    component: MenuShowSystemLargeOutline,
  },
  'more-options-system-large-outline': {
    component: MoreOptionsSystemLargeOutline,
  },
  'multiple-folders-system-large-outline': {
    component: MultipleFolderSystemLargeOutline,
  },
  'notification-system-large-outline': {
    component: NotificationSystemLargeOutline,
  },
  'palette-system-large-outline': {
    component: PaletteSystemLargeOutline,
  },
  'pause-system-large-outline': {
    component: PauseSystemLargeOutline,
  },
  'price-round-system-large-outline': {
    component: PriceRoundSystemLargeOutline,
  },
  'print-system-large-outline': {
    component: PrintSystemLargeOutline,
  },
  'radio-selected-system-large-outline': {
    component: RadioSelectedSystemLargeOutline,
  },
  'radio-unselected-system-large-outline': {
    component: RadioUnselectedSystemLargeOutline,
  },
  'reload-system-large-outline': {
    component: ReloadSystemLargeOutline,
  },
  'save-design-system-large-outline': {
    component: SaveDesignSystemLargeOutline,
  },
  'search-system-large-outline': {
    component: SearchSystemLargeOutline,
  },
  'share-system-large-outline': {
    component: ShareSystemLargeOutline,
  },
  'track-system-large-outline': {
    component: TrackSystemLargeOutline,
  },
  'type-system-large-outline': {
    component: TypeSystemLargeOutline,
  },
  'upload-system-large-outline': {
    component: UploadSystemLargeOutline,
  },
  'users-system-large-outline': {
    component: UsersSystemLargeOutline,
  },
  'warning-system-large-outline': {
    component: WarningSystemLargeOutline,
  },
  'web-system-large-outline': {
    component: WebSystemLargeOutline,
  },
  // endregion
  // SYSTEM END

  // SOCIAL
  // region Fill@16px
  'facebook-social-small-fill': {
    component: FacebookSocialSmallFill,
  },
  'google-social-small-fill': {
    component: GoogleSocialSmallFill,
  },
  'instagram-social-small-fill': {
    component: InstagramSocialSmallFill,
  },
  'linkedin-social-small-fill': {
    component: LinkedinSocialSmallFill,
  },
  'twitter-social-small-fill': {
    component: TwitterSocialSmallFill,
  },
  'xing-social-small-fill': {
    component: XingSocialSmallFill,
  },
  'youtube-social-small-fill': {
    component: YoutubeSocialSmallFill,
  },
  // endregion

  // region Fill@24px
  'facebook-social-large-fill': {
    component: FacebookSocialLargeFill,
  },
  'google-social-large-fill': {
    component: GoogleSocialLargeFill,
  },
  'instagram-social-large-fill': {
    component: InstagramSocialLargeFill,
  },
  'linkedin-social-large-fill': {
    component: LinkedinSocialLargeFill,
  },
  'twitter-social-large-fill': {
    component: TwitterSocialLargeFill,
  },
  'xing-social-large-fill': {
    component: XingSocialLargeFill,
  },
  'youtube-social-large-fill': {
    component: YoutubeSocialLargeFill,
  },
  // endregion

  // region Outline@16px
  'facebook-social-small-outline': {
    component: FacebookSocialSmallOutline,
  },
  'google-social-small-outline': {
    component: GoogleSocialSmallOutline,
  },
  'instagram-social-small-outline': {
    component: InstagramSocialSmallOutline,
  },
  'linkedin-social-small-outline': {
    component: LinkedinSocialSmallOutline,
  },
  'twitter-social-small-outline': {
    component: TwitterSocialSmallOutline,
  },
  'xing-social-small-outline': {
    component: XingSocialSmallOutline,
  },
  'youtube-social-small-outline': {
    component: YoutubeSocialSmallOutline,
  },
  // endregion

  // region Outline@24px
  'facebook-social-large-outline': {
    component: FacebookSocialLargeOutline,
  },
  'google-social-large-outline': {
    component: GoogleSocialLargeOutline,
  },
  'instagram-social-large-outline': {
    component: InstagramSocialLargeOutline,
  },
  'linkedin-social-large-outline': {
    component: LinkedinSocialLargeOutline,
  },
  'twitter-social-large-outline': {
    component: TwitterSocialLargeOutline,
  },
  'xing-social-large-outline': {
    component: XingSocialLargeOutline,
  },
  'youtube-social-large-outline': {
    component: YoutubeSocialLargeOutline,
  },
  // endregion
  // SOCIAL END

  // TYPE
  // region Fill@16px
  'app-type-small-fill': {
    component: AppTypeSmallFill,
  },
  'bitcoin-type-small-fill': {
    component: BitcoinTypeSmallFill,
  },
  'business-type-small-fill': {
    component: BusinessTypeSmallFill,
  },
  'coupon-type-small-fill': {
    component: CouponTypeSmallFill,
  },
  'email-type-small-fill': {
    component: EmailTypeSmallFill,
  },
  'epc-type-small-fill': {
    component: EpcTypeSmallFill,
  },
  'event-type-small-fill': {
    component: EventTypeSmallFill,
  },
  'facebook-like-type-small-fill': {
    component: FacebookLikeTypeSmallFill,
  },
  'facebook-messenger-type-small-fill': {
    component: FacebookMessengerTypeSmallFill,
  },
  'feedback-type-small-fill': {
    component: FeedbackTypeSmallFill,
  },
  'form-type-small-fill': {
    component: FormTypeSmallFill,
  },
  'img-gallery-type-small-fill': {
    component: ImgGalleryTypeSmallFill,
  },
  'mp3-type-small-fill': {
    component: Mp3TypeSmallFill,
  },
  'pdf-type-small-fill': {
    component: PdfTypeSmallFill,
  },
  'rating-type-small-fill': {
    component: RatingTypeSmallFill,
  },
  'sms-type-small-fill': {
    component: SmsTypeSmallFill,
  },
  'social-media-type-small-fill': {
    component: SocialMediaTypeSmallFill,
  },
  'text-type-small-fill': {
    component: TextTypeSmallFill,
  },
  'twitter-type-small-fill': {
    component: TwitterTypeSmallFill,
  },
  'url-dynamic-type-small-fill': {
    component: UrlDynamicTypeSmallFill,
  },
  'url-static-type-small-fill': {
    component: UrlStaticTypeSmallFill,
  },
  'vcard-type-small-fill': {
    component: VcardTypeSmallFill,
  },
  'vcard-plus-type-small-fill': {
    component: VcardPlusTypeSmallFill,
  },
  'video-type-small-fill': {
    component: VideoTypeSmallFill,
  },
  'wifi-type-small-fill': {
    component: WifiTypeSmallFill,
  },
  // endregion

  // region Fill@24px
  'app-type-large-fill': {
    component: AppTypeLargeFill,
  },
  'bitcoin-type-large-fill': {
    component: BitcoinTypeLargeFill,
  },
  'business-type-large-fill': {
    component: BusinessTypeLargeFill,
  },
  'coupon-type-large-fill': {
    component: CouponTypeLargeFill,
  },
  'email-type-large-fill': {
    component: EmailTypeLargeFill,
  },
  'epc-type-large-fill': {
    component: EpcTypeLargeFill,
  },
  'event-type-large-fill': {
    component: EventTypeLargeFill,
  },
  'facebook-like-type-large-fill': {
    component: FacebookLikeTypeLargeFill,
  },
  'facebook-messenger-type-large-fill': {
    component: FacebookMessengerTypeLargeFill,
  },
  'feedback-type-large-fill': {
    component: FeedbackTypeLargeFill,
  },
  'form-type-large-fill': {
    component: FormTypeLargeFill,
  },
  'img-gallery-type-large-fill': {
    component: ImgGalleryTypeLargeFill,
  },
  'mp3-type-large-fill': {
    component: Mp3TypeLargeFill,
  },
  'pdf-type-large-fill': {
    component: PdfTypeLargeFill,
  },
  'rating-type-large-fill': {
    component: RatingTypeLargeFill,
  },
  'sms-type-large-fill': {
    component: SmsTypeLargeFill,
  },
  'social-media-type-large-fill': {
    component: SocialMediaTypeLargeFill,
  },
  'text-type-large-fill': {
    component: TextTypeLargeFill,
  },
  'twitter-type-large-fill': {
    component: TwitterTypeLargeFill,
  },
  'url-dynamic-type-large-fill': {
    component: UrlDynamicTypeLargeFill,
  },
  'url-static-type-large-fill': {
    component: UrlStaticTypeLargeFill,
  },
  'vcard-type-large-fill': {
    component: VcardTypeLargeFill,
  },
  'vcard-plus-type-large-fill': {
    component: VcardPlusTypeLargeFill,
  },
  'video-type-large-fill': {
    component: VideoTypeLargeFill,
  },
  'wifi-type-large-fill': {
    component: WifiTypeLargeFill,
  },
  // endregion

  // region Outline@16px
  'app-type-small-outline': {
    component: AppTypeSmallOutline,
  },
  'bitcoin-type-small-outline': {
    component: BitcoinTypeSmallOutline,
  },
  'business-type-small-outline': {
    component: BusinessTypeSmallOutline,
  },
  'coupon-type-small-outline': {
    component: CouponTypeSmallOutline,
  },
  'email-type-small-outline': {
    component: EmailTypeSmallOutline,
  },
  'epc-type-small-outline': {
    component: EpcTypeSmallOutline,
  },
  'event-type-small-outline': {
    component: EventTypeSmallOutline,
  },
  'facebook-like-type-small-outline': {
    component: FacebookLikeTypeSmallOutline,
  },
  'facebook-messenger-type-small-outline': {
    component: FacebookMessengerTypeSmallOutline,
  },
  'feedback-type-small-outline': {
    component: FeedbackTypeSmallOutline,
  },
  'form-type-small-outline': {
    component: FormTypeSmallOutline,
  },
  'img-gallery-type-small-outline': {
    component: ImgGalleryTypeSmallOutline,
  },
  'mp3-type-small-outline': {
    component: Mp3TypeSmallOutline,
  },
  'pdf-type-small-outline': {
    component: PdfTypeSmallOutline,
  },
  'rating-type-small-outline': {
    component: RatingTypeSmallOutline,
  },
  'sms-type-small-outline': {
    component: SmsTypeSmallOutline,
  },
  'social-media-type-small-outline': {
    component: SocialMediaTypeSmallOutline,
  },
  'text-type-small-outline': {
    component: TextTypeSmallOutline,
  },
  'twitter-type-small-outline': {
    component: TwitterTypeSmallOutline,
  },
  'url-dynamic-type-small-outline': {
    component: UrlDynamicTypeSmallOutline,
  },
  'url-static-type-small-outline': {
    component: UrlStaticTypeSmallOutline,
  },
  'vcard-type-small-outline': {
    component: VcardTypeSmallOutline,
  },
  'vcard-plus-type-small-outline': {
    component: VcardPlusTypeSmallOutline,
  },
  'video-type-small-outline': {
    component: VideoTypeSmallOutline,
  },
  'wifi-type-small-outline': {
    component: WifiTypeSmallOutline,
  },
  // endregion

  // region Outline@24px
  'app-type-large-outline': {
    component: AppTypeLargeOutline,
  },
  'bitcoin-type-large-outline': {
    component: BitcoinTypeLargeOutline,
  },
  'business-type-large-outline': {
    component: BusinessTypeLargeOutline,
  },
  'coupon-type-large-outline': {
    component: CouponTypeLargeOutline,
  },
  'email-type-large-outline': {
    component: EmailTypeLargeOutline,
  },
  'epc-type-large-outline': {
    component: EpcTypeLargeOutline,
  },
  'event-type-large-outline': {
    component: EventTypeLargeOutline,
  },
  'facebook-like-type-large-outline': {
    component: FacebookLikeTypeLargeOutline,
  },
  'facebook-messenger-type-large-outline': {
    component: FacebookMessengerTypeLargeOutline,
  },
  'feedback-type-large-outline': {
    component: FeedbackTypeLargeOutline,
  },
  'form-type-large-outline': {
    component: FormTypeLargeOutline,
  },
  'img-gallery-type-large-outline': {
    component: ImgGalleryTypeLargeOutline,
  },
  'mp3-type-large-outline': {
    component: Mp3TypeLargeOutline,
  },
  'pdf-type-large-outline': {
    component: PdfTypeLargeOutline,
  },
  'rating-type-large-outline': {
    component: RatingTypeLargeOutline,
  },
  'sms-type-large-outline': {
    component: SmsTypeLargeOutline,
  },
  'social-media-type-large-outline': {
    component: SocialMediaTypeLargeOutline,
  },
  'text-type-large-outline': {
    component: TextTypeLargeOutline,
  },
  'twitter-type-large-outline': {
    component: TwitterTypeLargeOutline,
  },
  'url-dynamic-type-large-outline': {
    component: UrlDynamicTypeLargeOutline,
  },
  'url-static-type-large-outline': {
    component: UrlStaticTypeLargeOutline,
  },
  'vcard-type-large-outline': {
    component: VcardTypeLargeOutline,
  },
  'vcard-plus-type-large-outline': {
    component: VcardPlusTypeLargeOutline,
  },
  'video-type-large-outline': {
    component: VideoTypeLargeOutline,
  },
  'wifi-type-large-outline': {
    component: WifiTypeLargeOutline,
  },
  // endregion
  // TYPE END

  // MATERIAL
  // region Fill@24px
  'art-material-large-fill': {
    component: ArtMaterialLargeFill,
  },
  'badge-material-large-fill': {
    component: BadgeMaterialLargeFill,
  },
  'banner-material-large-fill': {
    component: BannerMaterialLargeFill,
  },
  'billboard-material-large-fill': {
    component: BillboardMaterialLargeFill,
  },
  'book-material-large-fill': {
    component: BookMaterialLargeFill,
  },
  'bottle-material-large-fill': {
    component: BottleMaterialLargeFill,
  },
  'brochure-material-large-fill': {
    component: BrochureMaterialLargeFill,
  },
  'business-card-material-large-fill': {
    component: BusinessCardMaterialLargeFill,
  },
  'catalogue-material-large-fill': {
    component: CatalogueMaterialLargeFill,
  },
  'cinema-material-large-fill': {
    component: CinemaAdMaterialLargeFill,
  },
  'clothing-material-large-fill': {
    component: ClothingMaterialLargeFill,
  },
  'digital-display-material-large-fill': {
    component: DigitalDisplayMaterialLargeFill,
  },
  'display-material-large-fill': {
    component: DisplayMaterialLargeFill,
  },
  'ebook-material-large-fill': {
    component: EbookMaterialLargeFill,
  },
  'email-material-large-fill': {
    component: EmailMaterialLargeFill,
  },
  'flyer-material-large-fill': {
    component: FlyerMaterialLargeFill,
  },
  'food-packaging-material-large-fill': {
    component: FoodPackagingMaterialLargeFill,
  },
  'giveaway-material-large-fill': {
    component: GiveawayMaterialLargeFill,
  },
  'infographic-material-large-fill': {
    component: InfographicMaterialLargeFill,
  },
  'menu-material-large-fill': {
    component: MenuMaterialLargeFill,
  },
  'newspaper-material-large-fill': {
    component: NewspaperMaterialLargeFill,
  },
  'poster-material-large-fill': {
    component: PosterMaterialLargeFill,
  },
  'product-packaging-material-large-fill': {
    component: ProductPackagingMaterialLargeFill,
  },
  'social-media-material-large-fill': {
    component: SocialMediaMaterialLargeFill,
  },
  'stationary-material-large-fill': {
    component: StationaryMaterialLargeFill,
  },
  'sticker-material-large-fill': {
    component: StickerMaterialLargeFill,
  },
  'street-sign-material-large-fill': {
    component: StreetSignMaterialLargeFill,
  },
  'table-tent-material-large-fill': {
    component: TableTentMaterialLargeFill,
  },
  'ticket-material-large-fill': {
    component: TicketMaterialLargeFill,
  },
  'tv-ad-material-large-fill': {
    component: TvAdMaterialLargeFill,
  },
  'vehicle-material-large-fill': {
    component: VehicleMaterialLargeFill,
  },
  'video-game-material-large-fill': {
    component: VideoGameMaterialLargeFill,
  },
  'web-banner-material-large-fill': {
    component: WebBannerMaterialLargeFill,
  },
  'website-material-large-fill': {
    component: WebsiteMaterialLargeFill,
  },
  'window-material-large-fill': {
    component: WindowMaterialLargeFill,
  },
  // endregion

  // region Outline@24px
  'art-material-large-outline': {
    component: ArtMaterialLargeOutline,
  },
  'badge-material-large-outline': {
    component: BadgeMaterialLargeOutline,
  },
  'banner-material-large-outline': {
    component: BannerMaterialLargeOutline,
  },
  'billboard-material-large-outline': {
    component: BillboardMaterialLargeOutline,
  },
  'book-material-large-outline': {
    component: BookMaterialLargeOutline,
  },
  'bottle-material-large-outline': {
    component: BottleMaterialLargeOutline,
  },
  'brochure-material-large-outline': {
    component: BrochureMaterialLargeOutline,
  },
  'business-card-material-large-outline': {
    component: BusinessCardMaterialLargeOutline,
  },
  'catalogue-material-large-outline': {
    component: CatalogueMaterialLargeOutline,
  },
  'cinema-material-large-outline': {
    component: CinemaAdMaterialLargeOutline,
  },
  'clothing-material-large-outline': {
    component: ClothingMaterialLargeOutline,
  },
  'digital-display-material-large-outline': {
    component: DigitalDisplayMaterialLargeOutline,
  },
  'display-material-large-outline': {
    component: DisplayMaterialLargeOutline,
  },
  'ebook-material-large-outline': {
    component: EbookMaterialLargeOutline,
  },
  'email-material-large-outline': {
    component: EmailMaterialLargeOutline,
  },
  'flyer-material-large-outline': {
    component: FlyerMaterialLargeOutline,
  },
  'food-packaging-material-large-outline': {
    component: FoodPackagingMaterialLargeOutline,
  },
  'giveaway-material-large-outline': {
    component: GiveawayMaterialLargeOutline,
  },
  'infographic-material-large-outline': {
    component: InfographicMaterialLargeOutline,
  },
  'menu-material-large-outline': {
    component: MenuMaterialLargeOutline,
  },
  'newspaper-material-large-outline': {
    component: NewspaperMaterialLargeOutline,
  },
  'poster-material-large-outline': {
    component: PosterMaterialLargeOutline,
  },
  'product-packaging-material-large-outline': {
    component: ProductPackagingMaterialLargeOutline,
  },
  'social-media-material-large-outline': {
    component: SocialMediaMaterialLargeOutline,
  },
  'stationary-material-large-outline': {
    component: StationaryMaterialLargeOutline,
  },
  'sticker-material-large-outline': {
    component: StickerMaterialLargeOutline,
  },
  'street-sign-material-large-outline': {
    component: StreetSignMaterialLargeOutline,
  },
  'table-tent-material-large-outline': {
    component: TableTentMaterialLargeOutline,
  },
  'ticket-material-large-outline': {
    component: TicketMaterialLargeOutline,
  },
  'tv-ad-material-large-outline': {
    component: TvAdMaterialLargeOutline,
  },
  'vehicle-material-large-outline': {
    component: VehicleMaterialLargeOutline,
  },
  'video-game-material-large-outline': {
    component: VideoGameMaterialLargeOutline,
  },
  'web-banner-material-large-outline': {
    component: WebBannerMaterialLargeOutline,
  },
  'website-material-large-outline': {
    component: WebsiteMaterialLargeOutline,
  },
  'window-material-large-outline': {
    component: WindowMaterialLargeOutline,
  },
  // endregion
  // MATERIAL END

  // INDUSTRY
  // region Fill@24px
  'artist-industry-large-fill': {
    component: ArtistIndustryLargeFill,
  },
  'business-industry-large-fill': {
    component: BusinessIndustryLargeFill,
  },
  'business-service-industry-large-fill': {
    component: BusinessServiceIndustryLargeFill,
  },
  'city-culture-industry-large-fill': {
    component: CityCultureIndustryLargeFill,
  },
  'education-industry-large-fill': {
    component: EducationIndustryLargeFill,
  },
  'electronic-industry-large-fill': {
    component: ElectronicIndustryLargeFill,
  },
  'event-manager-industry-large-fill': {
    component: EventManagerIndustryLargeFill,
  },
  'finance-industry-large-fill': {
    component: FinanceIndustryLargeFill,
  },
  'government-industry-large-fill': {
    component: GovernmentIndustryLargeFill,
  },
  'gym-industry-large-fill': {
    component: GymIndustryLargeFill,
  },
  'healthcare-industry-large-fill': {
    component: HealthcareIndustryLargeFill,
  },
  'home-repair-industry-large-fill': {
    component: HomeRepairIndustryLargeFill,
  },
  'hotels-industry-large-fill': {
    component: HotelsIndustryLargeFill,
  },
  'museum-industry-large-fill': {
    component: MuseumIndustryLargeFill,
  },
  'musician-industry-large-fill': {
    component: MusicianIndustryLargeFill,
  },
  'nonprofit-industry-large-fill': {
    component: NonprofitIndustryLargeFill,
  },
  'packaged-good-industry-large-fill': {
    component: PackagedGoodIndustryLargeFill,
  },
  'photographer-industry-large-fill': {
    component: PhotographerIndustryLargeFill,
  },
  'private-industry-large-fill': {
    component: PrivateIndustryLargeFill,
  },
  'publisher-industry-large-fill': {
    component: PublisherIndustryLargeFill,
  },
  'real-estate-industry-large-fill': {
    component: RealEstateIndustryLargeFill,
  },
  'restaurant-industry-large-fill': {
    component: RestaurantIndustryLargeFill,
  },
  'retail-industry-large-fill': {
    component: RetailIndustryLargeFill,
  },
  'software-dev-industry-large-fill': {
    component: SoftwareDevIndustryLargeFill,
  },
  'tourism-industry-large-fill': {
    component: TourismIndustryLargeFill,
  },
  // endregion

  // region Outline@24px
  'artist-industry-large-outline': {
    component: ArtistIndustryLargeOutline,
  },
  'business-industry-large-outline': {
    component: BusinessIndustryLargeOutline,
  },
  'business-service-industry-large-outline': {
    component: BusinessServiceIndustryLargeOutline,
  },
  'city-culture-industry-large-outline': {
    component: CityCultureIndustryLargeOutline,
  },
  'education-industry-large-outline': {
    component: EducationIndustryLargeOutline,
  },
  'electronic-industry-large-outline': {
    component: ElectronicIndustryLargeOutline,
  },
  'event-manager-industry-large-outline': {
    component: EventManagerIndustryLargeOutline,
  },
  'finance-industry-large-outline': {
    component: FinanceIndustryLargeOutline,
  },
  'government-industry-large-outline': {
    component: GovernmentIndustryLargeOutline,
  },
  'gym-industry-large-outline': {
    component: GymIndustryLargeOutline,
  },
  'healthcare-industry-large-outline': {
    component: HealthcareIndustryLargeOutline,
  },
  'home-repair-industry-large-outline': {
    component: HomeRepairIndustryLargeOutline,
  },
  'hotels-industry-large-outline': {
    component: HotelsIndustryLargeOutline,
  },
  'museum-industry-large-outline': {
    component: MuseumIndustryLargeOutline,
  },
  'musician-industry-large-outline': {
    component: MusicianIndustryLargeOutline,
  },
  'nonprofit-industry-large-outline': {
    component: NonprofitIndustryLargeOutline,
  },
  'packaged-good-industry-large-outline': {
    component: PackagedGoodIndustryLargeOutline,
  },
  'photographer-industry-large-outline': {
    component: PhotographerIndustryLargeOutline,
  },
  'private-industry-large-outline': {
    component: PrivateIndustryLargeOutline,
  },
  'publisher-industry-large-outline': {
    component: PublisherIndustryLargeOutline,
  },
  'real-estate-industry-large-outline': {
    component: RealEstateIndustryLargeOutline,
  },
  'restaurant-industry-large-outline': {
    component: RestaurantIndustryLargeOutline,
  },
  'retail-industry-large-outline': {
    component: RetailIndustryLargeOutline,
  },
  'software-dev-industry-large-outline': {
    component: SoftwareDevIndustryLargeOutline,
  },
  'tourism-industry-large-outline': {
    component: TourismIndustryLargeOutline,
  },
  // endregion
  // INDUSTRY END
};
