import { Plan } from '@/classes/stripe';
import type { PlanState } from './StripeSubscriptionService';

type SubscribeButtonLabel = 'continue' | 'selected' | 'upgrade' | 'downgrade' | 'reactivate' | 'incomplete';

const labels: { [key in PlanState]: UiLabel } = {
  NO_PLAN: {
    label: 'fields.pricing_table.button.checkout_now',
    state: 'continue',
  },
  PLAN_INCOMPLETE: {
    label: 'fields.pricing_table.button.incomplete',
    state: 'incomplete',
  },
  PLAN_ACTIVE: {
    label: 'fields.pricing_table.button.active',
    state: 'selected',
  },
  PLAN_UPCOMING: {
    label: 'fields.pricing_table.button.upcoming',
    state: 'selected',
  },
  PLAN_INFERIOR: {
    label: 'fields.pricing_table.button.downgrade',
    state: 'downgrade',
  },
  PLAN_DOWNGRADING: {
    label: 'fields.pricing_table.button.reactivate',
    state: 'reactivate',
  },
  PLAN_CANCELLED: {
    label: 'fields.pricing_table.button.reactivate',
    state: 'reactivate',
  },
  PLAN_SUPERIOR: {
    label: 'fields.pricing_table.button.upgrade',
    state: 'upgrade',
  },
} as const;

type UiLabel = {
  label: string;
  state: SubscribeButtonLabel;
};

export function labelForPlanState(planState: PlanState): UiLabel {
  return labels[planState] ?? labels.NO_PLAN;
}

// Order the plans descending by plan level
export function filterAndSortPlans(subscriptionPlans: Plan[]): Plan[] {
  return subscriptionPlans
    .filter(
      (plan: Plan) =>
        !plan.hidden &&
        (plan.interval === 'year' || plan.interval === 'day' || plan.interval === 'month') &&
        !plan.isEnterprisePlan
    )
    .sort((planA: Plan, planB: Plan) => parseInt(planB.planLevel as string) - parseInt(planA.planLevel as string));
}
