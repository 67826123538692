import AppConfig from '@/AppConfig';
import { LoadingState, Plan, Subscription, SubscriptionState } from '@/classes/stripe';
import { StripePaymentMethodService } from '@/Services/Subscription/StripePaymentMethodService';
import store, { RootState } from '@/store';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { Module } from 'vuex';

const SubscriptionStore: Module<SubscriptionState, RootState> = {
  namespaced: true,

  state: new SubscriptionState(),

  mutations: {
    loadingState(state, { loading, isLoading }: { loading: string; isLoading: boolean }) {
      state.showLoading[loading as keyof LoadingState] = isLoading;
    },
    subscriptionPlans(state, plans) {
      state.plans = plans.map((item: Plan) => new Plan(item)) || [];
    },
    subscriptionSelectedPlan(state, plan) {
      state.selectedPlan = new Plan(plan);
    },
    activeSubscription(state, subscription) {
      state.activeSubscription = new Subscription(subscription);
    },
    activePlan(state, plan) {
      state.activePlan = new Plan(plan);
    },
    cancelSubscription(state, isCancelled) {
      state.activeSubscription.is_cancelled = isCancelled;
    },
    setStripePaymentMethodService(state, stripeInstanceCountry) {
      state.stripePaymentMethodService = new StripePaymentMethodService(stripeInstanceCountry);
    },
    setStripePaymentMethodServicePaymentType(state, paymentType) {
      if (state.stripePaymentMethodService) {
        state.stripePaymentMethodService.paymentType = paymentType;
      }
    },
  },

  actions: {
    async init() {
      // Preloading selected plan, to keep selected plan after a refresh.
      await store.dispatch('subscription/initSubscriptionPlans');
      await store.dispatch('subscription/loadSubscription');
      return Promise.resolve();
    },
    async resetSelectedPlan({ commit }) {
      return commit('subscriptionSelectedPlan', new Plan());
    },
    async initSubscriptionPlans({ commit, state }) {
      if (state.plans.length === 0) {
        commit('loadingState', { loading: 'loadingPlans', isLoading: true });
        return await axios
          .get(AppConfig.getAPIBaseUrl() + '/subscription/stripe/plans/')
          .then((response) => {
            commit('subscriptionPlans', response?.data?.data || []);
            commit('loadingState', { loading: 'loadingPlans', isLoading: false });
          })
          .catch((error: AxiosError) => {
            Sentry.captureException(error);
          });
      }
    },
    async setSubscriptionPlan({ commit }, plan) {
      commit('subscriptionSelectedPlan', plan);
    },
    async setActiveSubscription({ commit }, subscription: Subscription) {
      commit('activeSubscription', subscription);
      commit('activePlan', subscription?.plan ?? new Plan());
    },
    async cancelSubscription({ commit, state }) {
      commit('loadingState', { loading: 'cancelSubscription', isLoading: true });
      return await axios
        .delete(AppConfig.getAPIBaseUrl() + '/subscription/stripe/' + state.activeSubscription.id)
        .then(async () => {
          await store.dispatch('subscription/loadSubscription');
          commit('cancelSubscription', true);
          commit('loadingState', { loading: 'cancelSubscription', isLoading: false });
        })
        .catch((error: AxiosError) => {
          Sentry.captureException(error);
          commit('loadingState', { loading: 'loadingSubscription', isLoading: false });
        });
    },
    // Fetch fresh data from API directly to fill activeSubscription and Plan
    async loadSubscription({ commit }) {
      commit('loadingState', { loading: 'loadingSubscription', isLoading: true });
      return await axios
        .get(AppConfig.getAPIBaseUrl() + '/subscription/stripe/default')
        .then(async (response) => {
          await store.dispatch('subscription/setActiveSubscription', response.data);
          commit('loadingState', { loading: 'loadingSubscription', isLoading: false });
        })
        .catch((error: AxiosError) => {
          Sentry.captureException(error);
          commit('loadingState', { loading: 'loadingSubscription', isLoading: false });
        });
    },
    async reactivateSubscription({ commit }, planId) {
      commit('loadingState', { loading: 'reactivateSubscription', isLoading: true });
      await axios
        .patch(AppConfig.getAPIBaseUrl() + '/subscription/stripe/' + planId, {
          plan_id: planId,
        })
        .then(() => {
          store.dispatch('layout/setSnackbar', {
            type: 'info',
            icon: 'info-system',
            text: 'Your subscription has been resumed successfully',
            isSnackbar: false,
            active: true,
          });
        })
        .catch((error: AxiosError) => {
          Sentry.captureException(error);
          store.dispatch('layout/setSnackbar', {
            type: 'error',
            icon: 'forbidden-system',
            text: 'We failed to reactivate your subscription. Please try again or contact support',
            isSnackbar: false,
            active: true,
          });
        })
        .finally(() => {
          commit('cancelSubscription', false);
          commit('loadingState', { loading: 'reactivateSubscription', isLoading: false });
        });
    },
  },
  getters: {
    isEnterprise(state) {
      return state?.activePlan?.isEnterprisePlan || false;
    },

    relatedPlans(state) {
      return state.plans.filter((plan: Plan) => plan.product_id === state.selectedPlan.product_id);
    },
  },
};

export default SubscriptionStore;
