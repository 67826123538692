import { User } from '@/classes/auth';

export default class OptimizelyService {
  public static trackEvent(eventName: string, tags: object | null = null): void {
    window['optimizely'] = window['optimizely'] || [];

    // Sends a tracking call to Optimizely for the given event name
    const data = {
      type: 'event',
      eventName: eventName,
    };

    if (tags) {
      Object.assign(data, { tags });
    }
    // @ts-ignore
    window['optimizely'].push(data);
  }

  public static isAbTestVariantActive(experimentId: string | null = null, variantId: string | null = null): boolean {
    if (experimentId === null || variantId === null) return false;
    if (this.optimizelyIsNotInitialized()) return false; // If Optimizely is not active/available

    const optimizelyState = window.optimizely.get('state');
    return (
      optimizelyState.getActiveExperimentIds().includes(experimentId) &&
      optimizelyState.getVariationMap()[experimentId].id == variantId
    );
  }

  public static optimizelyIsNotInitialized(): boolean {
    return window.optimizely === undefined || window.optimizely === null;
  }

  public static isUserOnAbtestVariantCNV3020(): boolean {
    return OptimizelyService.isAbTestVariantActive('26353010905', '26442340272');
  }

  public static isPlanAbTestVariantMonthlyEnabled(user: User): boolean | undefined {
    if (user.team.ab_tests) {
      for (const test of user.team.ab_tests) {
        if (test.includes('original')) {
          return false;
        }

        if (test.startsWith('CNV-3531') || test.startsWith('CNV-3532')) {
          return true;
        }
      }
    }

    return false;
  }
}
