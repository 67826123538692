<template>
  <v-dialog
    :value="open"
    max-width="572"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    content-class="qr-dialog"
    scrollable
    @input="cancel"
  >
    <v-card rounded class="pa-6">
      <!-- Illustration -->
      <v-img
        v-if="isEnterprise"
        src="../../../assets/illustrations/modal_account-cancelation-enterprise.png"
        height="160"
        contain
        class="mb-11"
      />
      <v-img
        v-else
        src="../../../assets/illustrations/modal_account-cancelation.png"
        height="160"
        contain
        class="mb-11"
      />

      <!-- Title -->
      <v-card-title class="px-0 pt-0 text-center d-block">
        <h4
          class="text-h4"
          v-text="
            isEnterprise
              ? $t('fields.account.billing.cancel_subs.modal_title_ent')
              : $t('fields.account.billing.cancel_subs.modal_title')
          "
        />
      </v-card-title>

      <!-- Subtitle description -->
      <v-card-subtitle v-if="isEnterprise" class="pt-3 pb-6 px-10 text-center">
        {{ $t('fields.account.billing.cancel_subs.modal_desc_ent') }}
      </v-card-subtitle>
      <v-card-subtitle v-else class="pt-3 pb-6 px-10 text-center">
        {{ $t('fields.account.billing.cancel_subs.modal_desc') }}
      </v-card-subtitle>

      <!-- Actions -->
      <v-card-actions class="px-0 py-0 justify-center">
        <v-btn
          name="btn-cancel-keep-benefits"
          depressed
          :ripple="false"
          rounded
          color="primary"
          class="mr-4"
          @click="cancel"
        >
          {{
            isEnterprise
              ? $t('fields.account.billing.cancel_subs.modal_btn_primary_ent')
              : $t('fields.account.billing.cancel_subs.modal_btn_primary')
          }}
        </v-btn>
        <v-btn
          name="btn-cancel-contact-us"
          outlined
          :ripple="false"
          rounded
          color="primary"
          class="ml-0"
          @click="toggleSupportModal(true)"
        >
          {{
            isEnterprise
              ? $t('fields.account.billing.cancel_subs.modal_btn_outline_ent')
              : $t('fields.account.billing.cancel_subs.modal_btn_outline')
          }}
        </v-btn>
      </v-card-actions>

      <!-- Actions -->
      <v-card-actions v-if="!isEnterprise" class="px-0 py-0 mt-7 justify-center">
        <v-btn name="btn-cancel-cta" text x-small :ripple="false" color="navy" to="/cancel-subscription">
          {{ $t('fields.account.billing.cancel_subs.modal_decision_link') }}
        </v-btn>
      </v-card-actions>

      <Alert
        v-if="!isEnterprise"
        outlined
        type="info"
        icon="info-system"
        regular-title
        class="subscription-cancel__alert"
      >
        <template #title>
          <span
            v-html="
              $t('fields.account.billing.cancel_subs.modal_note', {
                plan_title: $t(planName),
                subs_end: subscription.current_period_end.format('MMM DD, YYYY'),
              })
            "
          />
        </template>
      </Alert>

      <Dismiss absolute top right @click.stop="cancel" />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import Authenticator from '@/Services/Auth/Authenticator';
import { User } from '@/classes/auth';
import Alert from '@/components/Alert.vue';
import Dismiss from '@/components/Dismiss.vue';
import { SupportModalMixin } from '@/mixins/SupportModalMixin';
import store from '@/store';

@Component({
  components: {
    Alert,
    Dismiss,
  },
  computed: {
    ...mapGetters('subscription', {
      isEnterprise: 'isEnterprise', // <- for runtime
    }),
  },
})
export default class CancelPauseSubscription extends SupportModalMixin {
  // region PROPS
  @Prop(Boolean)
  open!: boolean;
  // endregion PROPS END

  subscription = store.state.subscription.activeSubscription;

  // region PROPERTIES
  stripeSubscriptionService: StripeSubscriptionService = new StripeSubscriptionService();
  user: User = Authenticator.getUser();
  // endregion PROPERTIES END

  // getter from vuex
  isEnterprise!: boolean;

  get planName(): string | undefined {
    return this.stripeSubscriptionService.findPlan(this.subscription.plan_id)?.title;
  }

  // endregion COMPUTED

  cancel(): void {
    this.$emit('cancel');
  }
}
</script>

<style lang="scss">
.subscription-cancel {
  &__alert {
    margin: 40px 0 0;
    padding: 4px;
    font-size: 12px;
    border-width: 1px !important;
    border-radius: 4px !important;

    .icon {
      width: 12px !important;
      height: 12px !important;
      margin-right: 6px !important;
    }

    .v-alert__content {
      align-items: baseline;

      .alert__icon-wrapper {
        align-self: baseline;
      }
    }
  }
}
</style>
