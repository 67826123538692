<template>
  <div class="pricing-plan__card-wrapper mb-13">
    <v-card outlined height="100%" class="pricing-plan__card--enterprise">
      <v-card-title class="pricing-plan__title pa-0 flex-column">
        <v-chip label x-small class="chip--badge text-uppercase font-weight-bold" text-color="lapis lighten-1">
          Current plan
        </v-chip>
        <h3 class="text-h3 lapis--text mt-2">Enterprise</h3>
      </v-card-title>

      <v-card-subtitle class="pricing-plan__subtitle--enterprise">
        Need more Dynamic QR Codes, API requests, account users or bulk creation volume?
      </v-card-subtitle>

      <v-card-actions class="pa-0 justify-center mt-7">
        <v-btn depressed rounded :ripple="false" large color="primary" :href="returnMailTo" target="_blank">
          Contact sales
        </v-btn>
      </v-card-actions>

      <v-card-text v-if="false" class="pb-2 pt-sm-0">
        <v-row>
          <v-col sm="12">
            <div class="pricing-plan__title-wrapper">
              <!-- Plan title -->

              <!-- Plan title END -->
            </div>

            <!-- Plan billing -->
            <v-row>
              <v-col xs="12" class="pricing-plan__billing-info">
                Need more Dynamic QR Codes, API requests, account users or bulk creation volume?
              </v-col>
            </v-row>
            <!-- Plan billing END -->

            <!-- Select button desktop-->
            <v-row align="center" justify="center" class="pricing-plan__select-btn--desktop">
              <v-col xs="12" md="8" lg="8" align="center" justify="center" class="pt-4 pb-0">
                <v-btn depressed rounded :ripple="false" large color="primary" :href="returnMailTo" target="_blank">
                  Contact sales
                </v-btn>
              </v-col>
            </v-row>
            <!-- Select button desktop END -->
          </v-col>
        </v-row>

        <!-- Select button desktop -->
        <v-row align="center" justify="center" class="pricing-plan__select-btn--mobile">
          <hr class="pricing-plan__fullwidth-border pricing-plan__fullwidth-border--mobile" />
          <v-col cols="12" align="center" justify="center" class="pt-4 pb-2">
            <v-btn depressed rounded :ripple="false" large color="primary" :href="returnMailTo" target="_blank">
              Contact sales
            </v-btn>
          </v-col>
        </v-row>
        <!-- Select button desktop END -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import Authenticator from '@/Services/Auth/Authenticator';
import { User } from '@/classes/auth';

@Component({})
export default class EnterprisePlan extends Vue {
  user: User = Authenticator.getUser();
  get returnMailTo(): string {
    return this.mailTo({
      emails: 'support@qr-code-generator.com',
      subject: `Enterprise Plan request (Customer ID: app-u-${this.user.id})`,
    });
  }

  mailTo({ emails, subject, body }: { emails: string; subject?: string; body?: string }): string {
    let mail = `mailto:${emails}`;
    if (!emails) {
      console.warn('mailTo is missing an email.');
    }
    if (subject) {
      mail += `?subject=${encodeURIComponent(subject)}`;
    }
    if (body) {
      mail += `${!subject ? '?' : '&'}body=${encodeURIComponent(body)}`;
    }
    return mail;
  }
}
</script>

<style lang="scss">
.pricing-plan {
  &__card {
    border-width: 2px !important;

    &-wrapper {
      height: 100%;
      position: relative;

      @media (max-width: 959px) {
        padding-top: 24px;
      }
    }

    &--enterprise {
      height: auto !important;
      padding: $spacer * 10 $spacer * 9 $spacer * 17;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        padding-bottom: $spacer * 14;
      }

      > * {
        position: relative;
        z-index: 2;
      }

      &:after {
        content: '';
        background: url('../../assets/illustrations/bg_image_enterprise.svg') no-repeat center;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0.5;
      }
    }
  }

  &__title {
    text-align: center;
    justify-content: center;

    @media (min-width: 600px) and (max-width: 959px) {
      padding-top: 0;
    }
  }

  &__subtitle {
    &--enterprise {
      text-align: center;
      padding: 0 $spacer * 50;
      margin-top: $spacer * 2 !important;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        padding: 0;
      }
    }
  }

  &__billing-info {
    padding: 0 12px 8px;
    text-align: center;
    color: map-get($lapis, 'lighten-2');
  }

  &__fullwidth-border {
    border-top: 1px solid #e6edf0;
    width: calc(100% + 9px);
    margin-bottom: 8px;
    margin-left: -5px;

    @media (min-width: 600px) and (max-width: 959px) {
      margin-bottom: 4px;
    }

    &--mobile {
      display: none;
      @media (min-width: 600px) and (max-width: 959px) {
        display: block;
        margin-top: 4px;
        margin-bottom: 0;
      }
    }
  }

  &__select-btn--desktop {
    @media (min-width: 600px) and (max-width: 959px) {
      display: none;
    }
  }

  &__select-btn--mobile {
    display: none;
    @media (min-width: 600px) and (max-width: 959px) {
      display: flex;
    }
  }
}
</style>
