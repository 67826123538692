import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from 'vee-validate/dist/locale/de.json';
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import it from 'vee-validate/dist/locale/it.json';
import pt from 'vee-validate/dist/locale/pt_BR.json';
import fr from 'vee-validate/dist/locale/fr.json';
import deAttributes from '@/locales/attributes/de.json';
import enAttributes from '@/locales/attributes/en.json';
import esAttributes from '@/locales/attributes/es.json';
import itAttributes from '@/locales/attributes/it.json';
import ptAttributes from '@/locales/attributes/pt.json';
import frAttributes from '@/locales/attributes/fr.json';
import deGeneral from '@/locales/de.json';
import enGeneral from '@/locales/en.json';
import { configure } from 'vee-validate';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE,
  // silentTranslationWarn: process.env.NODE_ENV === 'production',
  silentTranslationWarn: true,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: {
    de: {
      general: deGeneral,
      fields: deAttributes,
      validation: de.messages,
    },
    en: {
      general: enGeneral,
      fields: enAttributes,
      validation: en.messages,
    },
    es: {
      fields: esAttributes,
      validation: es.messages,
    },
    it: {
      fields: itAttributes,
      validation: it.messages,
    },
    pt: {
      fields: ptAttributes,
      validation: pt.messages,
    },
    fr: {
      fields: frAttributes,
      validation: fr.messages,
    },
  },
});

configure({
  // @ts-ignore
  defaultMessage: (field, values) => {
    // @ts-ignore
    values._field_ = i18n.t(`fields.${field}`);

    // @ts-ignore
    return i18n.t(`validation.${values._rule_}`, values);
  },
});

export { i18n };
