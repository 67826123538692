<template>
  <ValidationProvider ref="checkout.customer.address.country" v-slot="{ errors }" name="user.country" rules="required">
    <v-autocomplete
      class="text-field country-autocomplete"
      :value="userCountry"
      :items="countryList"
      outlined
      hide-details
      hide-no-data
      dense
      item-text="country"
      item-value="country_iso2"
      :label="$t('fields.user.country')"
      :error-messages="errors[0]"
      attach=".country-autocomplete"
      :menu-props="{
        contentClass: 'menu--select',
        bottom: true,
        offsetY: true,
        nudgeBottom: 0,
      }"
      @change="$emit('countrySelected', $event)"
    />
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    ValidationProvider,
  },
})
export default class CountryDropdown extends Vue {
  @Prop()
  countryList?: Array<any>;

  @Prop()
  userCountry?: string;
}
</script>

<style lang="scss" scoped>
.country-autocomplete {
  .v-list-item__mask {
    font-weight: 700;
    color: inherit;
    background: inherit;
  }
}
</style>
