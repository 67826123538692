/**
 * PaymentMethod model contains all the needed data for a PaymentMethod.
 * A user can have multiple payment methods and one as a default.
 */
export default class FeedbackModel {
  tag = 'cancelation';
  message = '';

  deserialize(instanceData: FeedbackModel): FeedbackModel {
    if (instanceData == null || typeof instanceData == 'undefined') {
      return this;
    }

    Object.assign(this, instanceData);
    return this;
  }
}
