import AppConfig from '@/AppConfig';
import Authenticator from '@/Services/Auth/Authenticator';
import { MiddlewareInterface } from '@/middleware/middlewareInterface';

const allowedPaths: { [key: string]: string[] } = {
  VUE_APP_FEATURE_CHECKOUT: [
    '/features-and-pricing',
    '/my-account/billing',
    '/cancel-subscription',
    '/complete-with-discount',
    '/processing',
  ],
};

const redirects: { [key: string]: string } = {
  '/create/': '/create/new/',
  '/my-account': '/account/',
  '/my-account/api-keys': '/api/',
  '/my-account/white-label': '/whitelabel/',
  '/my-account/user-management': '/usermanagement/',
  '/all-codes': '/manage',
};

export default function globalMiddleware({ next, to }: MiddlewareInterface): boolean | undefined {
  const active = Object.keys(allowedPaths).some((feature) => {
    // TODO: figure out this loops here and reinvent the wheel
    return process.env[feature] === 'true';
  });

  if (!Authenticator.isAuthenticated()) {
    window.location.href = `${AppConfig.getAuthServiceUrl()}/?redirect=${window.location.href}`;

    return;
  }

  // If no feature is active, we skip the redirection process
  if (!active) {
    next();
    return;
  }

  // Go through all features
  for (const feature in allowedPaths) {
    // Check if the specific feature from allowedPaths is active
    const enabled = process.env[feature] === 'true';
    if (!enabled) {
      continue;
    }
    // Check if a feature allows this path
    const isAllowed = allowedPaths[feature].some((path) => to.path.indexOf(path) === 0);

    // If we found an allowed path, exit early and continue with rendering
    if (isAllowed) {
      next();
      return;
    }
  }

  // No feature allows this path, so we have to redirect the user to the old app
  const redirectPath = redirects[to.path] ?? '';
  window.location.href = AppConfig.getOldAppBaseUrl() + redirectPath;
  return false;
  // return next();
}
