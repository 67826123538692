<template>
  <div v-resize="onResizeNavigation">
    <AppBar />

    <v-main :class="drawer && isNavigationMobile ? 'sidebar--is-open' : 'sidebar--is-closed'">
      <v-snackbar
        top
        :value="$store.state.layout.snackbar.active && $store.state.layout.snackbar.isSnackbar"
        outlined
        :color="$store.state.layout.snackbar.type"
        rounded="16"
      >
        <Icon :name="$store.state.layout.snackbar.icon" left outline large :color="$store.state.layout.snackbar.type" />
        {{ $store.state.layout.snackbar.text }}
      </v-snackbar>
      <!-- GENERAL ALERT -->
      <v-container v-if="$store.state.layout.snackbar.active && !$store.state.layout.snackbar.isSnackbar">
        <v-row justify="center">
          <v-col cols="10">
            <Alert
              v-if="!$store.state.layout.snackbar.isSnackbar"
              dense
              outlined
              :type="$store.state.layout.snackbar.type"
              :icon="$store.state.layout.snackbar.icon"
              :title-class="$store.state.layout.snackbar.textClass"
              class="ma-0"
            >
              <template #title>
                {{ $store.state.layout.snackbar.text }}
              </template>

              <template #content>
                <v-btn
                  v-if="!$store.state.layout.snackbar.sticky"
                  text
                  :ripple="false"
                  color="primary"
                  small
                  class="v-btn--text--link ml-3"
                  @click="$store.dispatch('layout/setSnackbar', { active: false })"
                >
                  <!-- TODO: missing translation -->
                  <span class="navy--text"> Dismiss </span>
                </v-btn>
              </template>
            </Alert>
          </v-col>
        </v-row>
      </v-container>
      <!-- GENERAL ALERT END -->

      <!-- REFRESH SNACKBAR -->
      <v-snackbar v-model="$store.state.layout.refreshNotice" :timeout="-1" :bottom="true">
        A new version is available!
        <v-btn @click="refreshApplication()"> Refresh </v-btn>
      </v-snackbar>
      <!-- REFRESH SNACKBAR END -->

      <router-view />
      <!-- START: Global modals-->
      <support-modal v-if="supportModalShow" />
      <!-- END: Global modals-->
      <!-- START: Overlay-->
      <v-overlay :value="globalOverlay" color="neutral lighten-1" class="global-overlay" />
      <!-- END: Overlay-->
    </v-main>
  </div>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import AppBar from '@/components/Layout/AppBar.vue';
import Alert from '@/components/Alert.vue';
import store from '@/store';
import { mapActions, mapState } from 'vuex';
import { LayoutState } from '@/store/layout';
import Icon from '@/components/Icons/Icon.vue';
import { SupportModalMixin } from '@/mixins/SupportModalMixin';
import SupportModal from '@/components/Modals/Support/SupportModal.vue';

@Component({
  components: {
    SupportModal,
    Icon,
    AppBar,
    Alert,
  },
  computed: {
    ...mapState<LayoutState>('layout', {
      drawer: (state: LayoutState) => state.drawer,
      isNavigationMobile: (state: LayoutState) => state.isNavigationMobile,
      supportModalShow: (state: LayoutState) => state.supportModalShow,
      globalOverlay: (state: LayoutState) => state.globalOverlay,
    }),
  },
  methods: {
    ...mapActions('layout', ['setDrawerState', 'setNavigationMobileState']),
  },
})
export default class Mainframe extends SupportModalMixin {
  drawer!: boolean;
  isNavigationMobile!: boolean;
  setNavigationMobileState!: (isMobile: boolean) => any;

  windowSize: { width: number; height: number } = {
    width: 0,
    height: 0,
  };

  async refreshApplication(): Promise<void> {
    await store.dispatch('layout/setRefreshNotice', false);
    window.location.reload();
  }

  onResizeNavigation(): void {
    this.onResize();
    this.setNavigationMobileState(this.windowSize.width < 1264);
  }

  created(): void {
    // Check if modal is present in query and show it based on trigger
    if (this.$route.query.modal === 'support') {
      this.toggleSupportModal(true);
    }
  }

  onResize(isLogOn = false): void {
    this.windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    if (isLogOn) {
      console.log('Width = ', this.windowSize.width);
      console.log('Height = ', this.windowSize.height);
    }
  }
}
</script>

<style lang="scss">
.global-overlay {
  z-index: 0 !important;
}
</style>
