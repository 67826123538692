<template>
  <v-dialog
    :value="open"
    max-width="360"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    content-class="qr-dialog"
    scrollable
    @input="cancel"
  >
    <v-card rounded class="pa-6">
      <v-card-title class="px-0 pt-0">
        <h4 class="text-h4">
          {{ $t('fields.account.billing.email_edit.title') }}
        </h4>
      </v-card-title>

      <v-card-subtitle class="px-0 pt-3 pb-5">
        {{ $t('fields.account.billing.email_edit.desc') }}
      </v-card-subtitle>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <v-card-text class="px-0 pb-8 pt-2">
          <!-- Email -->

          <ValidationProvider
            ref="checkout.customer.email"
            v-slot="{ errors }"
            name="checkout.customer.email"
            rules="required|email"
          >
            <v-text-field
              v-model="email"
              outlined
              hide-details
              dense
              class="text-field"
              :label="$t('fields.user.email')"
              type="email"
              persistent-hint
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-card-text>

        <v-card-actions class="px-0 py-0">
          <v-spacer />
          <v-btn outlined large :ripple="false" rounded color="primary" @click="cancel">
            {{ $t('fields.account.billing.email_edit.btn_cancel') }}
          </v-btn>
          <v-btn
            depressed
            large
            :ripple="false"
            :loading="showLoading"
            rounded
            color="primary"
            class="ml-4"
            :disabled="invalid"
            @click="update"
          >
            {{ $t('fields.account.billing.email_edit.btn_update') }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import VeeValidateRefs from '@/Models/VeeValidateRefs';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import store from '@/store';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class InvoiceEmailEdit extends Vue {
  @Prop(Boolean)
  open!: boolean;

  $refs!: {
    observer: VeeValidateRefs;
  };

  email: string = store.state.auth.team.email;
  showLoading: boolean = false;

  async update(): Promise<void> {
    if (!(await this.$refs.observer.validate())) {
      return;
    }

    this.showLoading = true;

    const team = store.getters['auth/team'];
    await store.dispatch('auth/updateTeam', { ...team, email: this.email }).then(() => {
      this.$emit('done');
    });
    this.showLoading = false;
  }

  cancel(): void {
    this.email = store.getters['auth/team'].email;
    this.$emit('done');
  }
}
</script>
