import { RootState } from '@/store';
import { Module } from 'vuex';

interface Snackbar {
  text: string;
  textClass?: string;
  active: boolean;
  isSnackbar: boolean;
  type: string;
  icon: string;
  sticky?: boolean;
  showDismissButton: boolean;
  timeToDeactivate?: number;
}

export interface LayoutState {
  drawer: boolean;
  isLoading: boolean;
  isNavigationMobile: boolean;
  snackbar: Snackbar;
  refreshNotice: boolean;
  routeLoading: boolean;
  layoutInitialized: boolean;
  supportModalShow?: boolean;
  globalLoader?: boolean;
  globalOverlay?: boolean;
}

const LayoutStore: Module<LayoutState, RootState> = {
  namespaced: true,
  state: {
    drawer: false,
    isLoading: false,
    isNavigationMobile: false,
    snackbar: {
      text: '',
      active: false,
      isSnackbar: false,
      type: 'info',
      icon: 'info-system',
      sticky: false,
      showDismissButton: true,
      timeToDeactivate: 12000,
    },
    refreshNotice: false,
    routeLoading: false,
    layoutInitialized: false,
    supportModalShow: false,
    globalLoader: false,
    globalOverlay: false,
  },
  mutations: {
    setRouteLoading(state, isLoading: boolean) {
      state.routeLoading = isLoading;
    },
    setLayoutInitialized(state, isInitialized: boolean) {
      state.layoutInitialized = isInitialized;
    },
    drawerState(state, isOpen: boolean) {
      state.drawer = isOpen;
    },
    navigationMobileState(state, isMobile: boolean) {
      state.isNavigationMobile = isMobile;
    },
    loadingState(state, isLoading: boolean) {
      state.isLoading = isLoading;
    },
    snackbarActive(state, active: boolean) {
      state.snackbar.active = active;
    },
    snackbarOptions(state, options) {
      state.snackbar = { ...state.snackbar, ...options };
    },
    refreshNotice(state, isActive: boolean) {
      state.refreshNotice = isActive;
    },
    toggleSupportModal(state, toggle: boolean) {
      state.supportModalShow = toggle;
    },
    toggleGlobalLoader(state, toggle: boolean) {
      state.globalLoader = toggle;
    },
    toggleGlobalOverlay(state, toggle: boolean) {
      state.globalOverlay = toggle;
    },
  },
  actions: {
    setLoadingIndicator({ commit }, isLoading: boolean) {
      commit('loadingState', isLoading);
    },
    setDrawerState({ commit }, isOpen: boolean) {
      commit('drawerState', isOpen);
    },
    setNavigationMobileState({ commit }, isMobile: boolean) {
      commit('navigationMobileState', isMobile);
    },
    setSnackbar({ commit, state }, options: Snackbar) {
      commit('snackbarOptions', options);
      if (!options.sticky) {
        setTimeout(() => {
          commit('snackbarActive', false);
        }, state.snackbar.timeToDeactivate);
      }
    },
    setRefreshNotice({ commit }, isOpen: boolean) {
      commit('refreshNotice', isOpen);
    },
  },
};

export default LayoutStore;
