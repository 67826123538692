<template>
  <v-row justify="center">
    <v-col md="11">
      <h4 class="text-h4 checkout__step-header">
        {{ $t('fields.checkout.headline_step_2') }}
      </h4>
      <div class="checkout__step-description">
        <div class="mb-4">
          <Icon name="lock-system" outline />
          {{ $t('fields.checkout.sub_headline_step_2') }}
        </div>
      </div>
      <!-- CREDIT CARD -->
      <div v-if="stripePaymentMethodService.paymentType === 'card'" class="checkout__stripe-inputs">
        <v-row :class="!stripePaymentMethodService.isPaymentFormLoaded ? 'stripe-input__form-hidden' : null">
          <v-col cols="12" sm="8" class="credit-card-wrapper pr-0">
            <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
              <div id="cardNumber" ref="CreditCardNumber" />
              <span id="testCardNumber" class="stripe-input__label-text">{{
                $t('fields.checkout.form.credit_card_number')
              }}</span>
              <span v-if="formErrorMessageCreditCardNumber" class="stripe-input__label-text input_validation">{{
                $t('fields.error.invalid_credit_card_number')
              }}</span>
            </div>
          </v-col>

          <v-col cols="12" sm="4" class="credit-card-wrapper pr-0">
            <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
              <div id="cardExpiry" ref="CreditCardDate" />
              <span class="stripe-input__label-text">{{ $t('fields.checkout.form.credit_card_expiration') }}</span>
              <span v-if="formErrorMessageCreditCardDate" class="stripe-input__label-text input_validation">{{
                $t('fields.error.invalid_expiration_credit_card_date')
              }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row :class="!stripePaymentMethodService.isPaymentFormLoaded ? 'stripe-input__form-hidden' : null">
          <v-col cols="12" sm="6" class="pb-8 pb-sm-2">
            <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
              <div id="cardCvc" ref="CreditCardCvcNumber" />
              <span class="stripe-input__label-text">{{ $t('fields.checkout.form.credit_card_cvc') }}</span>
              <span
                v-if="formErrorMessageCreditCardCvcNumber"
                class="stripe-input__label-text input_validation stripe-input-hint"
                >{{ $t('fields.error.invalid_credit_card_cvc_number') }}</span
              >
              <v-btn
                text
                :ripple="false"
                color="primary"
                small
                class="stripe-input__sub-button"
                tabindex="-1"
                @click.stop="openFindVatModal = true"
              >
                <span class="navy--text">{{ $t('fields.checkout.form.cvc_hint.button_text') }}</span>
              </v-btn>

              <v-dialog v-model="openFindVatModal" max-width="572" overlay-color="neutral lighten-1">
                <v-card class="pt-6 pb-8">
                  <v-card-title class="d-flex justify-center">
                    <img src="../../assets/illustrations/modal_cvv_illustration.png" class="cvv-modal__image" alt="" />

                    <h4 class="text-h4 text-center">
                      {{ $t('fields.checkout.form.cvc_hint.header') }}
                    </h4>
                  </v-card-title>

                  <v-card-text class="pb-0">
                    <p class="lapis--text text--lighten-1 text-center">
                      {{ $t('fields.checkout.form.cvc_hint.description') }}
                    </p>
                  </v-card-text>

                  <v-card-actions class="mt-8 d-flex justify-center">
                    <v-btn depressed rounded :ripple="false" color="primary" large @click="openFindVatModal = false">
                      {{ $t('fields.checkout.form.cvc_hint.close') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>

          <v-col cols="12" sm="6" class="pb-4 d-flex align-center">
            <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
              <div id="postalCode" ref="CreditCardPostalCode" class="mr-2" />
              <span class="stripe-input__label-text">{{ $t('fields.checkout.form.credit_card_zip') }}</span>
              <span v-if="formErrorMessageCreditCardPostalCode" class="stripe-input__label-text input_validation">{{
                $t('fields.error.invalid_credit_card_zipcode')
              }}</span>
            </div>
            <HelpTooltip>
              <template #text>
                {{ $t('fields.checkout.form.credit_card_zip_hint') }}
              </template>
            </HelpTooltip>
          </v-col>
        </v-row>

        <v-row v-if="!stripePaymentMethodService.isPaymentFormLoaded">
          <v-col cols="8">
            <v-skeleton-loader loading type="text" />
          </v-col>
          <v-col cols="4">
            <v-skeleton-loader loading type="text" />
          </v-col>
        </v-row>
        <v-row v-if="!stripePaymentMethodService.isPaymentFormLoaded">
          <v-col cols="6">
            <v-skeleton-loader loading type="text" />
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader loading type="text" />
          </v-col>
        </v-row>
      </div>
      <!-- CREDIT CARD END -->
      <!-- INVOICE -->
      <div v-if="stripePaymentMethodService.paymentType === 'sepa_debit'" class="checkout__stripe-inputs">
        <v-row :class="!stripePaymentMethodService.isPaymentFormLoaded ? 'stripe-input__form-hidden' : null">
          <v-col cols="6">
            <ValidationProvider
              ref="checkout.customer.iban.first_name"
              v-slot="{ errors }"
              name="checkout.customer.iban.first_name"
              rules="required|max:191"
            >
              <v-text-field
                v-model="stripePaymentMethodService.ibanData.first_name"
                outlined
                hide-details
                dense
                class="text-field"
                :label="$t('fields.user.firstname')"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="6">
            <ValidationProvider
              ref="checkout.customer.iban.last_name"
              v-slot="{ errors }"
              name="checkout.customer.iban.last_name"
              rules="required|max:191"
            >
              <v-text-field
                v-model="stripePaymentMethodService.ibanData.last_name"
                outlined
                hide-details
                dense
                class="text-field"
                :label="$t('fields.user.lastname')"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" class="py-0 pb-4">
            <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
              <div id="iban" />
              <span class="stripe-input__label-text">{{ $t('fields.checkout.form.bank_transfer_iban') }}</span>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="!stripePaymentMethodService.isPaymentFormLoaded">
          <v-col cols="6">
            <v-skeleton-loader loading type="text" />
          </v-col>
          <v-col cols="6">
            <v-skeleton-loader loading type="text" />
          </v-col>
        </v-row>
        <v-row v-if="!stripePaymentMethodService.isPaymentFormLoaded">
          <v-col cols="12">
            <v-skeleton-loader loading type="text" />
          </v-col>
        </v-row>

        <v-row
          v-if="
            stripePaymentMethodService.isPaymentFormLoaded && stripePaymentMethodService.paymentType === 'sepa_debit'
          "
        >
          <v-col cols="12">
            <p
              class="sepa__mandate lapis--text text--lighten-2 mb-0"
              v-text="$t('fields.checkout.form.sepa_mandate_text')"
            />
          </v-col>
        </v-row>
      </div>
      <!-- INVOICE END -->
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { StripePaymentMethodService } from '@/Services/Subscription/StripePaymentMethodService';
import HelpTooltip from '@/components/HelpTooltip.vue';
import Icon from '@/components/Icons/Icon.vue';
import { ValidationProvider } from 'vee-validate';

@Component({
  components: {
    HelpTooltip,
    Icon,
    ValidationProvider,
  },
})
export default class StripePaymentInformation extends Vue {
  @Prop({ type: StripePaymentMethodService })
  stripePaymentMethodService!: StripePaymentMethodService;

  @Prop({ type: Boolean, default: false })
  formStripeErrorMessage!: boolean;

  openFindVatModal = false;

  formErrorMessageCreditCardNumber: boolean = false;
  formErrorMessageCreditCardDate: boolean = false;
  formErrorMessageCreditCardCvcNumber: boolean = false;
  formErrorMessageCreditCardPostalCode: boolean = false;

  $refs!: {
    // Used for validation of the form data e.g. customer, address etc.
    CreditCardCvcNumber: any;
    CreditCardNumber: any;
    CreditCardDate: any;
    CreditCardPostalCode: any;
  };

  /**
   * If type changes due to clicking on payment selection trigger a change
   * of the payment method form.
   *
   * @param newVal
   */
  @Watch('stripePaymentMethodService.paymentType', { deep: true, immediate: false })
  async onPaymentMethodUpdate(newVal: string): Promise<void> {
    this.formErrorMessageCreditCardNumber = false;
    this.formErrorMessageCreditCardDate = false;
    this.formErrorMessageCreditCardCvcNumber = false;
    this.formErrorMessageCreditCardPostalCode = false;
    this.formStripeErrorMessage = false;
    await this.rebuildStripeForm(newVal);
  }

  async mounted(): Promise<void> {
    // Mount the payment form, we do this here because refs is not available in created()!
    await this.rebuildStripeForm(this.stripePaymentMethodService.paymentType);
  }

  // Rebuild stripe elements form an exception is send_invoice which doesn't need stripe elements
  async rebuildStripeForm(paymentMethodType: string): Promise<void> {
    // Wait for the next tick to ensure the form is loaded and the custom elements could be injected
    await this.$nextTick();
    switch (paymentMethodType) {
      case 'card':
        this.stripePaymentMethodService.initCardForm();
        break;
      case 'sepa_debit':
        this.stripePaymentMethodService.initSEPAForm();
        break;
    }
  }

  hasClass(): void {
    const elementCreditCardNumber = this.$refs.CreditCardNumber;
    const elementCreditCardDate = this.$refs.CreditCardDate;
    const elementCreditCardCvcNumber = this.$refs.CreditCardCvcNumber;
    const elementCreditCardPostalCode = this.$refs.CreditCardPostalCode;

    this.formErrorMessageCreditCardNumber =
      elementCreditCardNumber.classList.contains('StripeElement--empty') ||
      elementCreditCardNumber.classList.contains('StripeElement--invalid');

    this.formErrorMessageCreditCardDate =
      elementCreditCardDate.classList.contains('StripeElement--empty') ||
      elementCreditCardDate.classList.contains('StripeElement--invalid');

    this.formErrorMessageCreditCardCvcNumber =
      elementCreditCardCvcNumber.classList.contains('StripeElement--empty') ||
      elementCreditCardCvcNumber.classList.contains('StripeElement--invalid');

    this.formErrorMessageCreditCardPostalCode =
      elementCreditCardPostalCode.classList.contains('StripeElement--empty') ||
      elementCreditCardPostalCode.classList.contains('StripeElement--invalid');
  }
}
</script>
