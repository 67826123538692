<template>
  <div id="preloading">
    <div class="qr-loader-wrapper">
      <div class="qr-loader"></div>
      <div class="qr-loader-content">
        <h4>Hold on</h4>
        <p :class="{ 'hide-processing-payment-text': isPaymentMethodUpdated }">
          Thank you, your payment is being processed.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppConfig from '@/AppConfig';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Processing extends Vue {
  isReturningFromPaymentForm = this.$route.query.checkoutType || null;
  isPaymentMethodUpdated = this.$route.query.returningFromPaypal || null;
  isPaymentTypeWallet = this.$route.query.paymentType === 'wallet' || false;
  incompleteSubscription: any;
  incompletePaymentMethodTransition: any;
  userSubscription: any;

  async created(): Promise<void> {
    const selectedPlanId = this.$route.query.planId as string;
    if (this.isReturningFromPaymentForm && (this.isRedirectSuccessful() || this.isPaymentTypeWallet)) {
      await this.getUserSubscription(selectedPlanId);
      //check status of the subscription and depending on the status, redirect to the appropriate page
      if (this.userSubscription && this.userSubscription.status === 'active') {
        // Handle successful payment here
        await this.subscriptionComplete();
      } else {
        const planName = this.$route.query.plan as string;
        // Handle unsuccessful, processing, or canceled payments and API errors here
        if (planName) {
          await this.$router.push({
            name: 'checkout_payment_form_preselect_plan_name',
            params: { planName },
          });
        } else {
          await this.$router.push({ path: '/features-and-pricing' });
        }
      }
    }

    if (this.isRedirectSuccessful() && this.isPaymentMethodUpdated) {
      await this.paymentMethodTransitionComplete();
    }

    if (this.isPaymentMethodUpdated) {
      await this.redirectToBilling();
    } else if (this.isReturningFromPaymentForm) {
      await this.redirectToPaymentComplete();
    } else {
      await this.$router.push({ path: '/features-and-pricing' });
    }
  }

  sleep = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  async performWithRetry(
    syncMethod: () => Promise<void>,
    condition: () => boolean,
    waitMultiplier: number
  ): Promise<void> {
    const retryAttempts: number = 5;
    let retry: number = 0;

    await syncMethod();

    do {
      if (retry > 0) {
        await this.sleep(retry * waitMultiplier);
        await syncMethod();
      }

      if (condition()) {
        break;
      }

      retry++;
    } while (retry < retryAttempts);
  }

  async subscriptionComplete(): Promise<void> {
    await this.performWithRetry(
      () => this.syncIncompleteSubscription(),
      () => !this.incompleteSubscription,
      2000
    );
  }

  async paymentMethodTransitionComplete(): Promise<void> {
    await this.performWithRetry(
      () => this.syncPaymentMethodTransition(),
      () => !this.incompletePaymentMethodTransition,
      1000
    );
  }

  async getUserSubscription(planId: string): Promise<void> {
    await this.performWithRetry(
      () => this.syncUserSubscription(planId),
      () => !this.userSubscription,
      500
    );
  }

  async syncIncompleteSubscription(): Promise<void> {
    await axios
      .get(
        AppConfig.getAPIBaseUrl() +
          `/team/incomplete-subscription/get_by_setup_intent_id/${this.$route.query.setup_intent}`
      )
      .then(async (response) => {
        this.incompleteSubscription = response.data;
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
      });
  }

  async syncPaymentMethodTransition(): Promise<void> {
    await axios
      .get(AppConfig.getAPIBaseUrl() + '/team/payment-method-transition/exists')
      .then(async (response) => {
        this.incompletePaymentMethodTransition = response.data;
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
      });
  }

  async syncUserSubscription(planId: string): Promise<void> {
    await axios
      .get(AppConfig.getAPIBaseUrl() + `/subscription/stripe/${planId}`)
      .then(async (response) => {
        this.userSubscription = response.data;
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
      });
  }

  isRedirectSuccessful(): boolean | string {
    return this.$route.query.redirect_status && this.$route.query.redirect_status === 'succeeded';
  }

  async redirectToBilling(): Promise<void> {
    await this.$router.push({ path: '/my-account/billing', query: this.$route.query });
  }

  async redirectToPaymentComplete(): Promise<void> {
    const routerQuery = { ...this.$route.query };
    delete routerQuery.checkoutType;

    await this.$router.push({
      path: `/features-and-pricing/complete/${this.$route.query.checkoutType}`,
      query: routerQuery,
    });
  }
}
</script>

<style lang="scss" scoped>
h4 {
  color: #1b294b;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.26px;
}
p {
  color: #53678c;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.16px;
  white-space: nowrap;
}

.hide-processing-payment-text {
  visibility: hidden;
}

.qr-loader-wrapper {
  position: relative;
}
.qr-loader {
  margin: 0 auto;
}

.qr-loader-content {
  position: absolute;
  top: calc(100% + 12px);
  left: -103%;
}
</style>
