<template>
  <v-dialog
    :value="open"
    max-width="460"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    content-class="qr-dialog"
    scrollable
    @input="cancel"
  >
    <v-card id="checkout" rounded class="pa-6" data-test="payment-update-modal">
      <!-- Title -->
      <v-card-title class="px-0 pt-0 pb-1">
        <h4 v-show="step < 3" class="text-h4">
          {{ currentTitle }}
        </h4>
        <v-spacer />
        <v-chip label small class="chip--badge lapis--text text--lighten-1">
          {{ step }}
        </v-chip>
      </v-card-title>
      <ValidationObserver ref="observer" slim>
        <v-stepper v-model="step" elevation="0">
          <v-stepper-items>
            <!-- STEP 1 -->
            <v-stepper-content class="pa-0" step="1">
              <!-- Subtitle description -->
              <v-card-subtitle class="px-0 pt-0 pb-4">
                {{ $t('fields.account.billing.payment_edit.desc') }}
              </v-card-subtitle>

              <v-card-text class="pa-1">
                <v-radio-group v-model="stripePaymentMethodService.paymentType" class="radio-selection">
                  <v-radio
                    v-if="stripePaymentMethodService.paymentMethodsToShow.includes('card')"
                    value="card"
                    color="navy"
                    class="radio-selection__type"
                    data-test="qr-payment-method"
                    :disabled="!stripePaymentMethodService.isPaymentFormLoaded"
                    @click="trackOptimizelyEventForPymentMethodSelection()"
                  >
                    <template #label>
                      <div class="d-inline-flex">
                        {{ $t('fields.checkout.payment_methods.card') }}
                      </div>
                      <div class="d-inline-flex">
                        <img class="ml-3" height="38" src="../../../assets/images/payment-icons/pay-amex.svg" alt="" />
                        <img
                          class="ml-3"
                          height="38"
                          src="../../../assets/images/payment-icons/pay-mastercard.svg"
                          alt=""
                        />
                        <img class="ml-3" height="38" src="../../../assets/images/payment-icons/pay-visa.svg" alt="" />
                      </div>
                    </template>
                  </v-radio>

                  <v-radio
                    v-if="stripePaymentMethodService.paymentMethodsToShow.includes('send_invoice')"
                    value="send_invoice"
                    color="navy"
                    class="radio-selection__type"
                    data-test="qr-payment-method"
                    :disabled="!stripePaymentMethodService.isPaymentFormLoaded"
                    @click="trackOptimizelyEventForPymentMethodSelection()"
                  >
                    <template #label>
                      <div class="d-inline-flex">
                        {{ $t('fields.checkout.payment_methods.send_invoice') }}
                      </div>
                      <div class="d-inline-flex">
                        <img
                          class="ml-3"
                          height="40"
                          src="../../../assets/images/payment-icons/pay-direct-debit.svg"
                          alt=""
                        />
                      </div>
                    </template>
                  </v-radio>

                  <v-radio
                    v-if="enableDirectDebit && stripePaymentMethodService.paymentMethodsToShow.includes('sepa_debit')"
                    value="sepa_debit"
                    color="navy"
                    class="radio-selection__type"
                    data-test="qr-payment-method"
                    @click="trackOptimizelyEventForPymentMethodSelection()"
                  >
                    <template #label>
                      <div class="d-inline-flex">{{ $t('fields.checkout.payment_methods.sepa_debit') }}</div>
                      <div class="d-inline-flex">
                        <img class="ml-3" height="40" src="../../../assets/images/payment-icons/pay-sepa.svg" alt="" />
                      </div>
                    </template>
                  </v-radio>

                  <v-radio
                    v-if="canShowPaypal"
                    value="paypal"
                    color="navy"
                    class="radio-selection__type"
                    data-test="qr-payment-method"
                    :disabled="!stripePaymentMethodService.isPaymentFormLoaded"
                    @click="trackOptimizelyEventForPymentMethodSelection()"
                  >
                    <template #label>
                      <div class="d-inline-flex">
                        {{ $t('fields.checkout.payment_methods.paypal') }}
                      </div>
                      <div class="d-inline-flex">
                        <img
                          class="ml-3"
                          height="40"
                          src="../../../assets/images/payment-icons/pay-paypal.svg"
                          alt=""
                        />
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card-text>

              <!-- Actions -->
              <v-card-actions class="px-0 py-0 mt-5">
                <v-progress-circular
                  v-if="!stripePaymentMethodService.isPaymentFormLoaded"
                  indeterminate
                  :width="3"
                  size="20"
                  color="candy"
                />
                <v-spacer />
                <v-btn
                  outlined
                  large
                  :ripple="false"
                  rounded
                  color="primary"
                  data-test="payment-update-button-cancel"
                  @click="cancel"
                >
                  {{ $t('fields.account.billing.payment_edit.btn_outline') }}
                </v-btn>
                <v-btn
                  depressed
                  large
                  :ripple="false"
                  rounded
                  color="primary"
                  class="ml-4"
                  :loading="stripePaymentMethodService.paymentType === 'send_invoice' && isSaving"
                  :disabled="!stripePaymentMethodService.isPaymentFormLoaded || !isSubmitPossible()"
                  data-test="payment-update-button"
                  @click="continueStep"
                >
                  {{
                    stripePaymentMethodService.paymentType === 'send_invoice'
                      ? $t('fields.account.billing.payment_edit.btn_primary_update')
                      : $t('fields.account.billing.payment_edit.btn_primary')
                  }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
            <!-- STEP 1 END -->

            <!-- STEP 2 -->
            <v-stepper-content class="pa-0" step="2">
              <!-- Subtitle description -->
              <v-card-subtitle class="px-0 pt-0 pb-4">
                <Icon name="lock-system" outline />
                {{ $t('fields.checkout.sub_headline_step_2') }}
                <img
                  v-if="stripePaymentMethodService.paymentType === 'paypal'"
                  class="float-right"
                  height="24"
                  src="../../../assets/images/payment-icons/powered_by_stripe.svg"
                  alt=""
                />
              </v-card-subtitle>

              <v-card-text v-if="stripePaymentMethodService.paymentType === 'paypal'" class="pa-0 mt-5 pb-4">
                {{ $t('fields.checkout.sub_headline_step_2_additional') }}
              </v-card-text>

              <v-card-text class="pa-0">
                <!-- CREDIT CARD -->
                <div v-if="stripePaymentMethodService.paymentType === 'card'" class="checkout__stripe-inputs">
                  <v-row :class="!stripePaymentMethodService.isPaymentFormLoaded ? 'stripe-input__form-hidden' : null">
                    <v-col cols="12" class="py-0 pb-4">
                      <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
                        <div id="cardNumber" />
                        <span class="stripe-input__label-text">
                          {{ $t('fields.checkout.form.credit_card_number') }}
                        </span>
                      </div>
                    </v-col>

                    <v-col cols="12" class="py-0 pb-4">
                      <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
                        <div id="cardExpiry" />
                        <span class="stripe-input__label-text">
                          {{ $t('fields.checkout.form.credit_card_expiration') }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row :class="!stripePaymentMethodService.isPaymentFormLoaded ? 'stripe-input__form-hidden' : null">
                    <v-col cols="12" class="credit-card-wrapper">
                      <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
                        <div id="cardCvc" />
                        <span class="stripe-input__label-text">
                          {{ $t('fields.checkout.form.credit_card_cvc') }}
                        </span>

                        <v-btn
                          text
                          :ripple="false"
                          color="primary"
                          small
                          class="stripe-input__sub-button"
                          tabindex="-1"
                          @click.stop="openFindVatModal = true"
                        >
                          <span class="navy--text">
                            {{ $t('fields.checkout.form.cvc_hint.button_text') }}
                          </span>
                        </v-btn>

                        <v-dialog
                          v-model="openFindVatModal"
                          max-width="572"
                          overlay-color="neutral lighten-1"
                          data-test="find-vat-modal"
                        >
                          <v-card class="pt-6 pb-8">
                            <v-card-title class="d-flex justify-center">
                              <img
                                src="../../../assets/illustrations/modal_cvv_illustration.png"
                                class="cvv-modal__image"
                              />

                              <h4 class="text-h4 text-center">
                                {{ $t('fields.checkout.form.cvc_hint.header') }}
                              </h4>
                            </v-card-title>

                            <v-card-text class="pb-0">
                              <p class="lapis--text text--lighten-1 text-center">
                                {{ $t('fields.checkout.form.cvc_hint.description') }}
                              </p>
                            </v-card-text>

                            <v-card-actions class="mt-8 d-flex justify-center">
                              <v-btn
                                depressed
                                rounded
                                :ripple="false"
                                color="primary"
                                large
                                @click="openFindVatModal = false"
                              >
                                {{ $t('fields.checkout.form.cvc_hint.close') }}
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </v-col>

                    <v-col cols="12" class="py-0 pb-4 d-flex align-center mt-6">
                      <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
                        <div id="postalCode" class="mr-2" />
                        <span class="stripe-input__label-text">
                          {{ $t('fields.checkout.form.credit_card_zip') }}
                        </span>
                      </div>
                      <HelpTooltip>
                        <template #text>
                          {{ $t('fields.checkout.form.credit_card_zip_hint') }}
                        </template>
                      </HelpTooltip>
                    </v-col>
                  </v-row>

                  <v-row v-if="!stripePaymentMethodService.isPaymentFormLoaded">
                    <v-col cols="8">
                      <v-skeleton-loader loading type="text" />
                    </v-col>
                    <v-col cols="4">
                      <v-skeleton-loader loading type="text" />
                    </v-col>
                  </v-row>
                  <v-row v-if="!stripePaymentMethodService.isPaymentFormLoaded">
                    <v-col cols="6">
                      <v-skeleton-loader loading type="text" />
                    </v-col>
                    <v-col cols="6">
                      <v-skeleton-loader loading type="text" />
                    </v-col>
                  </v-row>
                </div>
                <!-- CREDIT CARD END -->

                <!-- INVOICE -->
                <div v-if="stripePaymentMethodService.paymentType === 'sepa_debit'" class="checkout__stripe-inputs">
                  <v-row :class="!stripePaymentMethodService.isPaymentFormLoaded ? 'stripe-input__form-hidden' : null">
                    <v-col cols="6">
                      <ValidationProvider
                        ref="checkout.customer.iban.first_name"
                        v-slot="{ errors }"
                        name="checkout.customer.iban.first_name"
                        rules="required|max:191"
                      >
                        <v-text-field
                          v-model="stripePaymentMethodService.ibanData.first_name"
                          outlined
                          hide-details
                          dense
                          class="text-field"
                          :label="$t('fields.user.firstname')"
                          :error-messages="errors[0]"
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="6">
                      <ValidationProvider
                        ref="checkout.customer.iban.last_name"
                        v-slot="{ errors }"
                        name="checkout.customer.iban.last_name"
                        rules="required|max:191"
                      >
                        <v-text-field
                          v-model="stripePaymentMethodService.ibanData.last_name"
                          outlined
                          hide-details
                          dense
                          class="text-field"
                          :label="$t('fields.user.lastname')"
                          :error-messages="errors[0]"
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" class="py-0 pb-4">
                      <div class="stripe-input__wrapper stripe-input__wrapper--with-placeholder">
                        <div id="iban" />
                        <span class="stripe-input__label-text">
                          {{ $t('fields.checkout.form.bank_transfer_iban') }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row v-if="!stripePaymentMethodService.isPaymentFormLoaded">
                    <v-col cols="6">
                      <v-skeleton-loader loading type="text" />
                    </v-col>
                    <v-col cols="6">
                      <v-skeleton-loader loading type="text" />
                    </v-col>
                  </v-row>
                  <v-row v-if="!stripePaymentMethodService.isPaymentFormLoaded">
                    <v-col cols="12">
                      <v-skeleton-loader loading type="text" />
                    </v-col>
                  </v-row>
                </div>
                <!-- INVOICE END -->
              </v-card-text>

              <!-- Actions -->
              <v-card-actions class="px-0 py-0 mt-3">
                <v-spacer />
                <v-btn
                  outlined
                  large
                  :ripple="false"
                  rounded
                  color="primary"
                  data-test="payment-update-button-back"
                  :disabled="!isSubmitPossible()"
                  @click="step--, trackOptimizelyEventForPaypalBackButton()"
                >
                  {{ $t('fields.account.billing.payment_edit.btn_outline_back') }}
                </v-btn>
                <v-btn
                  depressed
                  large
                  :ripple="false"
                  rounded
                  color="primary"
                  class="ml-4"
                  data-test="payment-update-button-confirm"
                  :loading="isSaving"
                  :disabled="!isSubmitPossible()"
                  @click="onPaymentMethodSave"
                >
                  {{
                    stripePaymentMethodService.paymentType === 'paypal'
                      ? $t('fields.account.billing.payment_edit.btn_primary_connect')
                      : $t('fields.account.billing.payment_edit.btn_primary_update')
                  }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
            <!-- STEP 2 END -->
          </v-stepper-items>
        </v-stepper>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import VeeValidateRefs from '@/Models/VeeValidateRefs';
import Authenticator from '@/Services/Auth/Authenticator';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import { User } from '@/classes/auth';
import Icon from '@/components/Icons/Icon.vue';
import store from '@/store';
import * as Sentry from '@sentry/vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { computed } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import OptimizelyService from '@/Services/OptimizelyService';
import Country from '@/Models/Static/Country';
import countries from '@/Models/Static/countries.json';

@Component({
  components: {
    HelpTooltip: () => import('@/components/HelpTooltip.vue'),
    Icon,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class PaymentMethodEdit extends Vue {
  @Prop(Boolean)
  open!: boolean;

  @Prop({ default: undefined })
  wizardStep: any;

  @Prop({ default: undefined })
  redirectStatus: any;

  // region DATA
  // mapState
  user: User = Authenticator.getUser();

  step = 1;
  openFindVatModal = false;
  // Prevents double click issues and shows state of saving
  isSaving = false;
  // Handles business logic and some of the form state
  stripeCheckoutService: StripeCheckoutService = new StripeCheckoutService();

  isPaymentFormError = false;

  enableDirectDebit: boolean = process.env.VUE_APP_FEATURE_SEPA === 'true'; // Switch to disable/enable direct debit

  $refs!: {
    // Used for validation of the form data<ValidationObserver slim ref="observer">
    observer: VeeValidateRefs;
  };
  // Handles payment method logic and form state
  stripePaymentMethodService = this.$store.state.subscription.stripePaymentMethodService;

  // region METHODS
  cancel(): void {
    this.$emit('cancel');
    OptimizelyService.trackEvent('payment_method_modal_closed_billing_page');
    setTimeout(() => {
      this.step = 1;
    }, 500);
  }

  // Rebuild stripe elements form an exception is send_invoice which doesn't need stripe elements
  async rebuildStripeForm(paymentMethodType: string): Promise<void> {
    // Wait for the next tick to ensure the form is loaded and the custom elements could be injected
    await this.$nextTick();
    switch (paymentMethodType) {
      case 'card':
        try {
          this.stripePaymentMethodService.initCardForm();
        } catch (e) {
          store.dispatch('layout/setSnackbar', {
            type: 'error',
            icon: 'forbidden-system',
            text: this.$t('fields.account.billing.payment_edit.rebuild_error'),
            isSnackbar: true,
            active: true,
          });
          this.isPaymentFormError = true;
        }
        // We do not have to manually trigger loader state since it will be triggered inside StripePaymentMethodService
        // this.stripePaymentMethodService.isPaymentFormLoaded = true;
        break;
      case 'sepa_debit':
        try {
          this.stripePaymentMethodService.initSEPAForm();
        } catch (e) {
          store.dispatch('layout/setSnackbar', {
            type: 'error',
            icon: 'forbidden-system',
            text: this.$t('fields.account.billing.payment_edit.rebuild_error'),
            isSnackbar: true,
            active: true,
          });
          this.isPaymentFormError = true;
        }

        // We do not have to manually trigger loader state since it will be triggered inside StripePaymentMethodService
        // this.stripePaymentMethodService.isPaymentFormLoaded = true;
        break;
    }
  }

  // Save the payment method as new default
  async submitPaymentMethod(): Promise<any> {
    await this.stripeCheckoutService.updateCustomerVatRate();

    return await this.stripePaymentMethodService.savePaymentMethod({
      legal_entity: store.state.auth.team.legal_entity,
      customer: this.stripeCheckoutService.customer,
    });
  }

  async onPaymentMethodSave(): Promise<void> {
    this.stripePaymentMethodService.setupIntent = this.stripePaymentMethodService.paymentType === 'paypal';
    if (this.stripePaymentMethodService.paymentType === 'paypal') {
      OptimizelyService.trackEvent('paypal_payment_method_modal_connect_billing_page');
    }
    if ((await this.$refs.observer.validate()) && this.isSubmitPossible()) {
      this.isSaving = true;
      const result = await this.submitPaymentMethod();
      if (result?.status === 200) {
        let team = store.state.auth.team;
        team.is_payment_method_invoice = this.stripePaymentMethodService.paymentType === 'send_invoice';
        store.dispatch('auth/updateTeam', team);
        this.$emit('update-payment-method');
        this.$emit('update-billing-address');
        this.$emit('cancel');
        this.isSaving = false;
        this.step = 1;

        await store.dispatch('layout/setSnackbar', {
          type: 'info',
          icon: 'info-system',
          text: this.$t('fields.account.billing.payment_edit.save_ok'),
          isSnackbar: true,
          active: true,
        });
      } else {
        const errorMessage = this.$t(
          'fields.checkout.form.stripe_errors.' + this.stripePaymentMethodService.stripeError
        ).toString();
        this.isSaving = false;
        // Fixme: result reported to Sentry either null or undefined
        Sentry.captureException(result, {
          user: {
            id: Authenticator.getUser().id,
            email: Authenticator.getUser().email,
            team_id: Authenticator.getTeam().id,
          },
          tags: {
            method: 'onPaymentMethodSave',
          },
          extra: {
            stripe_error: this.stripePaymentMethodService.stripeError,
            message: 'Stripe error on payment method save',
          },
        });

        await this.$store.dispatch('layout/setSnackbar', {
          type: 'error',
          icon: 'forbidden-system',
          text: errorMessage || this.$t('fields.checkout.form.invalid'),
          isSnackbar: true,
          active: true,
        });
      }
    }
  }

  // We fill the form for the customer to ease the pain of filling out
  handlePrefillData(): void {
    // Init customer data for prefilling the form
    this.stripeCheckoutService.initCustomerData();
  }

  // No saving process going on and stripe form is ready?
  isSubmitPossible(): boolean {
    return !this.isSaving && !this.isPaymentFormError;
  }

  continueStep(): void {
    this.stripePaymentMethodService.paymentType === 'send_invoice' ? this.onPaymentMethodSave() : (this.step = 2);
    OptimizelyService.trackEvent('payment_method_modal_continue_billing_page');
  }
  // endregion METHODS END

  // region COMPUTED METHODS
  get currentTitle(): string {
    switch (this.step) {
      case 1:
        return this.$t('fields.account.billing.payment_edit.title_one').toString();
      case 2:
        return this.stripePaymentMethodService.paymentType === 'paypal'
          ? this.$t('fields.account.billing.payment_edit.title_two_paypal').toString()
          : this.$t('fields.account.billing.payment_edit.title_two').toString();
    }
    return '';
  }
  // endregion COMPUTED METHODS EN

  trackOptimizelyEventForPymentMethodSelection(): void {
    if (this.stripePaymentMethodService.paymentType === 'card') {
      OptimizelyService.trackEvent('credit_card_selection_billing_page');
    }
    if (this.stripePaymentMethodService.paymentType === 'paypal') {
      OptimizelyService.trackEvent('paypal_selection_billing_page');
    }
    if (this.stripePaymentMethodService.paymentType === 'sepa_debit') {
      OptimizelyService.trackEvent('sepa_debit_selection_billing_page');
    }
    if (this.stripePaymentMethodService.paymentType === 'send_invoice') {
      OptimizelyService.trackEvent('send_invoice_selection_billing_page');
    }
  }

  trackOptimizelyEventForPaypalBackButton(): void {
    OptimizelyService.trackEvent('paypal_back_button_billing_page');
  }

  // region WATCHERS
  @Watch('open')
  async onOpenChange(open: boolean): Promise<void> {
    if (!open) {
      return;
    }
    await this.rebuildStripeForm(this.stripePaymentMethodService.paymentType);
  }

  /**
   * If type changes due to clicking on payment selection trigger a change
   * of the payment method form.
   *
   * @param newVal
   */
  @Watch('stripePaymentMethodService.paymentType', { deep: true, immediate: false })
  async onPaymentMethodUpdate(newVal: string): Promise<void> {
    if (!this.open) {
      return;
    }
    await this.rebuildStripeForm(newVal);
  }
  // endregion WATCHERS END

  // region HOOKS
  // Mount the payment form, we do this here because refs is not available in created()!
  // Setup the components needed data, like customer data.
  async created(): Promise<void> {
    this.handlePrefillData();
    await this.stripePaymentMethodService.getAllowedPaymentMethodsForUser(this.getUserCountryForPaymentMethods());

    if (this.wizardStep && parseInt(this.wizardStep) === 3) {
      this.open = true;
      this.wizardStep = undefined;
      this.$emit('update-billing-address');
      this.$emit('cancel');
      this.isSaving = false;

      if (this.redirectStatus !== 'failed') {
        await store.dispatch('layout/setSnackbar', {
          type: 'info',
          icon: 'info-system',
          text: this.$t('fields.account.billing.payment_edit.save_ok'),
          isSnackbar: true,
          active: true,
        });
      } else {
        await store.dispatch('layout/setSnackbar', {
          type: 'error',
          icon: 'forbidden-system',
          text: this.$t('fields.account.billing.payment_edit.save_error_paypal').toString(),
          isSnackbar: true,
          active: true,
        });
      }
    }
  }
  // endregion HOOKS END

  canShowPaypal = computed(() => {
    return this.stripePaymentMethodService?.paymentMethodsToShow.includes('paypal');
  });

  getUserCountryForPaymentMethods(): string {
    const team = Authenticator.getTeam();
    if (!team) {
      return '';
    }
    const countryFromRegistration = team.country_from_registration;
    let result: Country | undefined;
    if (!countryFromRegistration) {
      return '';
    }
    result = countries.find((item: Country) => item.country_iso2 === countryFromRegistration);
    return result ? result.country_iso2 : '';
  }
}
</script>

<style lang="scss"></style>
