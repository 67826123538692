export interface AppState {
  location: {
    history: string[];
  };
}

const AppStore = {
  namespaced: true,
  state: {
    location: {
      history: [],
    },
  },
  mutations: {
    LOCATION_HISTORY_ADD(state: AppState, path: string): void {
      state.location.history.push(path);
    },
    LOCATION_HISTORY_REMOVE(state: AppState): void {
      const currentHistory = state.location.history || [];
      if (currentHistory.length) {
        // First .pop will actually remove previous route
        let newHistory = currentHistory.pop();
        // Second .pop will remove current route since it will be added to history
        newHistory = currentHistory.pop();
        if (Array.isArray(newHistory)) {
          state.location.history = newHistory;
        }
      }
    },
  },
};

export default AppStore;
