import { TranslateResult } from 'vue-i18n';

export interface SnowplowEventData {
  action: string;
  category: string;
}

export type SnowplowUserContextData = {
  industry_id?: number | null;
  material_id?: number | null;
  tracking_consent_status: boolean;
  user_id?: string | null;
};

export class SnowplowUserContext {
  schema = 'iglu:com.egoditor/user/jsonschema/1-0-0';
  constructor(public data: SnowplowUserContextData) {}
}

export type SnowplowEventContextData = {
  call_to_action: string;
  event_intent?: string | null;
  impersonated_by?: string | null;
  product: string;
  trace_id?: string | null;
};

export class SnowplowEventContext {
  schema = 'iglu:com.egoditor/event/jsonschema/1-0-0';
  constructor(public data: SnowplowEventContextData) {}
}

export type SnowplowFrontendContextData = {
  element_id?: string | null;
  element_name?: string | null;
  element_type?: string | null;
  interaction_type?: string | null;
  language_code?: string | null;
  page_name: string;
  price_currency_displayed?: string | null;
  source_location?: string | null;
};

export class SnowplowFrontendContext {
  schema = 'iglu:com.egoditor/frontend/jsonschema/1-0-1';
  constructor(public data: SnowplowFrontendContextData) {}
}

export type SnowplowCancellationReasonIdentifier =
  | 'missing_features'
  | 'technical_problems'
  | 'bad_outcome'
  | 'not_using_enough'
  | 'project_finished'
  | 'too_expensive'
  | 'unsure_how_to_use'
  | 'other';

export type SnowplowCancellationContextData = {
  reason_identifier?: SnowplowCancellationReasonIdentifier | null;
  reason_text?: TranslateResult | null;
  frontend_validation_succeeded: boolean;
  ab_test?: string | null;
};

export class SnowplowCancellationContext {
  schema = 'iglu:com.egoditor/cancellation/jsonschema/1-0-1';
  constructor(public data: SnowplowCancellationContextData) {}
}

export type SnowplowAbTestContextData = {
  event_type: string;
  group: string;
  name: string;
};

export class SnowplowAbTestContext {
  schema = 'iglu:com.egoditor/ab_test/jsonschema/1-0-0';
  constructor(public data: SnowplowAbTestContextData) {}
}
