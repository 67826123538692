import { createInstance, type Client } from '@optimizely/optimizely-sdk';
import AppConfig from '@/AppConfig';

export function createOptimizelyFeatureFlags(): Client | null {
  return createInstance({
    sdkKey: AppConfig.getOptimizelySdkKey(),
    datafileOptions: {
      autoUpdate: false,
    },
  });
}

export const optimizelyFeatureFlags = createOptimizelyFeatureFlags();
