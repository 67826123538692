<template>
  <v-dialog
    :value="open"
    :persistent="isTeamUpdating"
    max-width="360"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    content-class="qr-dialog"
    scrollable
    @input="cancel"
  >
    <v-card rounded class="pa-6">
      <v-card-title class="px-0 pt-0">
        <h4 class="text-h4">
          {{ $t('fields.account.billing.vat_edit.title') }}
        </h4>
      </v-card-title>

      <v-card-subtitle class="px-0 pt-3 pb-5">
        {{ $t('fields.account.billing.vat_edit.desc') }}
      </v-card-subtitle>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <v-card-text class="px-0 pb-8 pt-2">
          <!-- VAT ID -->
          <ValidationProvider vid="iso_code" rules="">
            <v-text-field
              v-show="false"
              v-model="stripeCheckoutService.customer.address.country"
              outlined
              hide-details
              dense
              class="text-field"
              :label="$t('fields.checkout.form.vat_id')"
            />
          </ValidationProvider>
          <ValidationProvider
            ref="checkout.customer.tax_id_data.vat_id"
            v-slot="{ errors }"
            name="checkout.form.vat_id"
            rules="required|vat_number:@iso_code"
            mode="lazy"
          >
            <v-text-field
              v-model="stripeCheckoutService.customer.tax_id_data.value"
              outlined
              dense
              class="text-field"
              :label="$t('fields.checkout.form.vat_id')"
              persistent-hint
              :error-messages="errors[0]"
              @keydown.space.prevent
            />
          </ValidationProvider>
        </v-card-text>

        <v-card-actions class="px-0 py-0">
          <v-spacer />
          <v-btn outlined large :ripple="false" rounded color="primary" :disabled="isTeamUpdating" @click="cancel">
            {{ $t('fields.account.billing.vat_edit.btn_cancel') }}
          </v-btn>
          <v-btn
            depressed
            large
            :ripple="false"
            rounded
            color="primary"
            class="ml-4"
            :loading="isTeamUpdating"
            :disabled="isTeamUpdating || team.vat_id === stripeCheckoutService.customer.tax_id_data.value || invalid"
            @click="update"
          >
            {{ $t('fields.account.billing.vat_edit.btn_update') }}
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import VeeValidateRefs from '@/Models/VeeValidateRefs';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import store from '@/store';
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class VatIdEdit extends Vue {
  @Prop(Boolean)
  open!: boolean;

  @Prop()
  isTeamUpdating!: boolean;

  team = store.state.auth.team;

  $refs!: {
    // Used for validation of the form data e.g. customer, address etc.
    observer: VeeValidateRefs;
  };

  // Handles business logic and some of the form state
  stripeCheckoutService: StripeCheckoutService = new StripeCheckoutService();

  async update(): Promise<void> {
    if (await this.$refs.observer.validate()) {
      this.team.vat_id = this.stripeCheckoutService.customer.tax_id_data.value;
      await store.dispatch('auth/updateTeam', this.team).then(() => {
        this.$emit('cancel');
      });
    }
  }

  cancel(): void {
    this.$emit('cancel');
  }

  handlePrefillData(): void {
    // Init customer data for prefilling the form
    this.stripeCheckoutService.initCustomerData();
  }

  @Watch('open')
  onOpenChange(open: boolean): void {
    if (!open) {
      this.handlePrefillData();
      return;
    }
  }

  async created(): Promise<void> {
    this.handlePrefillData();
  }
}
</script>
