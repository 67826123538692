<template>
  <v-dialog
    :value="true"
    max-width="620"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    content-class="qr-dialog"
    scrollable
    persistent
  >
    <v-card rounded class="pa-8">
      <!-- Title -->
      <v-card-title class="px-0 pt-0 mt-4 text-center d-block">
        <h4 class="text-h3" v-text="$t('fields.account.billing.cancel_subs.discount_offer_modal.modal_title')" />
      </v-card-title>

      <v-img src="../../assets/illustrations/modal_account-cancelation.png" height="160" contain class="mb-6 mt-5" />

      <div v-if="!discountInfoLoading" class="discount-offer-modal-pricing">
        <v-row no-gutters>
          <v-col cols="12" class="text-center">
            <h4 class="text-h4" v-text="$t('fields.account.billing.cancel_subs.discount_offer_modal.pricing_title')" />
            <p
              class="mb-5 mt-2"
              v-html="$t('fields.account.billing.cancel_subs.discount_offer_modal.pricing_subtitle')"
            />
          </v-col>
          <v-col cols="12" class="discount-offer-modal-pricing__container">
            <span class="discount-offer-modal-pricing__strikethrough-price">
              {{ currencySymbol }}{{ activeSubscriptionPrice }}
            </span>
            <span class="discount-offer-modal-pricing__currency"> {{ currencySymbol }} </span>
            <span class="discount-offer-modal-pricing__price"> {{ discountedPrice }} </span>
            <span class="discount-offer-modal-pricing__billing">
              /{{ $t('fields.pricing_table.plan_price_per_month') }}
            </span>
          </v-col>
          <v-col cols="12" class="discount-offer-modal-pricing__billing-info">
            {{ $tc('fields.pricing_table.plan_billing_info') }}
          </v-col>
          <v-col cols="12" class="text-center mt-5">
            <v-btn
              rounded
              color="primary"
              depressed
              :ripple="false"
              large
              :loading="acceptOfferLoading"
              @click="onAcceptOffer"
            >
              {{ $t('fields.account.billing.cancel_subs.discount_offer_modal.accept_offer_button') }}
            </v-btn>
            <p
              class="discount-offer-modal-pricing__offer-note mt-6 mb-0"
              v-html="
                $t('fields.account.billing.cancel_subs.discount_offer_modal.offer_note', {
                  subs_end: activeSubscription.current_period_end.format('MMM DD, YYYY'),
                })
              "
            />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-skeleton-loader type="image" />
      </div>

      <!-- Actions -->
      <v-card-actions class="px-0 py-0 mt-7 justify-center text-center">
        <button
          type="button"
          :disabled="isButtonDisabled"
          class="discount-offer-modal-pricing__decline-offer"
          @click="onDeclineOffer(false)"
        >
          {{ $t('fields.account.billing.cancel_subs.discount_offer_modal.offer_decline') }}
        </button>
      </v-card-actions>

      <Dismiss :disabled="isButtonDisabled" absolute top right @click.stop="onDeclineOffer(true)" />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import Dismiss from '@/components/Dismiss.vue';
import { Subscription } from '@/classes/stripe';
import Currency from '@/Models/PrimitiveModels/Currency/Currency';
import { CurrencyCode } from '@/Models/Localization';
import Authenticator from '@/Services/Auth/Authenticator';
import { DiscountInfo } from '@/Services/PromoCodeService';
import PromoCodeService from '@/Services/PromoCodeService';
import {
  trackAcceptDiscountAcceptThisOfferButtonClicked,
  trackRejectDiscountCloseButtonClicked,
  trackRejectDiscountNotThanksButtonClicked,
} from '@/Services/Snowplow/SnowplowService';

@Component({
  components: {
    Dismiss,
  },
})
export default class DiscountOfferModal extends Vue {
  @Prop(Object)
  discountInfo!: DiscountInfo;

  @Prop(Boolean)
  discountInfoLoading!: boolean;

  @Watch('discountInfoLoading')
  onDiscountInfoLoading(val: boolean): void {
    if (!val) {
      this.formatDiscountCodeInformation();
    }
  }

  activeSubscription: Subscription = new Subscription(this.$store.state.subscription.activeSubscription);
  discountedPrice: string = '';
  acceptOfferLoading: boolean = false;

  /**
   * Returns the price of the current subscription (without discount)
   */
  get activeSubscriptionPrice(): string {
    return new Currency(+this.activeSubscription.plan.price).displayPriceMonthly({
      plan: this.activeSubscription.plan,
      selectedInterval: 'month',
      overrideCurrency: this.activeSubscription.plan.currency,
    });
  }

  get currencySymbol(): string {
    const overrideCurrency: string | CurrencyCode = this.activeSubscription.plan.currency;
    return new Currency(+this.activeSubscription.plan.price).displaySymbol({ overrideCurrency });
  }

  get isButtonDisabled(): boolean {
    return this.discountInfoLoading || this.acceptOfferLoading;
  }

  formatDiscountCodeInformation(): void {
    if (this.discountInfo) {
      const discountedPrice = new Currency(
        +this.activeSubscription.plan.price -
          +this.activeSubscription.plan.price * (this.discountInfo.data.coupon.percent_off / 100)
      );

      // Setting the discounted price
      this.discountedPrice = discountedPrice.displayPriceMonthly({
        plan: this.activeSubscription.plan,
        selectedInterval: 'month',
        overrideCurrency: this.activeSubscription.plan.currency,
      });
    }
  }

  created(): void {
    this.formatDiscountCodeInformation();
  }

  async onAcceptOffer(): Promise<void> {
    this.acceptOfferLoading = true;
    const response = await PromoCodeService.assignCodeToTeam({
      coupon_id: this.discountInfo.data.id,
    });

    if (response) {
      trackAcceptDiscountAcceptThisOfferButtonClicked({
        industry_id: this.$store.state?.account?.account?.industry_id ?? null,
        user_id: this.$store.state?.auth?.user?.id,
      });
      await Authenticator.fetchUser();
      await this.$router.push({
        name: 'checkout_complete_with_discount',
        query: {
          discount: `${response.code.coupon.percent_off}`,
        },
      });
    } else {
      this.$emit('closeModal');
    }
    this.acceptOfferLoading = false;
  }

  onDeclineOffer(closeButton = false): void {
    const data = {
      industry_id: this.$store.state?.account?.account?.industry_id ?? null,
      user_id: this.$store.state?.auth?.user?.id,
    };
    if (closeButton) {
      trackRejectDiscountCloseButtonClicked(data);
    } else {
      trackRejectDiscountNotThanksButtonClicked(data);
    }
    this.$emit('declineOffer');
  }
}
</script>

<style lang="scss">
.discount-offer-modal-pricing {
  border-radius: 8px;
  background: rgba(0, 191, 255, 0.05);
  border-top: 8px solid;
  border-color: #80dbff;
  padding: 24px;

  &__container {
    text-align: center;
    padding: 4px 0 0;
  }
  &__strikethrough-price {
    position: relative;
    right: 2px;
    bottom: 4px;
    font-size: 18px;
    font-weight: 600;
    color: #53678c;
    text-decoration-line: line-through;
  }
  &__currency {
    position: relative;
    font-size: 18px;
    color: #1b294b;
    font-weight: 700;
    top: -8px;
    left: 3px;
  }
  &__price {
    font-size: 40px;
    color: #1b294b;
    font-weight: 900;
  }
  &__billing {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    top: -2px;
    right: 3px;
    color: #53678c;
  }
  &__billing-info {
    position: relative;
    padding: 0 12px 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    top: -2px;
  }
  &__offer-note {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #294270;
  }
  &__decline-offer {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    text-decoration-line: underline;
    text-transform: capitalize;
    border-radius: 4px;
    padding: 8px;
    color: #00a5f1;

    &:hover {
      background: #00bfff14;
      cursor: pointer;
    }
  }
}
</style>
