<template>
  <v-dialog :value="open" max-width="460" overlay-color="neutral lighten-1" @input="cancel">
    <v-card>
      <v-card-title>
        <h4 v-if="newPlan" class="text-h4">
          {{ $t('fields.downgrade_restricted_modal.title', { planTitle: $t(`fields.${newPlan.translationKey}`) }) }}
        </h4>
        <h4 v-else class="text-h4">
          {{ $t('fields.downgrade_restricted_modal.title_free_plan') }}
        </h4>
      </v-card-title>

      <v-card-text class="pb-0">
        <p
          v-if="newPlan"
          class="lapis--text text--lighten-1"
          v-html="$t('fields.downgrade_restricted_modal.description', { planCodeLimit: newPlan.codeLimit })"
        />
        <p
          v-else
          class="lapis--text text--lighten-1"
          v-html="$t('fields.downgrade_restricted_modal.description_free_plan')"
        />
      </v-card-text>

      <v-card-actions class="pa-6">
        <v-spacer />

        <v-btn outlined rounded :ripple="false" color="primary" large class="ml-4" @click="cancel">
          {{ $t('fields.downgrade_restricted_modal.cancel') }}
        </v-btn>

        <v-btn depressed rounded :ripple="false" color="primary" large class="ml-4" :href="returnRedirectUrl">
          <Icon name="pause-system" large outline size="24" left />
          {{ $t('fields.downgrade_restricted_modal.pause_codes') }}
        </v-btn>
      </v-card-actions>
      <div class="downgrade-modal__footer-wrapper">
        <p class="downgrade-modal__footer-content">
          {{ $t('fields.downgrade_restricted_modal.reactivate_info') }}
          <span class="downgrade-modal__paused-wrapper">
            <Icon name="pause-system" small outline size="16" />
            <strong>{{ $t('fields.downgrade_restricted_modal.paused') }}</strong>
          </span>
        </p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import AppConfig from '@/AppConfig';
import { Plan } from '@/classes/stripe';
import Icon from '@/components/Icons/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class DowngradeRestrictedModal extends Vue {
  @Prop(Boolean)
  open!: boolean;

  @Prop(Plan)
  newPlan!: Plan; //only set, when user selects to a new premium-plan

  @Prop(Number)
  userCodeAmount!: number;

  oldBaseUrl: any = AppConfig.getOldAppBaseUrl();

  get returnRedirectUrl(): string {
    const url = `${AppConfig.getOldAppBaseUrl()}/upgrade/?showMangement=true&showTrackable=0&allowed=${
      this.newPlan ? this.newPlan.codeLimit : 1
    }&has=${this.userCodeAmount}`;
    return url;
  }

  cancel(): void {
    this.$emit('cancel');
  }
}
</script>

<style lang="scss">
.downgrade-modal {
  &__footer-wrapper {
    text-align: center;
    padding: 8px !important;
    background: map-get($neutral, 'lighten-3');

    @media (max-width: 599px) {
    }
  }

  &__paused-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin-bottom: 0 !important;
    color: map-get($lapis, 'lighten-2') !important;

    @media (max-width: 599px) {
      justify-content: center;
      align-items: center;
      flex-flow: column;
    }

    svg {
      margin: 0 8px;
      color: map-get($neutral, 'darken-1') !important;
    }

    strong {
      font-size: 16px;
    }
  }
}
</style>
