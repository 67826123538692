import Vue from 'vue';
import * as Sentry from '@sentry/vue';

Sentry.init({
  Vue,
  logErrors: true,
  attachProps: true,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  debug: process.env.NODE_ENV !== 'production',
  environment: process.env.NODE_ENV || 'local',
  // 10% session performance tracking data (page-loads, navigation).
  tracesSampleRate: 0.1,
});
