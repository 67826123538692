var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('EmptyState',{staticClass:"offer-accepted-confirmation__container",attrs:{"illustration":"discount_illustration.png","width":200}},[_c('h1',{staticClass:"text-h1 mt-6"},[_vm._v(" "+_vm._s(_vm.$t('fields.account.billing.cancel_subs.offer_accepted_confirmation.title'))+" ")]),_c('h4',{staticClass:"text-h4 mt-6",domProps:{"innerHTML":_vm._s(
        _vm.$t('fields.account.billing.cancel_subs.offer_accepted_confirmation.subtitle', {
          discount: `${_vm.$route.query.discount}%`,
        })
      )}}),_c('p',{staticClass:"body-1 lapis--text text--lighten-2 mt-6",domProps:{"innerHTML":_vm._s(
        _vm.$t('fields.account.billing.cancel_subs.offer_accepted_confirmation.note', {
          subs_end: _vm.$store.state.subscription.activeSubscription.current_period_end.format('MMM DD, YYYY'),
        })
      )}}),_c('v-btn',{staticClass:"mt-10",attrs:{"depressed":"","rounded":"","ripple":false,"color":"primary","x-large":"","to":"/my-account/billing"}},[_vm._v(" "+_vm._s(_vm.$t('fields.account.billing.cancel_subs.offer_accepted_confirmation.button'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }