<template>
  <v-dialog
    :value="open"
    max-width="600"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    content-class="qr-dialog dialog-class"
    @input="cancel()"
  >
    <v-card rounded>
      <v-card-title class="pt-6">
        <h4 class="text-h4">
          {{
            isPaymentMode
              ? $t('fields.account.billing.address_edit.title_two')
              : $t('fields.account.billing.address_edit.title_one')
          }}
        </h4>
        <template v-if="isPaymentMode">
          <v-spacer />
          <v-chip label small class="chip--badge lapis--text text--lighten-1"> 3 </v-chip>
        </template>
      </v-card-title>

      <v-card-subtitle class="pt-3 pb-5">
        {{ $t('fields.account.billing.address_edit.desc') }}
      </v-card-subtitle>

      <ValidationObserver ref="observer" slim>
        <v-card-text class="pb-8 pt-2">
          <div v-show="formErrorMessage" class="pb-8">
            <Alert dense outlined type="error" icon="forbidden-system">
              <template #title>
                {{ formErrorMessage }}
              </template>
            </Alert>
          </div>

          <!-- COMPANY NAME -->
          <v-row v-if="form.legal_entity === legalEntityB2B">
            <v-col cols="12">
              <ValidationProvider
                ref="checkout.customer.name"
                v-slot="{ errors }"
                name="user.company_name"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="form.company"
                  outlined
                  dense
                  class="text-field"
                  :label="$t('fields.user.company_name')"
                  :counter="191"
                  maxlength="191"
                  :error-messages="errors[0]"
                  data-test="companyName"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <!-- COMPANY NAME END -->

          <!-- FIRST & LAST NAME -->
          <v-row>
            <!-- FIRST NAME -->
            <v-col cols="6">
              <ValidationProvider
                ref="checkout.customer.metadata.first_name"
                v-slot="{ errors }"
                name="user.firstname"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="form.first_name"
                  outlined
                  hide-details
                  dense
                  class="text-field"
                  :label="$t('fields.user.firstname')"
                  :error-messages="errors[0]"
                  data-test="firstName"
                />
              </ValidationProvider>
            </v-col>
            <!-- FIRST NAME END -->

            <!-- LAST NAME -->
            <v-col cols="6">
              <ValidationProvider
                ref="checkout.customer.metadata.last_name"
                v-slot="{ errors }"
                name="user.lastname"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="form.last_name"
                  outlined
                  hide-details
                  dense
                  class="text-field"
                  :label="$t('fields.user.lastname')"
                  :error-messages="errors[0]"
                  data-test="lastName"
                />
              </ValidationProvider>
            </v-col>
            <!-- LAST NAME END -->
          </v-row>
          <!-- FIRST & LAST NAME END -->

          <!-- GOOGLE PLACE SUGGESTIONS -->
          <v-row v-if="!showManualAddressInput">
            <!-- GOOGLE PLACE INPUT -->
            <v-col cols="12" class="pb-0">
              <GooglePlacesAutocomplete
                :address="form.line1"
                @selectedAddressFromDropdown="toggleManualAddressInput(true, $event)"
              />
            </v-col>
            <!-- GOOGLE PLACE INPUT END -->

            <!-- ENTER MANUALLY BUTTON -->
            <v-col class="text-right pt-0">
              <v-btn
                text
                :ripple="false"
                color="primary"
                small
                class="ml-4"
                @click.stop="toggleManualAddressInput(true)"
              >
                <span class="navy--text">
                  {{ $t('fields.checkout.form.manual_address_input_button') }}
                </span>
              </v-btn>
            </v-col>
            <!-- ENTER MANUALLY BUTTON END -->
          </v-row>
          <!-- GOOGLE PLACE SUGGESTIONS END -->

          <!-- MANUAL ADDRESS -->
          <v-row v-if="showManualAddressInput || form.line1">
            <v-col cols="12">
              <ValidationProvider
                ref="checkout.customer.team.line1"
                v-slot="{ errors }"
                name="user.address"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="form.line1"
                  outlined
                  hide-details
                  dense
                  class="text-field"
                  :label="form.legal_entity === legalEntityB2B ? 'Business address' : $t('fields.user.address')"
                  :error-messages="errors[0]"
                  :placeholder="$t('fields.user.address')"
                  data-test="address"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <!-- MANUAL ADDRESS END -->

          <!-- MANUAL CITY & ZIP CODE -->
          <v-row v-if="showManualAddressInput || form.line1">
            <v-col cols="8">
              <ValidationProvider
                ref="checkout.customer.address.city"
                v-slot="{ errors }"
                name="user.city"
                rules="required|max:191"
              >
                <v-text-field
                  v-model="form.city"
                  outlined
                  hide-details
                  dense
                  class="text-field"
                  :label="$t('fields.user.city')"
                  :error-messages="errors[0]"
                  data-test="city"
                />
              </ValidationProvider>
            </v-col>

            <v-col cols="4">
              <ValidationProvider
                ref="checkout.customer.address.postal_code"
                v-slot="{ errors }"
                name="user.zip"
                rules="required|max:15"
              >
                <v-text-field
                  v-model="form.postal_code"
                  outlined
                  hide-details
                  dense
                  class="text-field"
                  :label="$t('fields.user.zip')"
                  :error-messages="errors[0]"
                  data-test="zipCode"
                />
              </ValidationProvider>

              <v-col v-if="showManualAddressInput || form.line1" class="text-right pa-0">
                <v-btn
                  text
                  :ripple="false"
                  color="primary"
                  small
                  class="ml-4"
                  @click.stop="toggleManualAddressInput(false)"
                >
                  <span class="navy--text">
                    {{ $t('fields.checkout.form.reset') }}
                  </span>
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
          <!-- MANUAL CITY & ZIP CODE END -->

          <v-row v-if="(showManualAddressInput || stripeCheckoutService.customer.address.state) && showStateDropdown">
            <v-col cols="12">
              <ValidationProvider ref="checkout.customer.address.state" name="user.state" rules="max:191">
                <StateDropdown
                  :user-state="stripeCheckoutService.customer.address.state"
                  :state-list="statesList"
                  @stateSelected="onStateSelected"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" :class="showStateDropdown ? 'pt-12' : 'pt-5'">
              <CountryDropdown
                :user-country="stripeCheckoutService.customer.address.country"
                :country-list="countriesList"
                @countrySelected="onCountrySelected"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </ValidationObserver>
      <v-card-actions class="pb-6 pt-0 px-6">
        <v-spacer />
        <v-btn
          outlined
          large
          :ripple="false"
          rounded
          color="primary"
          data-test="cancelButton"
          @click="$emit('cancel'), trackOptimizelyEventBillingInformations()"
        >
          {{
            isPaymentMode
              ? $t('fields.account.billing.address_edit.btn_skip')
              : $t('fields.account.billing.address_edit.btn_cancel')
          }}
        </v-btn>
        <v-btn
          depressed
          large
          :loading="showLoading"
          :ripple="false"
          rounded
          color="primary"
          class="ml-4"
          data-test="updateButton"
          @click="update"
        >
          {{ $t('fields.account.billing.address_edit.btn_update') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import CaStates from '@/Models/Static/ca_states.json';
import UsStates from '@/Models/Static/us_states.json';
import VeeValidateRefs from '@/Models/VeeValidateRefs';
import DbipLookupService from '@/Services/DbipLookupService';
import OptimizelyService from '@/Services/OptimizelyService';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import { Team } from '@/classes/auth';
import Alert from '@/components/Alert.vue';
import store from '@/store';
import { cloneDeep } from 'lodash';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Vue } from 'vue-property-decorator';
import countries from '@/Models/Static/countries.json';
import { State } from '@/Models/Static/State';

@Component({
  components: {
    CountryDropdown: () => import('@/components/Input/CountryDropdown.vue'),
    StateDropdown: () => import('@/components/Input/StateDropdown.vue'),
    GooglePlacesAutocomplete: () => import('@/components/Input/GooglePlacesAutocomplete.vue'),
    ValidationObserver,
    ValidationProvider,
    Alert,
  },
})
export default class BillingAddressEdit extends Vue {
  @Prop(Boolean)
  open!: boolean;

  @Prop({ default: false })
  isPaymentMode?: boolean;

  form: Team = cloneDeep(store.state.auth.team);
  showManualAddressInput: boolean = !!store.state.auth.team.line1;
  openCountryModal: boolean = false;
  detectedCountry: any;
  showStateDropdown: boolean = false;
  statesList: Array<any> = [];
  countriesList = countries;
  legalEntityB2B: string = 'b2b';
  showLoading: boolean = false;
  formErrorMessage: TranslateResult = '';
  stripeCheckoutService: StripeCheckoutService = new StripeCheckoutService();
  $refs!: {
    // Used for validation of the form data<ValidationObserver slim ref="observer">
    observer: VeeValidateRefs;
  };

  mounted(): void {
    this.preloadData();
  }

  async update(): Promise<void> {
    this.showLoading = true;
    this.formErrorMessage = '';

    OptimizelyService.trackEvent('update_billing_informations');

    if (await this.$refs.observer.validate()) {
      this.stripeCheckoutService.customer.address.state = this.form.state;
      this.stripeCheckoutService.customer.address.country = this.form.country;

      await store.dispatch('auth/updateTeam', this.form).then(() => {
        this.cancel();
      });
    } else {
      const observer: any = this.$refs.observer;
      const errors = observer.errors;

      const firstErrorMessage = Object.keys(errors).find((key) => errors[key] && errors[key].length > 0);

      this.formErrorMessage = firstErrorMessage
        ? errors[firstErrorMessage][0]
        : this.$t('fields.checkout.form.invalid');
    }

    this.showLoading = false;
  }

  preloadData(): void {
    const country = this.form.country;
    this.stripeCheckoutService.customer.address.country = country;
    this.stripeCheckoutService.customer.address.state = this.form.state;

    this.setStatesList(country);
  }

  toggleManualAddressInput(toggle: boolean, googlePlacesData: any = null): void {
    this.showManualAddressInput = toggle;

    if (googlePlacesData) {
      if (googlePlacesData.street && googlePlacesData.house) {
        this.form.line1 = googlePlacesData.street + ' ' + googlePlacesData.house;
      } else if (googlePlacesData.street && !googlePlacesData.house) {
        this.form.line1 = googlePlacesData.street;
      }
      this.form.postal_code = googlePlacesData.zip;
      this.form.city = googlePlacesData.city;
    }
  }

  tryGeolocationDetection(): boolean {
    return 'geolocation' in navigator;
  }

  cancel(): void {
    this.$emit('cancel');
    this.resetFormValues();
  }

  resetFormValues(): void {
    this.form = store.state.auth.team;
  }

  detectCountry(): void {
    // If browser supports geolocation-detection
    if (this.tryGeolocationDetection()) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          const dbipLookupService = new DbipLookupService();
          await dbipLookupService.getLocationViaLatLong(pos.lat, pos.lng).then((result: any) => {
            if (result) {
              this.detectedCountry = result.data.country;
            }
          });

          this.openCountryModal = false;
        },
        function (error) {
          console.log(error);
        }
      );
    }
  }

  setStatesList(country: string): void {
    if (!country) {
      this.showStateDropdown = false;
      return;
    }
    const states: Record<string, State[]> = {
      us: UsStates,
      ca: CaStates,
    };
    this.statesList = states[country.toLowerCase()] || [];
    this.showStateDropdown = this.statesList.length > 0;
  }

  trackOptimizelyEventBillingInformations(): void {
    if (this.isPaymentMode) {
      OptimizelyService.trackEvent('skip_updating_billing_informations');
    } else {
      OptimizelyService.trackEvent('cancel_updating_billing_informations');
    }
  }

  onCountrySelected(country: string): void {
    this.form.country = country;
    this.setStatesList(country);
    OptimizelyService.trackEvent('billing_edit_country_change');
  }

  onStateSelected(state: string): void {
    this.form.state = state;
    this.stripeCheckoutService.customer.address.state = state;
  }
}
</script>

<style lang="scss">
.dialog-class {
  overflow-y: unset;
}
</style>
