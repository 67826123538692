<template>
  <v-dialog
    :value="open"
    max-width="460"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    content-class="qr-dialog"
    scrollable
    @input="cancel"
  >
    <v-card rounded class="pa-6">
      <!-- Title -->
      <v-card-title class="px-0 pt-0">
        <h4 class="text-h4">
          <span v-if="!isUserOnAbtestVariantENG1738()">
            {{ $t('fields.account.billing.reactivate_subs.modal_title') }}
          </span>
          <span v-if="isUserOnAbtestVariantENG1738()">Manage your auto-renewal settings</span>
        </h4>
      </v-card-title>

      <!-- Subtitle description -->
      <v-card-subtitle class="px-0 pt-3 pb-5">
        <span
          v-if="!isUserOnAbtestVariantENG1738()"
          v-html="$t('fields.account.billing.reactivate_subs.modal_desc', { date: renew })"
        ></span>
        <span v-if="isUserOnAbtestVariantENG1738()">
          You have previously disabled auto-renewal of your subscription. You can enable it here if you want your
          current subscription to be renewed on <b>{{ renew }}</b>
        </span>
      </v-card-subtitle>

      <!-- Actions -->
      <v-card-actions class="px-0 py-0 mt-3">
        <v-spacer />
        <v-btn
          outlined
          large
          :ripple="false"
          rounded
          color="primary"
          :disabled="showLoadingSubscription.reactivateSubscription"
          @click="cancel"
        >
          <span v-if="!isUserOnAbtestVariantENG1738()">
            {{ $t('fields.account.billing.reactivate_subs.modal_btn_outline') }}
          </span>
          <span v-if="isUserOnAbtestVariantENG1738()">Not Now</span>
        </v-btn>
        <v-btn
          depressed
          large
          :ripple="false"
          rounded
          color="primary"
          class="ml-4"
          :loading="showLoadingSubscription.reactivateSubscription"
          :disabled="showLoadingSubscription.reactivateSubscription"
          @click="onReactivateSubscription(activeSubscription.plan_id)"
        >
          <span v-if="!isUserOnAbtestVariantENG1738()">
            {{ $t('fields.account.billing.reactivate_subs.modal_btn_primary') }}
          </span>
          <span v-if="isUserOnAbtestVariantENG1738()">Enable Auto-Renewal</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import store from '@/store';
import { SubscriptionState } from '@/classes/stripe';
import OptimizelyService from '@/Services/OptimizelyService';
import { trackKeepSubscriptionButtonClicked } from '@/Services/Snowplow/SnowplowService';

@Component({
  computed: {
    ...mapState<SubscriptionState>('subscription', {
      showLoadingSubscription: (state: SubscriptionState) => state.showLoading,
    }),
  },
})
export default class ReactivateSubscription extends Vue {
  @Prop(Boolean)
  open!: boolean;

  @Prop()
  renew!: string;

  showLoadingSubscription!: any;
  activeSubscription = store.state.subscription.activeSubscription;

  onReactivateSubscription(planId: string): void {
    OptimizelyService.trackEvent('checkout_renewal_modal_enable');

    // ENG-2317: Track Subscription Reactivation [undo_cancellation.keep_subscription_button.clicked]
    const data = {
      industry_id: store.state?.account?.account?.industry_id ?? null,
      user_id: store.state?.auth?.user?.id,
    };
    trackKeepSubscriptionButtonClicked(data);

    if (!this.activeSubscription.id) {
      return;
    }

    store
      .dispatch('subscription/reactivateSubscription', planId)
      .then(() => {
        store.dispatch('subscription/setActiveSubscription', this.activeSubscription);
        this.$emit('reactivate');
        this.$emit('cancel');
      })
      .catch(() => {
        this.$emit('cancel');
        store.dispatch('layout/setSnackbar', {
          type: 'error',
          icon: 'forbidden-system',
          text: this.$t('fields.account.billing.reactivate_subs.snackbar_error').toString(),
          isSnackbar: true,
          active: true,
        });
      });
  }

  cancel(): void {
    OptimizelyService.trackEvent('checkout_renewal_modal_cancel');
    this.$emit('cancel');
  }

  isUserOnAbtestVariantENG1738(): boolean {
    return OptimizelyService.isAbTestVariantActive('24291960077', '24272590038');
  }
}
</script>
