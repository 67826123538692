<template>
  <v-dialog
    :value="open"
    max-width="600"
    overlay-color="neutral lighten-1"
    overlay-opacity=".80"
    content-class="qr-dialog"
    scrollable
    @input="cancel"
  >
    <v-card rounded class="pa-6">
      <!-- Title -->
      <v-card-title class="px-0 pt-0">
        <h4 v-show="step < 2" class="text-h4">
          {{ currentTitle }}
        </h4>
      </v-card-title>

      <v-window v-model="step">
        <!-- STEP 1 -->
        <v-window-item :value="1">
          <!-- Subtitle description -->
          <v-card-subtitle class="px-0 pt-3 pb-5">
            By resuming your paused subscription, you can create new QR Codes, reactivate existing Dynamic QR Codes, and
            access all features again. Your subscription period will end on
            <span class="font-weight-semi-bold">{{ formattedSubscriptionEnd }}</span>
          </v-card-subtitle>

          <!-- Actions -->
          <v-card-actions class="px-0 py-0 mt-3">
            <v-spacer />
            <v-btn outlined large :ripple="false" rounded color="primary" @click="cancel"> Cancel </v-btn>
            <v-btn depressed large :ripple="false" rounded color="primary" class="ml-4" @click="step++"> Resume </v-btn>
          </v-card-actions>
        </v-window-item>
        <!-- STEP 1 END -->

        <!-- STEP 2 -->
        <v-window-item :value="2">
          <!-- Illustration -->
          <v-img
            src="../../../assets/illustrations/modal_reactivate-subscription_illustration.png"
            height="160"
            contain
            class="mb-11"
          />

          <!-- Title -->
          <h4 v-show="step === 2" class="text-h4 text-center mx-10">
            {{ currentTitle }}
          </h4>

          <!-- Subtitle description -->
          <v-card-subtitle class="px-0 pt-3 pb-5 mx-10 text-center">
            Your subscription is now resumed so you can jump back in where you left off and start creating QR Codes.
          </v-card-subtitle>

          <!-- Actions -->
          <v-card-actions class="px-0 py-0 mt-3 justify-center">
            <v-btn depressed large :ripple="false" rounded color="primary" @click="cancel"> Got it </v-btn>
          </v-card-actions>
        </v-window-item>
        <!-- STEP 2 END -->
      </v-window>

      <Dismiss absolute top right @click.stop="cancel" />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Dismiss from '@/components/Dismiss.vue';
import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Dismiss,
  },
})
export default class ResumeSubscription extends Vue {
  @Prop(Boolean)
  open!: boolean;

  @Prop()
  subscriptionEnd!: dayjs.Dayjs;

  step = 1;

  get currentTitle(): string {
    switch (this.step) {
      case 1:
        return 'Ready to resume your subscription?';
      default:
        return 'Let’s get those QR Codes working again';
    }
  }

  get formattedSubscriptionEnd(): string {
    return this.subscriptionEnd ? this.subscriptionEnd.format('MMMM DD, YYYY') : '';
  }

  cancel(): void {
    this.$emit('cancel');

    setTimeout(() => {
      this.step = 1;
    }, 500);
  }
}
</script>
