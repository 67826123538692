<template>
  <v-dialog max-width="460" overlay-color="neutral lighten-1" :value="open" @input="cancel">
    <v-card>
      <v-card-title>
        <h4 class="text-h4">
          <span>{{ $t('fields.free_plan_modal.title') }}</span>
        </h4>
      </v-card-title>

      <v-card-text class="pb-0">
        <p class="lapis--text text--lighten-1">
          <span v-html="$t('fields.free_plan_modal.description')" />
        </p>
      </v-card-text>

      <v-card-actions class="pa-6">
        <v-spacer />

        <v-btn
          outlined
          rounded
          :ripple="false"
          color="primary"
          large
          class="ml-4"
          data-test="free-plan-modal-cancel"
          @click="cancel"
        >
          {{ $t('fields.free_plan_modal.cancel_button') }}
        </v-btn>

        <v-btn
          depressed
          rounded
          :ripple="false"
          color="primary"
          large
          class="ml-4"
          :loading="showLoadingSubscription"
          :disabled="showLoadingSubscription"
          data-test="free-plan-modal-select-free-plan"
          @click="selectFreePlan()"
        >
          <span>{{ $t('fields.free_plan_modal.activate_button') }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import store from '@/store';
import AppConfig from '@/AppConfig';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';

@Component({})
export default class SelectFreePlanModal extends Vue {
  @Prop(Boolean)
  open!: boolean;

  showLoadingSubscription = false;
  stripeSubscriptionService: StripeSubscriptionService = new StripeSubscriptionService();

  /**
   * Select free plan
   * Will cancel existing plan or convert trial to free plan
   */
  async selectFreePlan(): Promise<void> {
    // Downgrade trial
    this.showLoadingSubscription = true;
    await this.stripeSubscriptionService
      .cancel('default')
      .then(() => {
        // Redirect to old app with URL param, which triggers a confirmation-banner, that the free plan is active
        let url = AppConfig.getOldAppBaseUrl() + '/manage/?freeplan=true';
        window.location.href = url;
      })
      .catch(() => {
        this.$emit('cancel');
        store.dispatch('layout/setSnackbar', {
          type: 'error',
          icon: 'forbidden-system',
          text: this.$t('fields.free_plan_modal.error').toString(),
          isSnackbar: true,
          active: true,
        });
      });
    this.showLoadingSubscription = false;
  }

  cancel(): void {
    this.$emit('cancel');
  }
}
</script>
