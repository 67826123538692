<template>
  <v-container class="pa-0">
    <!-- LAYOUT LOADING -->
    <template v-if="!user.id || showLoadingSubscription.loadingSubscription">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate :width="3" color="candy" />
        </v-col>
      </v-row>
    </template>
    <!-- LAYOUT LOADING END -->

    <!-- CUSTOMER HAS SUBSCRIPTION -->
    <template v-if="(activeSubscription.id && activeSubscription.id !== 0) || invoices.length">
      <ActiveSubscription ref="activeSubscriptionRef" />

      <!-- PAYMENT DATA & INFORMATION END -->
    </template>
    <!-- CUSTOMER HAS SUBSCRIPTION END -->

    <!-- CUSTOMER HAS NO SUBSCRIPTION -->
    <template v-else>
      <v-row justify="center">
        <v-col cols="auto">
          <EmptyState illustration="empty-state_billing_illustration.png" :width="250">
            <h2 class="text-h2">
              {{ $t('fields.account.billing.payment.empty_title') }}
            </h2>
            <p class="body-1 lapis--text text--lighten-2 mt-3">
              {{ $t('fields.account.billing.payment.empty_subtitle') }}
            </p>

            <!-- Create button -->
            <v-btn depressed rounded :ripple="false" color="primary" x-large to="/features-and-pricing/" class="mt-5">
              {{ $t('fields.account.billing.payment.empty_action_button') }}
            </v-btn>
          </EmptyState>
        </v-col>
      </v-row>
    </template>
    <!-- CUSTOMER HAS NO SUBSCRIPTION END -->
  </v-container>
</template>

<script lang="ts">
import { User } from '@/classes/auth';
import { Invoice, Subscription, SubscriptionState } from '@/classes/stripe';

import EmptyState from '@/components/EmptyState.vue';
import ActiveSubscription from '@/components/Subscription/ActiveSubscription.vue';
import EventService from '@/Services/EventService';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import store from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { isFeatureEnabled } from '@/Services/FeatureFlagService';
import { optimizelyFeatureFlags } from '@/plugins/optimizely-feature-flags';
import PromoCodeService, { DiscountInfo } from '@/Services/PromoCodeService';
import AppConfig from '@/AppConfig';

@Component({
  components: {
    EmptyState,
    ActiveSubscription,
  },
  computed: {
    ...mapState<SubscriptionState>('subscription', {
      showLoadingSubscription: (state: SubscriptionState) => state.showLoading,
    }),
  },
})
export default class BillingDetail extends Vue {
  // state from vuex
  get activeSubscription(): Subscription {
    return store.state.subscription.activeSubscription;
  }
  user: User = store.state.auth.user;
  errorSubscription = false;
  invoices: Array<Invoice> = [];
  hasInvoices = this.invoices?.length || false;
  tryAgainSubscription = false;
  showLoadingSubscription!: any;

  $refs!: {
    activeSubscriptionRef: any;
  };

  // Handles payment method logic and some of the form state
  stripeSubscriptionService: StripeSubscriptionService = new StripeSubscriptionService();

  isFeatureDiscountsForCancelledSubscriptionsEnabled = isFeatureEnabled(
    optimizelyFeatureFlags,
    'cnv-3263_apply_discounts',
    store.state?.auth?.user?.id.toString()
  );

  // METHODS

  async applyCouponCode(): Promise<void> {
    const CNV_3263_PROMO_CODE_ID = AppConfig.getCNV3263PromoCodeId();
    const codeInformation: DiscountInfo | null = await PromoCodeService.getDiscountCodeInformation(
      CNV_3263_PROMO_CODE_ID
    );

    const canApplyCode = codeInformation && codeInformation.data?.active && this.activeSubscription.is_cancelled;

    if (canApplyCode) {
      const response = await PromoCodeService.assignCodeToTeam({
        coupon_id: CNV_3263_PROMO_CODE_ID,
      });
      if (response) {
        // TODO: any additional tracking is needed?
        // could be implemented with refs or using another query `?modal=subscriptionReactivate`
        this.$refs.activeSubscriptionRef.openDialog('subscriptionReactivate');
      }
    }
  }

  async loadSubscriptionPlan(tryAgain?: boolean): Promise<void> {
    if (tryAgain) {
      this.errorSubscription = false;
      this.tryAgainSubscription = true;
    }

    // Reload Subscription
    if (this.activeSubscription.id == 0 || tryAgain) {
      await this.$store.dispatch('subscription/loadSubscription');
      this.errorSubscription = false;
      this.tryAgainSubscription = false;
    }

    // Validate reloaded subscription
    if (this.activeSubscription.id == 0) {
      this.errorSubscription = true;
      this.tryAgainSubscription = false;
    }

    if (this.invoices) {
      this.errorSubscription = false;
      this.hasInvoices = true;
    }
  }

  // METHODS END

  async created(): Promise<void> {
    await this.stripeSubscriptionService.initSubscription();
    await this.loadSubscriptionPlan();
    EventService.createEvent(EventService.VISITED_PAGE_BILLING);
  }

  async mounted(): Promise<void> {
    await this.$store.dispatch('account/loadInvoices');
    this.invoices = store.state.account.invoices || [];
    if (this.$route.query?.coupon === 'REACTIVATE50' && this.isFeatureDiscountsForCancelledSubscriptionsEnabled) {
      await this.applyCouponCode();
    }
  }
}
</script>

<style lang="scss">
.transaction-history {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0 16px;
  }
}
</style>
