<template>
  <!-- START: Appbar -->
  <v-app-bar height="72px" app fixed clipped-left data-test="app-header">
    <!-- START: Logo Desktop -->
    <v-img
      contain
      class="ml-6 mr-6 d-none d-md-flex cursor--pointer"
      src="../../assets/logos/qrcg-pro-logo.svg"
      max-width="224px"
      @click="$router.push({ name: 'index' })"
    />
    <!-- END: Logo Desktop -->

    <!-- START: Logo Mobile -->
    <v-img
      class="ml-4 mr-2 d-flex d-md-none cursor--pointer"
      src="../../assets/logos/qrcg-pro-logo-short.svg"
      max-width="51px"
      @click="$router.push({ name: 'index' })"
    />
    <!-- END: Logo Mobile -->

    <!-- Divider -->
    <v-divider v-if="$route.name !== 'checkout_payment_complete'" inset vertical class="appbar__divider" />

    <!-- START: Back button -->
    <v-btn
      v-if="(isCheckout($route.name) || isPauseMode) && $route.name !== 'checkout_payment_complete'"
      text
      :ripple="false"
      color="primary"
      large
      class="ml-2 ml-sm-4 v-btn--back"
      @click="goBack(-1)"
    >
      <Icon name="arrow-system" large size="24" left outline class="icon--mirror" />
      <span class="navy--text d-none d-sm-flex">
        {{ backButtonLabel }}
      </span>
    </v-btn>
    <!-- END: Back button -->

    <!-- START: Folder collapse button -->
    <v-tooltip v-if="false" bottom z-index="999" transition="none">
      <template #activator="{ on }">
        <v-btn icon color="primary" large class="ml-4" :ripple="false" @click="toggleDrawer" v-on="on">
          <Icon
            v-if="(drawer && !isNavigationMobile) || (isNavigationMobile && !drawer)"
            name="menu-show-system"
            large
            outline
          />
          <Icon v-else name="menu-hide-system" large outline />
        </v-btn>
      </template>
      <span v-if="(drawer && !isNavigationMobile) || (isNavigationMobile && !drawer)">Show folders</span>
      <span v-else>{{ $t('fields.navigation.collapse_folders') }}</span>
    </v-tooltip>
    <!-- END: Folder collapse button -->

    <!-- Spacer -->
    <v-spacer />

    <!-- Upgrade-notice -->
    <v-alert
      v-if="qrcodes.current >= qrcodes.allowed && $route.name !== 'checkout_plan_selection'"
      class="appbar-notice appbar-notice--in-navbar pa-2"
      dark
      color="navy"
    >
      <span class="appbar-notice__text typeface__render--semi-bold">No more Codes?</span>
      <v-btn text :ripple="false" color="primary" small class="v-btn--text--link ml-3" href="/features-and-pricing">
        <span class="white--text">
          {{ $t('fields.navigation.upgrade') }}
        </span>
      </v-btn>
    </v-alert>

    <!-- Help alert -->
    <v-alert
      v-if="$route.name === 'checkout_plan_selection'"
      class="appbar-notice appbar-notice--in-navbar appbar-notice--checkout pa-2"
      icon="$form-type-large-fill"
      dark
      color="navy"
    >
      <span class="appbar-notice__text typeface__render--semi-bold">{{ $t('fields.navigation.help_choosing') }}</span>
      <v-btn
        text
        :ripple="false"
        color="primary"
        small
        class="v-btn--text--link ml-sm-3"
        href="https://help.qr-code-generator.com/en"
        target="_blank"
      >
        <span class="white--text">
          {{ $t('fields.navigation.contact_us') }}
        </span>
      </v-btn>
    </v-alert>

    <!-- START: Helpcenter button -->
    <v-tooltip v-if="$route.name !== 'checkout_plan_selection'" bottom z-index="999" transition="none">
      <template #activator="{ on }">
        <v-btn
          icon
          :ripple="false"
          color="primary"
          large
          class="mr-3 d-none d-sm-flex"
          href="https://help.qr-code-generator.com/en"
          v-on="on"
        >
          <Icon name="help-round-system" outline large />
        </v-btn>
      </template>
      <div class="text-center">
        <div>
          {{ $t('fields.navigation.cust_id') }}: <span>{{ user.id }}</span>
        </div>
        <div v-text="user.email" />
      </div>
    </v-tooltip>
    <!-- END: Helpcenter button -->

    <!-- Divider -->
    <v-divider v-if="$route.name !== 'checkout_plan_selection'" inset vertical class="appbar__divider" />

    <!-- START: Account button -->
    <v-menu
      v-if="$route.name !== 'checkout_plan_selection'"
      v-model="showTooltipAccount"
      app
      offset-y
      left
      max-width="284"
      min-width="284"
      nudge-bottom="20"
      elevation="4"
    >
      <template #activator="{}">
        <v-tooltip bottom z-index="999" :disabled="showTooltipAccount" transition="none">
          <template #activator="{ on: tooltip }">
            <v-btn
              text
              :ripple="false"
              color="primary"
              large
              data-test="btn_account"
              class="ml-3 mr-sm-6 mr-4 account-button"
              :class="showTooltipAccount ? 'v-btn--active' : null"
              v-on="{ ...tooltip }"
              @click="showTooltipAccount = !showTooltipAccount"
            >
              <Icon name="account-system" large outline left />
              <span class="navy--text d-none d-sm-block">
                {{ $t('fields.navigation.account') }}
              </span>
            </v-btn>
          </template>
          <span>{{ $t('fields.navigation.my_account') }}</span>
        </v-tooltip>
      </template>

      <v-list nav dense class="pt-6 pr-7 pl-7 pb-6" color="neutral lighten-4">
        <!-- Upgrade-notice -->
        <v-alert
          v-if="qrcodes.current >= qrcodes.allowed"
          class="appbar-upgrade-notice appbar-upgrade-notice--in-menu"
          dark
          color="navy"
        >
          <span class="appbar-upgrade-notice__text">No more Codes?</span>
          <v-btn text :ripple="false" color="primary" small class="v-btn--text--link ml-3" href="/features-and-pricing">
            <span class="white--text">
              {{ $t('fields.navigation.upgrade') }}
            </span>
          </v-btn>
        </v-alert>

        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in navItems"
            :key="i"
            class="account-menu__list-item mb-4"
            link
            v-bind="returnNavItemProps(item)"
            color="neutral darken-3"
            :data-test="item.title.replace(/ /g, '_').toLowerCase()"
            exact
          >
            <v-list-item-icon class="mr-3 d-flex justify-center align-center">
              <Icon :name="item.icon" outline right color="neutral darken-1" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="account-menu__title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="mt-6 mb-4" />

          <v-list-item class="account-menu__list-item mb-0" color="neutral darken-3" data-test="account_logout">
            <v-list-item-icon class="mr-3 d-flex justify-center align-center">
              <Icon name="logout-system" outline right color="neutral darken-1" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="account-menu__title" @click="logout">
                {{ $t('fields.navigation.logout') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <!-- END: Account button -->
    <!-- START: Progress linear (loader) -->
    <v-progress-linear :active="globalLoader" :indeterminate="true" absolute bottom color="candy" />
    <!-- END: Progress linear (loader) -->
  </v-app-bar>
  <!-- END: Appbar -->
</template>

<script lang="ts">
import Authenticator from '@/Services/Auth/Authenticator';
import OptimizelyService from '@/Services/OptimizelyService';
import { User } from '@/classes/auth';
import Dismiss from '@/components/Dismiss.vue';
import Icon from '@/components/Icons/Icon.vue';
import { SupportModalMixin } from '@/mixins/SupportModalMixin';
import store from '@/store';
import { AuthState } from '@/store/auth';
import { LayoutState } from '@/store/layout';
import { TranslateResult } from 'vue-i18n';
import { Component, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';

@Component({
  components: {
    Icon,
    Dismiss,
  },
  computed: {
    ...mapState<LayoutState>('layout', {
      drawer: (state: LayoutState) => state.drawer,
      isNavigationMobile: (state: LayoutState) => state.isNavigationMobile,
      globalLoader: (state: LayoutState) => state.globalLoader,
    }),
    ...mapState<AuthState>('auth', {
      user: (state: AuthState) => state.user,
    }),
  },
  methods: {
    ...mapActions('layout', ['setDrawerState']),
  },
})
export default class AppBar extends SupportModalMixin {
  @Watch('$route.path')
  onChangeRoute(): void {
    // Close menu on page change
    this.showTooltipAccount = false;
  }

  drawer!: boolean;
  globalLoader!: boolean;
  isNavigationMobile!: boolean;
  showTooltipAccount = false;
  user!: User;
  id = store?.state?.account?.account?.id;
  qrcodes = store?.state?.account?.account?.qrcodes;
  closeOnContentClick = false;
  navItems: Array<{ href: string; title: string; icon: string }> = [
    {
      href: '/my-account',
      title: this.$t('fields.navigation.my_account') as string,
      icon: 'account-system',
    },
    {
      href: '/my-account/user-management',
      title: this.$t('fields.navigation.user_management') as string,
      icon: 'users-system',
    },
    {
      href: '/my-account/white-label',
      title: this.$t('fields.navigation.whitelabel_options') as string,
      icon: 'palette-system',
    },
    {
      href: '/my-account/api-keys',
      title: this.$t('fields.navigation.api_keys') as string,
      icon: 'gear-system',
    },
    {
      href: '/features-and-pricing',
      title: this.$t('fields.navigation.features_and_pricing') as string,
      icon: 'price-round-system',
    },
    {
      href: 'https://help.qr-code-generator.com/en',
      title: this.$t('fields.navigation.help_center') as string,
      icon: 'help-round-system',
    },
  ];

  setDrawerState!: (isOpen: boolean) => any;
  isPauseMode = false;

  mounted(): void {
    this.setIsPauseMode();
  }

  async goBack(goBackBy: number = -1): Promise<void> {
    if (this.$route.query.trial === 'expired') {
      OptimizelyService.trackEvent('pricing_page_my_qr_codes_back_button');
    } else {
      OptimizelyService.trackEvent('pricing_page_back_button');
    }
    if (this.canGoBack) {
      await this.$store.commit('app/LOCATION_HISTORY_REMOVE');
      await this.$router.go(goBackBy); // Navigate back by X steps
    } else {
      await this.$router.push({ name: 'index' }); // If we do not have any history in app fallback to homepage
    }
  }

  get backButtonLabel(): string | TranslateResult {
    return this.$route.query.trial === 'expired'
      ? this.$t('fields.navigation.my_qr_codes')
      : this.$t('fields.navigation.back');
  }

  get canGoBack(): boolean {
    return this.$store.state.app.location.history.length > 1;
  }

  /**
   * Toggles the state of the drawer (sidebar) via emit, which is watched in Mainframe
   */
  toggleDrawer(): void {
    this.setDrawerState(!this.drawer);
  }

  /**
   * Calls Authenticator class to log the user out
   */
  logout(): void {
    Authenticator.logout();
  }

  /**
   * Returns if current route is part of checkout-process.
   * In checkout we hide some elements in the app-bar.
   * @see Navigation.vue
   */
  isCheckout(routeName: string): boolean {
    let checkoutRoutes = [
      'checkout_payment_form',
      'checkout_plan_selection',
      'checkout_payment_complete',
      'my_account',
      'my_account_tab',
      'cancel_subscription',
      'checkout_payment_form_preselect_plan',
      'checkout_payment_form_preselect_country',
      'checkout_payment_form_preselect_country_and_plan',
      'checkout_payment_complete',
    ];

    return checkoutRoutes.indexOf(routeName) > -1;
  }

  // Sets the pause-mode if the url-parameter is set
  setIsPauseMode(): void {
    this.isPauseMode = this.$route.query.pausemode === 'true';
  }

  // Check if link is external or internal
  returnNavItemProps({ href }: { href: string }): { href: string } | { to: string } {
    if (href.startsWith('http://') || href.startsWith('https://')) {
      return {
        href: href,
      };
    } else {
      return {
        to: href,
      };
    }
  }
}
</script>

<style lang="scss">
.appbar {
  // reduce height of divider
  &__divider {
    margin-top: 28px !important;
    max-height: 16px !important;
  }
}

.appbar-notice {
  padding: 6px 12px;

  .v-alert__wrapper {
    svg {
      margin-right: 8px;
      color: map-get($primary, 'base');
    }

    .v-alert__icon,
    svg {
      @media (max-width: 600px) {
        display: none;
      }
    }
    .v-alert__icon {
      margin-right: 0;
    }
  }

  &__text {
    font-size: 1rem;
    text-transform: initial;
    font-weight: map-get($font-weights, 'semi-bold');
  }

  &--in-navbar {
    margin: 8px 24px 8px 8px;

    @media (max-width: 600px) {
      margin-right: 16px;
    }

    @media (max-width: 959px) {
      display: none;
    }
  }

  &--checkout {
    display: block !important;

    @media (max-width: 767px) {
      .appbar-notice__text {
        display: none;
      }
      svg {
        margin-right: 0 !important;
      }
    }
  }

  &--in-menu {
    margin: 0 8px 16px;

    @media (min-width: 960px) {
      display: none;
    }
  }
}

.notification-menu {
  &__text {
    color: map-get($lapis, 'lighten-2');
    line-height: rem(24);
  }

  &__date {
    color: map-get($neutral, 'darken-2');
    font-size: rem(12);
  }
}

// Styles for mobile account-button
@media #{map-get($display-breakpoints, 'xs-only')} {
  .account-button {
    min-width: inherit !important;

    .v-btn__content {
      svg {
        margin-right: 0 !important;
      }
    }
  }
}

.account-menu {
  &__title {
    line-height: 1.2rem !important;
  }

  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: rgba(0, 0, 0, 0.87) !important;
  }

  &__list-item {
    max-height: 32px;
    padding: 0 !important;

    &.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      color: map-get($neutral, 'darken-3') !important;
    }

    &.v-list-item--active:before {
      opacity: 0.04;
    }
  }
}

.v-tooltip__content {
  pointer-events: auto;
}

.v-btn--back {
  @media (max-width: 600px) {
    min-width: unset !important;
  }
}

// TODO: Please find a different approach because it rewrites global styles and creates conflicts

//.v-select {
//  .v-input__control {
//    max-width: 176px;
//    height: 40px;
//  }
//}
</style>
