import axios, { AxiosError } from 'axios';
import AppConfig from '@/AppConfig';
import * as Sentry from '@sentry/vue';

// FIXME fix location.ts
export default class DbipLookupService {
  item = {
    continent: '',
    country: '',
    vat_rate: null,
    stateprov: '',
    district: '',
    city: '',
    latitude: 0,
    longitude: 0,
  };

  /**
   * Setup authentication.
   */
  async init(): Promise<any> {
    await axios
      .get(AppConfig.getGeoIpServiceBaseUrl() + '/system/dbip')
      .then((response) => {
        return Object.assign(this.item, response.data);
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
      });
  }

  /**
   * Gets country via lat and long from dbip
   * @param lat
   * @param long
   */
  async getLocationViaLatLong(lat: number, long: number): Promise<any> {
    return await axios
      .post(AppConfig.getGeoIpServiceBaseUrl() + '/system/dbip/latlong', {
        latitude: lat,
        longitude: long,
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
      });
  }
}
