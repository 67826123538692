<template>
  <div v-if="selectedPlan && selectedPlan.price">
    <div class="checkout__summary-container">
      <h4 class="text-h4 checkout__step-header">
        {{ $t('fields.checkout.sidebar.summary.headline') }}
      </h4>

      <!-- START: Radio buttons for standard-plan -->
      <v-radio-group v-if="!isEnterprisePlan" v-model="pricePlan" class="radio-selection-billing-group">
        <v-radio color="navy" class="radio-selection-billing-standard-plan" :value="selectedPlan.id">
          <template #label>
            <div>
              <span
                class="radio-selection-billing__title"
                v-html="
                  $t('fields.checkout.sidebar.summary.selected', {
                    selected_plan: planSelectedPlanTitle,
                  })
                "
              />
              <span class="radio-selection-billing__billing">{{ $t(getBillingInfoTranslationKey(selectedPlan)) }}</span>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <!-- END: Radio buttons for standard-plan -->

      <!-- START: Radio buttons for enterprise-plan -->
      <v-radio-group v-if="isEnterprisePlan" v-model="pricePlan" class="radio-selection-billing-group">
        <div
          v-if="isEnterprisePlan"
          class="order-summary__subscription-name"
          v-html="
            $t('fields.checkout.sidebar.summary.selected', {
              selected_plan: planSelectedPlanTitle,
            })
          "
        />

        <v-radio
          v-for="plan in relatedPlans"
          v-show="isPlanVisible(plan)"
          :key="plan.id"
          color="navy"
          class="radio-selection-billing"
          :value="plan.id"
        >
          <template #label>
            <div v-if="plan.interval === 'year'">
              <span class="radio-selection-billing__title">{{
                $t('fields.checkout.sidebar.summary.selected_annual')
              }}</span>
              <span class="radio-selection-billing__billing">
                <span class="billing-badge">{{ $t('fields.checkout.sidebar.summary.annual_save') }}</span>
              </span>
            </div>
            <div v-if="plan.interval !== 'year'">
              <span class="radio-selection-billing__title">{{
                $t('fields.checkout.sidebar.summary.selected_monthly')
              }}</span>
              <span class="radio-selection-billing__billing">{{
                $t('fields.checkout.sidebar.summary.monthly_minimum')
              }}</span>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <!-- END: Radio buttons for enterprise-plan -->

      <!-- START: Prorated discounts -->
      <div
        v-if="upgrading && stripeCheckoutService.location.tax_exempt && !prorateLoading"
        class="order-summary__prorated-discounts"
      >
        <div class="order-summary__plan-price">
          {{ $t(getPlanPriceTranslationKey(selectedPlan)) }}
          <span>
            {{ returnPlanPrice }}
          </span>
        </div>

        <div class="prorated-discounts__title">
          <span class="d-flex align-center">
            <span class="mr-1">
              {{ $t('fields.checkout.sidebar.summary.prorated_discounts') }}
            </span>
            <HelpTooltip>
              <template #text>
                {{ $t('fields.checkout.sidebar.summary.prorated_discounts_tooltip') }}
              </template>
            </HelpTooltip>
          </span>
          <span>
            <span>
              {{ returnProrateAmount }}
            </span>
          </span>
        </div>

        <v-divider />
      </div>
      <!-- END: Prorated discounts -->

      <!-- START: Subtotal -->
      <div class="order-summary__subtotal">
        <span>
          {{ $t('fields.checkout.sidebar.summary.subtotal') }}
        </span>
        <span v-if="stripeCheckoutService.location.tax_exempt === '' && prorateLoading">
          <v-skeleton-loader width="60" height="12" loading type="text" />
        </span>
        <div v-else data-tast="qr-payment-subtotal-price">
          <span v-if="selectedPlan.strikethrough_price" class="mr-1 striketrough_subtotal">{{
            returnStrikethroughPrice
          }}</span>
          <span>{{ returnSubTotal }}</span>
        </div>
      </div>
      <!-- END: Subtotal -->

      <!-- START: Tax rate -->
      <div class="order-summary__tax">
        <span>
          <span v-if="showTaxSales">
            {{ $t('fields.checkout.sidebar.summary.tax_sales') }}
          </span>
          <span v-else-if="stripeCheckoutService.location.country_iso2">
            {{ $t('fields.checkout.sidebar.summary.tax') }}
          </span>
          <span
            v-if="
              stripeCheckoutService.location.tax_exempt === 'none' && stripeCheckoutService.customer.address.postal_code
            "
          >
            (<span data-test="qr-payment-tax-rate">{{ stripeCheckoutService.location.vat_rate }}%</span>)
          </span>
        </span>
        <template v-if="!stripeCheckoutService.customer.address.postal_code">
          {{ $t('fields.checkout.sidebar.summary.address_missing') }}
        </template>
        <span
          v-else-if="stripeCheckoutService.location.tax_exempt === 'none' && !prorateLoading"
          data-test="qr-payment-tax-amount"
        >
          {{ returnTax }}
        </span>
        <span v-else-if="stripeCheckoutService.location.tax_exempt === 'reverse' && !prorateLoading">
          {{ $t('fields.checkout.sidebar.summary.tax_reverse_charged') }}
        </span>
        <span v-else-if="stripeCheckoutService.location.tax_exempt === 'exempt' && !prorateLoading">
          {{ $t('fields.checkout.sidebar.summary.tax_exempt') }}
        </span>
        <span v-else>
          <v-skeleton-loader width="60" height="12" loading type="text" />
        </span>
      </div>
      <!-- END: Tax rate -->

      <template v-if="stripeCheckoutService.customer.address.postal_code">
        <v-divider />

        <!-- START: Total amount -->
        <v-row>
          <v-col class="order-summary__calculated-price">
            <span class="text-uppercase">{{ $t('fields.checkout.sidebar.summary.total') }}</span>
            <span
              v-if="stripeCheckoutService.location.tax_exempt && !prorateLoading"
              class="order-summary__calculated-price--sum"
              data-test="qr-payment-total-price"
            >
              {{ totalPrice }}
            </span>
            <span v-else>
              <v-skeleton-loader width="80" height="16" loading type="text" />
            </span>
          </v-col>
        </v-row>
        <!-- END: Total amount -->
      </template>

      <v-row v-if="returnGrossPayment.price < 0">
        <v-col class="order-summary__credit-info">
          <div>
            {{ $t('fields.checkout.sidebar.summary.credit_info') }}
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import Currency from '@/Models/PrimitiveModels/Currency/Currency';
import StripeCheckoutService from '@/Services/Subscription/StripeCheckoutService';
import StripeSubscriptionService from '@/Services/Subscription/StripeSubscriptionService';
import axios, { AxiosError } from 'axios';
import AppConfig from '@/AppConfig';
import * as Sentry from '@sentry/vue';
import { Plan, Subscription, SubscriptionState } from '@/classes/stripe';
import { TranslateResult } from 'vue-i18n';

@Component({
  components: {
    HelpTooltip: () => import('@/components/HelpTooltip.vue'),
  },
  computed: {
    ...mapState<SubscriptionState>('subscription', {
      activeSubscription: (state: SubscriptionState) => state.activeSubscription,
    }),
  },
})
export default class OrderSummary extends Vue {
  @Prop()
  selectedPlan!: Plan;

  @Prop()
  stripeCheckoutService!: StripeCheckoutService;

  @Prop()
  legalEntity?: string;

  @Prop()
  checkoutType?: string;

  @Prop()
  relatedPlans?: Array<Plan>;

  activeSubscription!: Subscription;

  urlPlanId?: string;

  stripeSubscriptionService: StripeSubscriptionService = new StripeSubscriptionService();

  proration_behavior: Currency = new Currency();

  prorateLoading = true;

  isEnterprisePlan: boolean = this.selectedPlan.isEnterprisePlan;

  upgrading = false;

  pricePlan?: string = this.selectedPlan.id;

  getBillingInfoTranslationKey(plan: Plan): string {
    if (plan.interval === 'year') {
      return 'fields.checkout.sidebar.summary.selected_annual';
    }
    if (plan.interval === 'month' && plan.interval_count == 3) {
      return 'fields.checkout.sidebar.summary.selected_quarter';
    }
    if (plan.interval === 'month' && plan.interval_count == 1) {
      return 'fields.checkout.sidebar.summary.selected_monthly';
    }

    return 'fields.checkout.sidebar.summary.selected_annual';
  }

  getPlanPriceTranslationKey(plan: Plan): string {
    if (plan.interval === 'year') {
      return 'fields.checkout.sidebar.summary.planprice_yearly';
    }
    if (plan.interval === 'month' && plan.interval_count == 3) {
      return 'fields.checkout.sidebar.summary.planprice_quarterly';
    }
    if (plan.interval === 'month' && plan.interval_count == 1) {
      return 'fields.checkout.sidebar.summary.planprice_monthly';
    }

    return 'fields.checkout.sidebar.summary.planprice_yearly';
  }

  // Emits event when payment period changes
  @Watch('pricePlan', { immediate: true })
  async paymentPeriodChanged(pricePlanId: string): Promise<void> {
    this.$emit('pricePlanChanged', this.stripeSubscriptionService.findPlan(pricePlanId));

    if (this.upgrading) {
      await this.getProratedDiscount();
    }
  }

  // Emits event when checkout type changes
  @Watch('checkoutType', { immediate: true })
  async checkoutTypeChanged(checkoutType: string): Promise<void> {
    this.upgrading = checkoutType === 'upgrade';

    if (this.upgrading) {
      await this.getProratedDiscount();
    } else {
      this.prorateLoading = false;
    }
  }

  created(): void {
    this.urlPlanId = this.$route.params.paramsId;
    this.pricePlan = this.urlPlanId || this.pricePlan;
    this.proration_behavior.useGrouping = true;

    if (this.pricePlan) {
      this.selectedPlan = this.stripeSubscriptionService.findPlan(this.pricePlan)!;
    }
  }

  async getProratedDiscount(): Promise<void> {
    return await axios
      .get(AppConfig.getAPIBaseUrl() + '/subscription/stripe/proration/show/' + this.selectedPlan.id)
      .then((response) => {
        this.proration_behavior.price = response.data.proration_behavior / 100;
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        this.prorateLoading = false;
      });
  }

  get returnPlanPrice(): string {
    return this.selectedPlan.price.displayPrice({
      price: this.stripeCheckoutService.calcGrossPayment(this.selectedPlan, 0, 1).price,
      overrideCurrency: this.selectedPlan.currency,
    });
  }

  get returnProrateAmount(): string {
    return this.selectedPlan.price.displayPrice({
      overrideCurrency: this.selectedPlan.currency,
      price: this.proration_behavior.price,
      useGrouping: true,
    });
  }

  get returnSubTotal(): string {
    const calcGrossPayment = this.stripeCheckoutService.calcGrossPayment(
      this.selectedPlan,
      0,
      1,
      this.proration_behavior.price
    );
    return this.selectedPlan.price.displayPrice({
      overrideCurrency: this.selectedPlan.currency,
      price: calcGrossPayment.price,
    });
  }

  get returnStrikethroughPrice(): string {
    return this.selectedPlan.price.displayPrice({
      overrideCurrency: this.selectedPlan.currency,
      price: this.selectedPlan.strikethrough_price || 0,
    });
  }

  get returnGrossPayment(): Currency {
    return this.stripeCheckoutService.calcGrossPayment(
      this.selectedPlan,
      this.stripeCheckoutService.location.vat_rate,
      1,
      this.proration_behavior.price
    );
  }

  get returnVatRateTax(): Currency {
    return this.stripeCheckoutService.calcVatRateTax(
      this.selectedPlan,
      this.stripeCheckoutService.location.vat_rate,
      1,
      this.proration_behavior.price
    );
  }

  get returnTax(): string {
    return this.selectedPlan.price.displayPrice({
      price: this.returnVatRateTax.price,
      overrideCurrency: this.selectedPlan.currency,
    });
  }

  get showTaxSales(): boolean {
    const { country_iso2 } = this.stripeCheckoutService.location;
    const { state } = this.stripeCheckoutService.customer.address;
    const states = ['MD', 'PA', 'NY', 'AZ', 'MA', 'MN', 'TN', 'TX', 'UT', 'WA', 'WI'];
    return country_iso2 === 'US' && states.includes(state);
  }

  get totalPrice(): string {
    const intPriceString = Math.round(this.returnGrossPayment.price * 100);
    this.$emit('totalPriceChanged', intPriceString);

    return this.selectedPlan.price.displayPrice({
      price: this.returnGrossPayment.price,
      overrideCurrency: this.selectedPlan.currency,
    });
  }

  get planSelectedPlanTitle(): TranslateResult {
    return this.selectedPlan.isMonthlyFlexPlan
      ? this.$t('fields.pricing_table.flex_plan_title_monthly')
      : this.$t(`fields.${this.selectedPlan.translationKey}`);
  }

  isPlanVisible(plan: Plan): boolean {
    return plan.currency === this.selectedPlan.currency;
  }
}
</script>

<style lang="scss">
#checkout {
  // Radio button group
  .radio-selection-billing-group {
    padding: 0;
    margin: 0 0 4px;

    .v-messages {
      display: none;
    }

    label {
      font-size: 16px;
      height: 100%;
      font-weight: map-get($font-weights, 'regular');
      color: map-get($lapis, 'lighten-2') !important;
    }
  }
  // Radio buttons styles for standard plan
  .radio-selection-billing-standard-plan {
    border-bottom: 1px solid rgba(map-get($neutral, 'darken-3'), 0.38) !important;

    .v-input--selection-controls__input {
      display: none;
    }
  }
  // Label styles for all plans
  .radio-selection-billing,
  .radio-selection-billing-standard-plan {
    label {
      font-size: 16px;
      height: 100%;
      color: map-get($lapis, 'lighten-2');
      padding-right: 12px;
      cursor: auto !important;

      div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  // Radio buttons styles for enterprise plans
  .radio-selection-billing {
    height: 40px;
    border-radius: 4px;
    border: 1px solid rgba(map-get($neutral, 'darken-3'), 0.38);
    background: transparent;

    &:hover {
      border: 1px solid rgba(map-get($neutral, 'darken-3'), 0.86);
    }
    // Make space around radio-icon clickable by expanding the wrapper
    .v-input--selection-controls__input {
      margin: 0;
      height: 40px;
      width: 40px;

      input {
        min-height: 40px;
      }

      // adjust the ripple
      .v-input--selection-controls__ripple {
        left: 0;
        top: calc(50% - 20px);
      }
    }

    // Badge, which is shown next to yearly enterprise-plan
    .billing-badge {
      background-color: map-get($navy, 'base');
      text-transform: uppercase;
      font-size: 10px;
      font-weight: map-get($font-weights, 'bold');
      border-radius: 2px;
      color: #ffffff;
      padding: 1px 4px;
    }

    &__billing {
      font-size: 12px;
      padding-top: 2px;
    }

    &.v-item--active {
      border: 1px solid map-get($navy, 'base');
      background: #ffffff;

      &:hover {
        box-shadow: 0 0 0 1px map-get($navy, 'base');
      }
    }
  }

  .striketrough_subtotal {
    color: map-get($lapis, 'lighten-2');
    display: inline-block;
    position: relative;
    &::after {
      border-bottom: 1px solid;
      position: absolute;
      content: '';
      bottom: 50%;
      left: 0px;
      right: 1px;
    }
  }

  // Order summary, which contains all price info - below radio-group
  .order-summary {
    &__subscription-name {
      margin-bottom: 12px;
      font-size: 16px;
      line-height: 24px;
      color: map-get($lapis, 'lighten-2');

      span {
        font-weight: map-get($font-weights, 'regular');
      }
    }

    &__prorated-discounts {
      .prorated-discounts__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: map-get($lapis, 'lighten-2');
        margin-bottom: 12px;
        font-size: 14px;
      }

      .prorated-discounts__price-components {
        .price-components-list {
          padding: 6px 0 12px;
        }

        .price-components-item {
          color: map-get($lapis, 'lighten-2');
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          padding: 4px 0;
        }
      }
    }

    &__subtotal,
    &__tax,
    &__plan-price,
    &__calculated-price {
      display: flex;
      justify-content: space-between;
    }

    &__subtotal,
    &__plan-price {
      font-size: 14px;
      font-weight: map-get($font-weights, 'semi-bold');
      text-transform: uppercase;
      color: map-get($lapis, 'lighten-2');
      margin: 12px 0 4px;
    }

    &__plan-price {
      margin: 12px 0 0;
      text-transform: none;
    }

    &__tax {
      font-size: 14px;
      margin-bottom: 12px;
      color: map-get($lapis, 'lighten-2');
    }

    &__calculated-price {
      font-size: 18px;
      line-height: 24px;
      font-weight: map-get($font-weights, 'bold');
      color: map-get($navy, 'base');
      padding: 8px 12px 0;

      &--sum {
        color: map-get($lapis, 'lighten-2');
      }
    }

    &__credit-info {
      font-size: 12px;
      line-height: 16px;
      font-weight: map-get($font-weights, 'italic');
      color: map-get($lapis, 'lighten-2');
      padding: 12px 12px 0;
    }
  }
}
</style>
