import { Currencies, CurrencyCode } from '@/Models/Localization';
import { TeamDiscount } from '@/Services/PromoCodeService';

export class Team {
  id: any;
  email: string;
  company: string;
  vat_id: string | null;
  legal_entity: string; // 'b2b' || 'b2c'
  is_payment_method_invoice: boolean;
  payment_method_type: string; // card || bank_transfer || paypal
  currency: CurrencyCode; // (process.env.VUE_APP_DEFAULT_CURRENCY as CurrencyCode) || Currencies.EUR
  first_name: string;
  last_name: string;
  city: string;
  country: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
  ab_tests: string[];
  price_tag: string;
  country_from_registration: string;
  plan_name: string;
  created_at: string;
  discount: TeamDiscount | null;
  updated_at: number;
  now_timestamp: number;
  payment_method_details: object | null;

  constructor(object: any = {}) {
    this.id = object?.id || 0;
    this.email = object?.email || '';
    this.company = object?.company || '';
    this.vat_id = object?.vat_id || null;
    this.legal_entity = object?.legal_entity || 'b2b';
    this.is_payment_method_invoice = !!object?.is_payment_method_invoice || false;
    this.payment_method_type = object?.payment_method_type || '';
    this.currency = object?.currency || Currencies.EUR;
    this.first_name = object?.first_name || '';
    this.last_name = object?.last_name || '';
    this.city = object?.city || '';
    this.country = object?.country || '';
    this.line1 = object?.line1 || '';
    this.line2 = object?.line2 || '';
    this.postal_code = object?.postal_code || '';
    this.state = object?.state || '';
    this.ab_tests = object?.ab_tests || [];
    this.price_tag = object?.price_tag || null;
    this.country_from_registration = object?.country_from_registration || null;
    this.plan_name = object?.plan_name || null;
    this.created_at = object?.created_at || null;
    this.discount = object?.discount || null;
    this.updated_at = object?.updated_at;
    this.now_timestamp = object?.now_timestamp;
    this.payment_method_details = object?.payment_method_details || null;
  }
}

export class User {
  id: any;
  email: string;
  password: any;
  first_name: string;
  last_name: string;
  language: string;
  is_team_lead: boolean;
  team: Team;
  stripe_instance_country: string;

  constructor(object: any = {}) {
    this.id = object?.id || 0;
    this.email = object?.email || '';
    this.password = object?.password || '';
    this.first_name = object?.first_name || '';
    this.last_name = object?.last_name || '';
    this.language = object?.language || '';
    this.is_team_lead = !!object?.is_team_lead || true;
    this.team = object?.team || new Team();
    this.stripe_instance_country = object?.stripe_instance_country || 'DE';
  }
}
