<template>
  <v-col cols="12" class="pb-0">
    <!-- SKELETON LOADING -->
    <v-card
      v-if="isPaymentMethodsLoading && !isPaymentMethodsError && !tryAgainPaymentMethod && !isTeamPaymentMethodInvoice"
      outlined
      rounded
      class="px-3 py-4"
      height="125"
    >
      <div class="d-flex justify-space-between">
        <v-skeleton-loader type="text" width="100" />
      </div>

      <div class="d-flex justify-space-between align-center mt-3">
        <v-skeleton-loader type="heading" width="225" height="24" class="rounded-pill" />
        <v-skeleton-loader type="button" width="36" height="20" />
      </div>

      <div class="d-flex justify-space-between align-center mt-3">
        <div class="d-flex align-center">
          <v-skeleton-loader type="image" width="24" height="24" class="rounded-circle" />
          <v-skeleton-loader type="text" width="100" height="12" class="ml-1" />
        </div>

        <v-skeleton-loader type="image" width="75" height="20" class="rounded" />
      </div>
    </v-card>
    <!-- SKELETON LOADING END -->

    <!-- ERROR -->
    <v-card v-if="isPaymentMethodsError || tryAgainPaymentMethod" outlined rounded class="px-3 py-4" height="125">
      <div class="d-flex justify-space-between">
        <!-- Title -->
        <div class="text-overline--small neutral--text text--darken-1">
          {{ $t('fields.account.billing.payment.payment_method') }}
        </div>
      </div>

      <Alert v-if="isPaymentMethodsError" type="error" icon="forbidden-system" inline regular-title small class="mt-3">
        <template #title>
          <span class="font-weight-semi-bold">
            {{ $t('fields.account.billing.payment.payment_method_error') }}
          </span>
          <v-btn
            text
            name="btn-try-again"
            :ripple="false"
            color="primary"
            x-small
            class="ml-1"
            @click="loadPaymentMethods(true)"
          >
            <span class="navy--text">
              {{ $t('fields.account.billing.payment.try_again') }}
            </span>
          </v-btn>
        </template>
      </Alert>

      <v-progress-circular
        v-if="tryAgainPaymentMethod"
        indeterminate
        :width="4"
        :size="24"
        color="candy"
        class="mt-2"
      />
    </v-card>
    <!-- ERROR END -->

    <!-- DATA -->
    <v-card v-if="!isPaymentMethodsLoading" outlined rounded class="px-3 py-4">
      <div class="d-flex justify-space-between">
        <!-- Title -->
        <div class="text-overline--small neutral--text text--darken-1">
          {{ $t('fields.account.billing.payment.payment_method') }}
        </div>
      </div>

      <div class="d-flex justify-space-between align-center mt-3">
        <!-- Title -->
        <h5 class="text-h5 lapis--text text--lighten-2" :data-test="'payment_method_' + getPaymentMethodTitle">
          {{ getPaymentMethodTitle }}
        </h5>

        <!-- Action -->
        <v-btn text :ripple="false" color="primary" x-small @click="(isDialogShown = true), trackOptimizelyEvent()">
          <span class="navy--text">
            {{ $t('fields.account.billing.payment.edit') }}
          </span>
        </v-btn>
      </div>

      <div v-if="defaultPaymentMethod" class="d-flex justify-space-between align-center mt-3">
        <!-- Card type & number -->
        <span class="lapis--text text--lighten-2">
          <Icon
            v-if="paymentIconName"
            :name="paymentIconName"
            large
            color="lapis lighten-1"
            :class="{ 'mr-2': isPaymentMethodPaypal }"
          />
          <span v-if="defaultPaymentMethod.type === 'card'" data-test="card_last4">
            •••• •••• •••
            {{ defaultPaymentMethod.last4 }}
          </span>
          <span v-if="defaultPaymentMethod.type === 'paypal'" data-test="paypal_email">
            {{ defaultPaymentMethod.email }}
          </span>
        </span>

        <!-- Expire month & year -->
        <v-chip
          v-if="!isTeamPaymentMethodInvoice && defaultPaymentMethod.type === 'card'"
          label
          small
          class="chip--badge font-weight-regular"
          color="neutral lighten-2"
          text-color="lapis lighten-1"
        >
          {{ $t('fields.account.billing.payment.payment_method_expires') }}
          {{ defaultPaymentMethod.exp_month }}/{{ lastTwoDigitsOfYear(defaultPaymentMethod.exp_year) }}
        </v-chip>

        <!-- Expired--forbidden -->
        <v-chip
          v-if="isPaymentMethodExpired && defaultPaymentMethod.type === 'card' && !isTeamPaymentMethodInvoice"
          outlined
          label
          small
          class="chip--badge font-weight-semi-bold"
          text-color="lapis lighten-1"
          color="begonia lighten-1"
        >
          <Icon name="forbidden-system" outline size="12" class="mr-1" color="begonia lighten-1" />
          {{ $t('fields.account.billing.payment.payment_method_expired') }}
          {{ defaultPaymentMethod.exp_month }}/{{ lastTwoDigitsOfYear(defaultPaymentMethod.exp_year) }}
        </v-chip>

        <!-- Expired--warning -->
        <v-chip
          v-show="false"
          outlined
          label
          small
          class="chip--badge font-weight-semi-bold"
          text-color="lapis lighten-1"
          color="banana darken-1"
        >
          <Icon name="warning-system" outline size="12" class="mr-1" color="banana darken-1" />
          {{ $t('fields.account.billing.payment.payment_method_expired') }}
        </v-chip>
      </div>

      <!-- Prompt banner -->
      <v-banner
        v-if="isThereAnEasierPaymentMethod"
        class="qr-banner--card qr-banner--prompt"
        color="navy"
        data-test="pay-with-cc-banner"
      >
        <span class="neutral--text text--lighten-4 font-weight-bold">
          {{ $t('fields.account.billing.payment.payment_method_card_pay_title') }}
        </span>
        <template #actions>
          <v-btn
            text
            :ripple="false"
            color="primary"
            small
            class="v-btn--text--link"
            @click="(isDialogShown = true), trackOptimizelyEventPayWithCreditCard()"
          >
            <span class="white--text">
              {{ $t('fields.account.billing.payment.payment_method_card_pay_desc') }}
            </span>
          </v-btn>
        </template>
      </v-banner>
    </v-card>
    <!-- DATA END -->
    <payment-method-edit
      :open="isDialogShown"
      :wizard-step="$route.query.step"
      :redirect-status="$route.query.redirect_status"
      @cancel="isDialogShown = false"
      @update-payment-method="loadPaymentMethods"
      @update-billing-address="updatePaymentAddress"
    />
  </v-col>
</template>

<script lang="ts">
import AppConfig from '@/AppConfig';
import type { CardPaymentMethod, PaymentMethod } from '@/classes/stripe';
import PaymentMethodEdit from '@/components/Modals/Account/PaymentMethodEdit.vue';
import * as Sentry from '@sentry/vue';
import axios, { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import Icon from '@/components/Icons/Icon.vue';
import Alert from '@/components/Alert.vue';
import OptimizelyService from '@/Services/OptimizelyService';

@Component({
  components: {
    PaymentMethodEdit,
    Icon,
    Alert,
  },
})
export default class PaymentMethodSummary extends Vue {
  isDialogShown = false;

  defaultPaymentMethod: PaymentMethod = {} as any;

  isPaymentMethodsLoading = false;

  isPaymentMethodsError = false;

  tryAgainPaymentMethod = false;

  @Prop({
    default: false,
  })
  isTeamPaymentMethodInvoice!: boolean;

  @Emit()
  updatePaymentAddress(): boolean {
    return true;
  }

  trackOptimizelyEvent(): void {
    OptimizelyService.trackEvent('billing_page_edit_payment_methods_button');
  }

  async created(): Promise<void> {
    await this.loadPaymentMethods();
  }

  async mounted(): Promise<void> {
    if (this.$route.query.returningFromPaypal === 'true' && this.$route.query.step === '3') {
      // Clean up the URL
      await this.$router.replace({
        query: {
          ...this.$route.query,
          returningFromPaypal: undefined,
          step: undefined,
          setup_intent: undefined,
          setup_intent_client_secret: undefined,
        },
      });
    }
  }

  async loadPaymentMethods(tryAgain?: boolean): Promise<any> {
    if (tryAgain) {
      this.isPaymentMethodsError = false;
      this.tryAgainPaymentMethod = true;
    } else {
      this.isPaymentMethodsLoading = true;
    }

    return axios
      .get<PaymentMethod>(AppConfig.getAPIBaseUrl() + '/subscription/stripe/payment-methods/default')
      .then((response) => {
        this.defaultPaymentMethod = response.data;
        this.isPaymentMethodsError = false;
        this.tryAgainPaymentMethod = false;
      })
      .catch((error: AxiosError) => {
        Sentry.captureException(error);
        this.isPaymentMethodsError = true;
        this.tryAgainPaymentMethod = false;
      })
      .finally(() => {
        this.isPaymentMethodsLoading = false;
      });
  }

  get getPaymentMethodTitle(): string {
    const teamPaymentMethodDetails = this.$store.state.auth.team.payment_method_details;
    switch (this.defaultPaymentMethod?.type) {
      case 'card':
        if (teamPaymentMethodDetails?.is_wallet) {
          return this.$t(`fields.checkout.payment_methods.${teamPaymentMethodDetails.wallet_type}`).toString();
        }
        return this.$t('fields.checkout.payment_methods.card').toString();
      case 'sepa_debit':
        return this.$t('fields.checkout.payment_methods.sepa_debit').toString();
      case 'paypal':
        return this.$t('fields.checkout.payment_methods.paypal').toString();
      default:
        return this.$t('fields.checkout.payment_methods.send_invoice').toString();
    }
  }

  get isPaymentMethodPaypal(): boolean {
    return this.getPaymentMethodTitle === this.$t('fields.checkout.payment_methods.paypal').toString();
  }

  get paymentIconName(): string | boolean {
    if (this.defaultPaymentMethod?.type === 'paypal') {
      return 'pay-paypal-system';
    }

    if (this.defaultPaymentMethod?.type === 'card') {
      const icons: { [key: string]: string } = {
        mastercard: 'pay-mastercard-system',
        visa: 'pay-visa-system',
        amex: 'pay-amex-system',
        sepa_debit: 'pay-direct-debit-system',
      };
      return icons[(this.defaultPaymentMethod as CardPaymentMethod).brand];
    }

    return false;
  }

  lastTwoDigitsOfYear(year: string | number): number {
    return Number(year) % 100;
  }

  get isPaymentMethodExpired(): boolean {
    if (this.isTeamPaymentMethodInvoice) {
      return false;
    }
    const paymentMethod = this.defaultPaymentMethod as CardPaymentMethod;
    return paymentMethod?.exp_month < dayjs().format('M') && paymentMethod?.exp_year < dayjs().format('YYYY');
  }

  get isThereAnEasierPaymentMethod(): boolean {
    return ['paypal', 'card'].includes(this.defaultPaymentMethod?.type) === false;
  }

  trackOptimizelyEventPayWithCreditCard(): void {
    OptimizelyService.trackEvent('billing_page_pay_with_credit_card_banner');
  }
}
</script>
