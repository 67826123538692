<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2 class="text-h2 checkout__header">
          <span v-html="$t('fields.checkout.checkout')" />
          <span v-if="checkoutType === 'downgrade' || checkoutType === 'upgrade'" class="checkout__header--light">
            {{ $t('fields.checkout.header_' + checkoutType + '_plan', { plan_title: planTitle }) }}
          </span>
          <span v-else class="checkout__header--light">
            {{ $t('fields.checkout.header_new_plan', { plan_title: planTitle }) }}
          </span>
        </h2>
      </v-col>
    </v-row>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class Header extends Vue {
  @Prop({ type: String })
  checkoutType!: string;

  @Prop({ type: String })
  translationKey!: string;

  @Prop({ type: Boolean })
  isMonthlyFlexPlan!: boolean;

  get planTitle(): TranslateResult {
    return this.isMonthlyFlexPlan
      ? this.$t('fields.pricing_table.flex_plan_title_monthly')
      : this.$t(`fields.${this.translationKey}`);
  }
}
</script>
