<template>
  <v-alert :class="['qr-alert', classObject, 'custom-alert']" :max-width="maxWidth">
    <div class="alert__icon-wrapper">
      <Icon v-if="icon" :class="['alert__icon']" :name="icon" :large="!small" outline :size="small ? 12 : null" />
    </div>

    <v-row>
      <v-col v-if="$slots.title" cols="auto" class="py-0" :class="titleClass">
        <slot name="title" />
      </v-col>
      <v-spacer />
      <v-col v-if="$slots.content" class="alert__subtitle-container" xs="12" sm="12" md="12" lg="auto" xl="auto">
        <slot name="content" />
      </v-col>
    </v-row>
  </v-alert>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import Icon from '@/components/Icons/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class Alert extends Vue {
  @Prop(Boolean)
  inline?: boolean;

  @Prop(Boolean)
  small?: boolean;

  @Prop(String)
  icon?: string;

  @Prop({ type: String, default: 'alert__header--regular' })
  titleClass?: string;

  @Prop(String)
  maxWidth?: string;

  @Prop({ default: 'success' })
  type?: 'success' | 'info' | 'warning' | 'error';

  get classObject(): Array<string | { [key: string]: boolean | undefined }> {
    return [
      `qr-alert--${this.type}`,
      {
        'qr-alert--small': this.small,
        'd-inline-flex': this.inline,
      },
    ];
  }
}
</script>

<style scoped>
.trial-expired-title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #294270;
}

.alert__icon-wrapper {
  display: flex;
  align-items: center;
  margin-right: 8px;
}
</style>
