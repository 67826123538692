import { extend } from 'vee-validate';
import { email, max, required } from 'vee-validate/dist/rules';
import { VatRules } from '@/plugins/VatRules';
import { i18n } from '@/i18n';
import { VatCountryRules } from '@/plugins/VatCountryRules';
import KeyValueItem from '@/Models/PrimitiveModels/KeyValueItem';
import axios from 'axios';
import * as Sentry from '@sentry/vue';
import AppConfig from '@/AppConfig';

// Import needed rules, adjust as needed
extend('required', required);
extend('email', email);
extend('max', max);

const vatIdMemorization: any = {};

const checkVatId = async (vatId: string): Promise<boolean> => {
  try {
    if (vatIdMemorization[vatId] !== undefined) return vatIdMemorization[vatId];
    const { data } = await axios.post(AppConfig.getAPIBaseUrl() + '/vat-id/check', { vatId: vatId });
    vatIdMemorization[vatId] = data.data;
    return data.data;
  } catch (err) {
    Sentry.captureException(err);
    return false;
  }
};

// VAT number validator
extend('vat_number', {
  params: ['target'],
  //@ts-ignore
  validate: async (vatNumber: string, { target }) => {
    let isValid = false;

    // Check if there is a rule for different VAT ISO code and target.
    const vatCountryRule = VatCountryRules.find((rule: KeyValueItem) => rule.key === target.toUpperCase());

    // Override target, if there is a rule for a different VAT ISO code.
    if (vatCountryRule) {
      target = vatCountryRule.value;
    }

    if (vatNumber.substring(0, 2) !== target.toUpperCase()) {
      return isValid;
    }

    // Find rule by iso country 2 code
    const rule = VatRules.find((rule: KeyValueItem) => rule.key === target.toUpperCase());

    // Use rule to check if vat id is valid
    if (rule) {
      const vatNumberWithoutIso = vatNumber.substring(2);
      const m = rule.value.exec(vatNumberWithoutIso);

      if (m !== null) {
        // The result can be accessed through the `m`-variable.
        m.forEach((match: any) => {
          if (typeof match != 'undefined' && !isValid) {
            isValid = match.length === vatNumberWithoutIso.length;
          }
        });
      }
    }

    return isValid ? await checkVatId(vatNumber) : false;
  },
  message: () => i18n.t('fields.vee-validate.vat_number').toString(),
});

/*
// Custom rule example
extend('super_special_egoditor_rule', {
    validate: (passedValueOrModel:any) => {
        return passedValueOrModel.valueToCheck == true; // or whatever...
    },
    message: "Please use i18n.ts for message!"
});
 */
