import { RouteConfig } from 'vue-router';

import Account from '@/Pages/AccountPage/Account.vue';
import CancelSubscription from '@/Pages/CancelSubscriptionPage/CancelSubscription.vue';
import OfferAcceptedConfirmation from '@/Pages/CancelSubscriptionPage/OfferAcceptedConfirmation.vue';
import Checkout from '@/Pages/Checkout.vue';
import PaymentForm from '@/Pages/CheckoutPage/PaymentForm.vue';
import PaymentComplete from '@/Pages/PaymentCompletePage/PaymentComplete.vue';
import PlanSelection from '@/Pages/PricingPage/PlanSelection.vue';
import Processing from '@/Pages/ProcessingPage/Processing.vue';

// FIXME we have like 3 routes, make this easier
const AppRoutes: Array<RouteConfig> = [
  {
    path: '/my-account',
    meta: {
      middleware: [],
    },
    component: Account,
    children: [
      {
        path: '/',
        name: 'my_account',
        meta: {
          middleware: [],
        },
        component: Account,
      },
      {
        path: ':tab',
        name: 'my_account_tab',
        meta: {
          middleware: [],
        },
        component: Account,
        props: true,
      },
    ],
  },
  {
    path: '/processing',
    name: 'processing',
    meta: {
      middleware: [],
      title: 'Processing payment',
    },
    component: Processing,
  },
  {
    path: '/cancel-subscription',
    name: 'cancel_subscription',
    meta: {
      middleware: [],
      title: 'Cancel subscription',
    },
    component: CancelSubscription,
  },
  {
    path: '/complete-with-discount',
    name: 'checkout_complete_with_discount',
    meta: {
      middleware: [],
    },
    props: true,
    component: OfferAcceptedConfirmation,
  },
  {
    path: '/features-and-pricing',
    meta: {
      middleware: [],
    },
    component: Checkout,
    children: [
      {
        path: '/',
        name: 'checkout_plan_selection',
        meta: {
          title: 'Select a Plan',
          middleware: [],
        },
        component: PlanSelection,
      },
      {
        path: 'payment',
        name: 'checkout_payment_form',
        meta: {
          middleware: [],
        },
        props: true,
        component: PaymentForm,
      },
      {
        path: 'payment/plan/:planId',
        name: 'checkout_payment_form_preselect_plan',
        meta: {
          middleware: [],
        },
        props: true,
        component: PaymentForm,
      },
      {
        path: 'payment/plan-name/:planName',
        name: 'checkout_payment_form_preselect_plan_name',
        meta: {
          middleware: [],
        },
        props: true,
        component: PaymentForm,
      },
      {
        path: 'payment/country/:countryIso2?',
        name: 'checkout_payment_form_preselect_country',
        meta: {
          middleware: [],
        },
        props: true,
        component: PaymentForm,
      },
      {
        path: 'payment/plan/:planId/country/:countryIso2',
        name: 'checkout_payment_form_preselect_country_and_plan',
        meta: {
          middleware: [],
        },
        props: true,
        component: PaymentForm,
      },
      {
        path: 'complete/:type?',
        name: 'checkout_payment_complete',
        meta: {
          middleware: [],
        },
        props: true,
        component: PaymentComplete,
      },
    ],
  },
];

export { AppRoutes };
