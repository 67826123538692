import { MiddlewareInterface } from '@/middleware/middlewareInterface';
import { trackSnowplowPageView } from '@/Services/Snowplow/SnowplowService';

export default async function snowplowMiddleware({
  next,
  to,
  store,
}: MiddlewareInterface): Promise<MiddlewareInterface> {
  const page_name = to.name;
  const language_code = store.state?.auth?.user?.language;
  const user_id = store.state?.auth?.user?.id;
  const industry_id = store.state?.account?.account?.industry_id;

  const ab_test_name = store.state?.auth?.team?.ab_tests;
  const group = [];
  const name = [];
  const event_type = [];

  for (const key in ab_test_name) {
    const ab_test_value = ab_test_name[key].toString();
    const ab_test_bucket = ab_test_value.slice(-8);
    let ab_test_key = '';

    if (
      ab_test_bucket.length &&
      (ab_test_bucket === 'original' || ab_test_bucket === 'variant2' || ab_test_bucket === 'variant3')
    ) {
      const split_value = ab_test_value.split('-');
      ab_test_key = `${split_value[0]}-${split_value[1]}`;
    } else {
      ab_test_key = ab_test_value;
    }

    name.push(ab_test_key);
    if (ab_test_bucket === 'original') {
      group.push('A');
    } else if (ab_test_bucket === 'variant2') {
      group.push('C');
    } else if (ab_test_bucket === 'variant3') {
      group.push('D');
    } else {
      group.push('B');
    }
    event_type.push('E');
  }

  trackSnowplowPageView({
    industry_id,
    page_name,
    user_id,
    language_code,
    event_type,
    group,
    name,
  });

  return next();
}
