<template>
  <v-btn v-bind="$attrs" icon :ripple="false" color="primary" :small="!isMobile" :large="isMobile" v-on="$listeners">
    <Icon name="exit-system" outline />
  </v-btn>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import Icon from '@/components/Icons/Icon.vue';

@Component({
  components: {
    Icon,
  },
})
export default class Dismiss extends Vue {
  isMobile = false;
  windowSize = {
    width: 0,
    height: 0,
  };

  onResize(): void {
    this.isMobile = this.windowSize.width < 960;
  }
}
</script>
