// Simple list of VAT IDs, that have a different ISO code and VAT country code.

// key: ISO code of the country
// value: ISO code (= first 2 chars) of the VAT ID
import KeyValueItem from '@/Models/PrimitiveModels/KeyValueItem';

export const VatCountryRules: Array<KeyValueItem> = [
  {
    key: 'GR',
    value: 'EL',
  },
];
