import axios from 'axios';
import AppConfig from '@/AppConfig';
import store from '@/store';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';

export type DiscountInfo = {
  data: {
    active: boolean;
    code: string;
    coupon: {
      name: string;
      percent_off: number;
    };
    id: string;
  };
};

export type TeamDiscount = {
  name: string;
  discount: number;
};

export type TeamPromoCode = {
  coupon_id: string;
};

export type UserCode = {
  code: {
    coupon: {
      percent_off: number;
    };
  };
};

export default class PromoCodeService {
  static PROMO_CODE_ID = AppConfig.getPromoCodeId();
  static AB_TEST_DISCOUNT_DELIVERY = 'ENG-2250';

  public static async getDiscountCodeInformation(promoCode = this.PROMO_CODE_ID): Promise<DiscountInfo | null> {
    try {
      return (await axios.get(`${AppConfig.getAPIBaseUrl()}/subscription/stripe/promo-code/${promoCode}`)).data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }

  public static async assignCodeToTeam({ coupon_id }: TeamPromoCode): Promise<UserCode | null> {
    try {
      return (
        await axios.post(`${AppConfig.getAPIBaseUrl()}/subscription/stripe/discount`, {
          coupon_id,
        })
      ).data;
    } catch (error) {
      Sentry.captureException(error);
      return null;
    }
  }

  public static isCouponCodeEnabled(): boolean {
    return !!store.state.auth.user.team?.discount?.name;
  }

  public static isPartOfABTestENG2250(skipOddEvenCheck = false): boolean {
    const account = store.state?.account?.account;
    const user = store.state?.auth?.user;
    const activeSubscription = store.state.subscription.activeSubscription;
    const subscriptionEndsIn30Days = dayjs(activeSubscription.current_period_end).diff(dayjs(), 'days', true) <= 30;
    // 1. Show to the Advanced Plan
    // 2. App language is English
    // 3. Current subscription will end in 30 days or less
    // 4. Account number is an even number
    const baseValidation =
      activeSubscription?.plan?.planLevel == '2' && user?.language === 'en' && subscriptionEndsIn30Days;
    if (skipOddEvenCheck) {
      return baseValidation;
    }
    return baseValidation && account?.id % 2 == 0;
  }
}
