import AppConfig from '@/AppConfig';
import { newTracker } from '@snowplow/browser-tracker';
import { OptimizelyXPlugin } from '@snowplow/browser-plugin-optimizely-x';

export default function init(): void {
  const collectorEndpoint = AppConfig.getSnowplowCollectorEndpoint();

  if (collectorEndpoint === '') {
    return;
  }

  newTracker('sp', collectorEndpoint, {
    contexts: {
      webPage: true,
    },
    appId: 'qrcg_checkout',
    platform: 'app',
    eventMethod: 'post',
    encodeBase64: true,
    stateStorageStrategy: 'cookieAndLocalStorage',
    discoverRootDomain: true,
    cookieSameSite: 'None',
    postPath: '/qrcg/custom',
    cookieSecure: true,
    plugins: [OptimizelyXPlugin()],
  });
}
