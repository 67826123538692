import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import light from './vuetify/theme';
import '@/styles/app.scss';
import appIcons from './vuetify/icons';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: { light },
  },
  icons: {
    values: appIcons,
  },
  lang: {
    locales: { de },
    current: 'de',
  },
});
